import React, { FC, useEffect } from 'react';
import getEnvironDomain from 'utils/getEnvironDomain';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useStores } from 'hooks/useStores';
import getUrlParameter from 'ati-utils/getUrlParameter';
import getCookie from 'ati-utils/getCookie';

import { NewsBlockContainer, NewsIframe } from './NewsBlockStyles';

interface INewsBlock {
  className?: string;
}

const NewsBlock: FC<INewsBlock> = observer(({ className }) => {
  const {
    appStore: { isMobileApp },
  } = useStores();

  const isCookieApp = getCookie('atiapp') === '1';
  const isMobileAppLocalCheck = getUrlParameter('atiapp') === '1' || isCookieApp;

  useEffect(() => {
    if (window.renderNewsWidget) {
      window.renderNewsWidget('#atiNewsBlock');
    }
  }, []);

  if (isMobileApp || isMobileAppLocalCheck) return null;

  return <NewsBlockContainer className={cn(className)} id="atiNewsBlock"></NewsBlockContainer>;
});

export default NewsBlock;
