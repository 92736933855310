import { observable, action, computed } from 'mobx';
import { CurrentPageType, IPagesStore } from 'interfaces/IPagesStore';

import theme from 'theme';
import { IRootStore } from 'stores/rootStore';

class PagesStore implements IPagesStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable currentPage: CurrentPageType = '';
  @observable isShowedBreadcrumbs = false;

  @computed get bgColor() {
    return this.currentPage !== ''
      ? this.pages[this.currentPage].bgColor
      : theme.colors.color_white;
  }

  @computed get isSearchPage() {
    return this.currentPage === 'search';
  }

  @computed get isAfterPublicationPage() {
    return this.currentPage === 'afterPublication';
  }

  @computed get isCabinetPage() {
    return this.currentPage === 'cabinet';
  }

  @computed get isFormPage() {
    return this.currentPage === 'form';
  }

  @computed get breadcrumbs() {
    return this.currentPage !== '' ? this.pages[this.currentPage].breadcrumbs : '';
  }

  @action setCurrentPage = (page: CurrentPageType) => {
    this.currentPage = page;
    if (['search', 'cabinet', 'form', 'afterPublication'].includes(page)) {
      this.setShowedBreadcrumbs(true);
    }
    if (page === '') {
      this.setShowedBreadcrumbs(false);
    }
  };

  @action setShowedBreadcrumbs = (status: boolean) => {
    this.isShowedBreadcrumbs = status;
  };

  pages = {
    search: {
      bgColor: theme.colors.color_white,
      breadcrumbs: '/search',
    },
    advert: {
      bgColor: theme.colors.color_white,
      breadcrumbs: '/advert',
    },
    cabinet: {
      bgColor: theme.colors.color_bg,
      breadcrumbs: '/cabinet',
    },
    form: {
      bgColor: theme.colors.color_bg,
      breadcrumbs: '/form',
    },
    afterPublication: {
      bgColor: theme.colors.color_bg,
      breadcrumbs: '/after-publication',
    },
  };
}

export default PagesStore;
