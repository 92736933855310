import styled from 'styled-components';

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 0 0;

  .wrapper-filter-email {
    width: 100%;
  }

  .filter-email {
    margin-right: 10px;
  }

  .filter-email-info {
    margin-left: 10px;
    align-self: baseline;
    margin-top: 5px;
  }
`;
