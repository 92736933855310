import React from 'react';
import { observer } from 'mobx-react-lite';

import Login from 'ati-ui-react/components/LoginPopup';
import getEnvironDomain from 'utils/getEnvironDomain';

import { useStores } from 'hooks/useStores';

const LoginPopup = observer(() => {
  const {
    popupStore: {
      closeLoginPopup,
      handleUserLogin,
      handleUserRegister,
      handleRestorePasswordRedirect,
      isLoginPopupOpen,
    },
    profileStore: { isUser },
  } = useStores();

  return (
    isLoginPopupOpen &&
    !isUser && (
      <Login
        baseUrl={getEnvironDomain()}
        onLoginClose={closeLoginPopup}
        onUserLogin={handleUserLogin}
        onUserRegister={handleUserRegister}
        fastreg={window.innerWidth >= 896}
        onRestorePasswordRedirect={handleRestorePasswordRedirect}
      />
    )
  );
});

export default LoginPopup;
