import axiosInstance from './axios';

async function getFullVinReportAPI(advertId) {
  const config = {
    method: 'GET',
    url: `/api/v1/avtocod/full_report/${advertId}`,
    withCredentials: true,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getFullVinReportAPI;
