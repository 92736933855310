import React, { FC } from 'react';
import Skeleton from 'ati-ui-react/components/Skeleton';
import PageContainer from 'generic/PageContainer';
import { MainPageSkeletonStyles } from './MainPageSkeletonStyles';

const MainPageSkeleton: FC = () => {
  return (
    <PageContainer page="search">
      <MainPageSkeletonStyles>
        <Skeleton className="skeleton-title" />
        <Skeleton className="skeleton-tab" />
        <Skeleton className="skeleton-search" />
        <div className="container">
          <Skeleton className="skeleton-fav-btn" />
          <Skeleton className="skeleton-search-btn" />
        </div>
        <Skeleton className="skeleton-search-result" />
      </MainPageSkeletonStyles>
    </PageContainer>
  );
};

export default MainPageSkeleton;
