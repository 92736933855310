import React, { FC } from 'react';
import Delete from 'ati-ui-react/components/icons/Delete';

import { RemoveFilterContainer } from './RemoveFilterStyles';

interface IRemoveFilter {
  onHandleRemove: (e: any) => void;
}

const RemoveFilter: FC<IRemoveFilter> = ({ onHandleRemove }) => {
  return (
    <RemoveFilterContainer onClick={onHandleRemove}>
      <Delete color="#d0021b" width={14} />
      <span className="delete-text">Удалить</span>
    </RemoveFilterContainer>
  );
};

export default RemoveFilter;
