// const queryParamsSearch = Object.fromEntries(new URLSearchParams(window.location.search));
// export default queryParamsSearch;

const paramsToObject = (entries) => {
  if (!entries) return null;

  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
};

export default paramsToObject;
