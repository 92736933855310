import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemSpecialMachineModel from './items/ItemSpecialMachineModel';

class SpecialMacineFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется вместе с простыми типами ТС
    this.advert_item = observable(toJS(new ItemSpecialMachineModel(options)));
  }

  @observable advert_item_type = 'special_machinery'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default SpecialMacineFormModel;
