import axiosInstance from './axios';

async function postSimilarAdvertsAPI(id) {
  const config = {
    method: 'POST',
    url: '/public/api/v1/transports/similar_adverts',
    data: id,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default postSimilarAdvertsAPI;
