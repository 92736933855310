import { observable, extendObservable } from 'mobx';
import additionalItemFields from './additionalItemFields';

class ItemBusModel {
  constructor(options) {
    if (options.advertType) {
      extendObservable(this, additionalItemFields[options.advertType]?.bus || {});
    }
  }

  // Информация о ТС
  @observable is_new = false; // Состояние транспорта
  @observable brand = {}; // Объект марки
  @observable model = {}; // Объект модели
  @observable bus_type = ''; // тип автобуса
  @observable mileage = ''; //  Пробег в км
  @observable gearbox_type = null; // Тип коробки передач.Значение из словаря
  // Характеристики
  @observable seats_count = ''; // поле для автобуса - количество мест
  // Дополнительно
  @observable suspension_type = null; // Тип подвески. Значение из словаря
  @observable wheel_formula = null; // Колесная формула. Значение из словаря
  @observable drive_type = null; // Привод. Значение из словаря
  @observable right_side_helm = null; // Правый руль
  @observable computer = null; // Бортовой компьютер
  @observable conditioner = null; // Кондиционер в кабине
  @observable engine_preheater = null; // Предпусковой обогрев двигателя
}

export default ItemBusModel;
