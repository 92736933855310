import axiosInstance from './axios';

async function getCurrentAdvertAPI(data) {
  const config = {
    method: 'POST',
    url: '/public/api/v1/transports/getone',
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getCurrentAdvertAPI;
