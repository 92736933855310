import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemTractorModel from './items/ItemTractorModel';

class TractorFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется вместе с простыми типами ТС
    this.advert_item = observable(toJS(new ItemTractorModel(options)));
  }

  @observable advert_item_type = 'tractor'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default TractorFormModel;
