import { observable, extendObservable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemBusModel from './items/ItemBusModel';

class BusFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется вместе с простыми типами ТС
    this.advert_item = observable(toJS(new ItemBusModel(options)));
  }

  @observable advert_item_type = 'bus'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default BusFormModel;
