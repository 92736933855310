const theme = {
  colors: {
    alto: '#cfcece',
    alabaster: '#f8f8f8',
    weakBlue: '#e8f4ff',
    primary: '#478cc8',
    bostonBlue: '#3a7bbf',
    seashell: '#f1f1f1',
    limedSpruce: '#39444f',
    limedSpruceLight: 'rgba(57, 68, 79, 0.5)',
    aliceBlue: '#e7f0f8',
    monza: '#d0021b',
    chablis: '#fff0f2',
    gallery: '#eaeaea',
    color_bg: '#f1f1f1',
    color_white: '#fff',
    color_gray: '#b8b8b8',
    color_black: '#000',
    color_feta: '#f1fbe4',
    silver: '#cccccc',
    color_silver_sand: '#c3c6c9',
    color_mercury: '#e8e8e8',
    regent: '#8c969d',
    color_dark_blue: '#1a87b3',
    color_yellow: '#eec46f',
    color_dark_yellow: '#ca9a60',
    color_yellow_hover: '#dfb257',
    color_dark_blue_hover: '#146f94',
    color_dark_yellow_hover: '#b1844d',
    color_comment_background_color: '#e5f4ff',
    color_pale_blue: '#edf3f9',

    color_lightgray: '#667078',
    color_darkgray: '#8c969d',
    color_bluegray: '#596e81',
    color_icongray: '#949a9f',
    color_bombay: '#aeb3b7',
    color_iron: '#dce0e3',
    color_really_dark_gray: '#344b5d',
    color_black_pearl: '#061523',
    color_blue_bayoux: '#586e81',
    color_dark: 'rgba(6, 21, 34, 0.8)',
    color_concrete: '#f2f2f2',
    color_emperor: '#535353',
    color_gray_silver: '#888',
    color_gray_notification_borders: '#d9dde1',

    color_blue: '#478cc8',
    color_blue_opacity: 'rgba(71, 140, 200, 0.16)',
    color_blue_opacity_tone3: 'rgba(71, 140, 200, 0.3)',
    color_blue_hover: '#3a7bbf',
    color_blue_pressed: '#3167b5',
    color_steel_blue: '#3e7db3',
    color_mariner: '#3279c1',
    color_link_water: '#EBF2F9',

    color_orange: '#e6ba4d',
    color_orange_opacity_tone5: 'rgba(230, 186, 77, 0.5)',
    color_orange_hover: '#dba92e',
    color_orange_pressed: '#d09f23',
    color_orange_np: '#da7c07',

    color_green: '#64b82a',
    color_green_hover: '#55a120',
    color_green_pressed: '#3d7915',
    color_yellow_green: '#c5dc77',
    riceFlower: '#f1ffe8',
    cream_can: '#f3cd6f',

    color_lime: '#cee57c',
    color_red: '#d0021b',
    color_bright_green: '#89c146',

    color_disabled: '#ccc',

    color_error_bg: '#fff0f2',
    color_error: '#d0021b',

    color_tip_background: 'rgba(29, 47, 62, 0.8)',

    color_button_disabled: '#dfdfdf',
    color_button_disabled_font: '#b8b8b8',

    color_filter_background: '#e5e5e5',

    color_contact_text: '#535353',
    color_contact_row_title: '#969696',

    color_wild_sand: '#f4f4f4',
    color_black_shadow: 'rgba(0, 0, 0, 0.3)',

    footer_bg: '#b9bfc3',
    footer_text: '#d9dde1',
    footer_link_text: '#eaedf0',
    footer_headings: '#596e81',

    color_tab_bg: '#ececec',
    color_tab_hover_text: 'rgba(57, 68, 79, 0.5)',

    color_highlighted_background: '#fff8e7',
    color_highlighted_background_hover: '#ffecc0',

    color_promt_background: 'rgba(221, 223, 225, 0.5)',
  },
  font_verdana: "Verdana, 'Dejavu Sans', Geneva, Tahoma, sans_serif",
  fontSize: {
    xl: '34px',
    lg: '26px',
    xm: '23px',
    md: '18px',
    xn: '16px',
    nm: '14px',
    sm: '12px',
    xsm: '11px',
  },
};

export default theme;
