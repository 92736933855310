import cloneDeep from 'lodash/cloneDeep';

/**
 * Функция прохождения по объекту (объект fields из AddTruckStore)
 * func - (obj, key) => {...}
 */
const mapFields = (inputObj, func, model) => {
  const obj = cloneDeep(inputObj);
  const objectModel = model || obj;
  const excludedFields = new Set([
    'advert_item',
    'advert_items',
    'contacts',
    'owner_contact',
    'step_two_data',
  ]);
  const advertProperties = Object.entries(objectModel).filter(([key]) => !excludedFields.has(key));
  const advertItemProperties = Object.entries(objectModel.advert_item || {});
  const stepTwoRegProperties = Object.entries(objectModel.step_two_data || {});
  advertProperties.forEach(([key]) => {
    func(obj, key);
  });
  advertItemProperties.forEach(([key]) => {
    func(obj.advert_item, key);
  });
  stepTwoRegProperties.forEach(([key]) => {
    func(obj.step_two_data, key);
  });
  if (objectModel?.advert_items) {
    objectModel.advert_items.forEach((item, index) => {
      if (item?.transport) {
        const advertItemsProperties = Object.entries(item.transport);
        advertItemsProperties.forEach(([key]) => {
          func(obj.advert_items[index]?.transport, key, item?.type);
        });
      }
    });
  }
  return obj;
};

export default mapFields;
