import styled from 'styled-components';

export const PageContainerStyles = styled.div<{ bgColor: string; isMobileApp: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ theme }) => theme.colors.limedSpruce};

  ${({ isMobileApp }) => (isMobileApp ? '--bottom-mobile-menu: 0px;' : '')};
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0 10px;
  }

  @media screen and (max-width: 512px) {
    padding: 0;
  }
`;

export const Content = styled.main`
  max-width: 1138px;
  margin: auto;
  padding: 20px 0 60px 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 78px);

  @media screen and (max-width: 512px) {
    padding: 5px 0 30px 0;
  }
`;
