import { observable, action, runInAction } from 'mobx';
import { addDraftAPI, getDraftAPI, deleteDraftAPI } from 'services/draftAPI';
import { IDraftStore } from 'interfaces/IDraftStore';
import { IRootStore } from 'stores/rootStore';
import { IAnyObject } from 'interfaces/GenericInterfaces';

// import promotion from '../mockData/promotion.json'; for testing API

class DraftStore implements IDraftStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable state = ''; // Состояние (для асинхронного запроса)
  @observable currentDraft = null; // Текущий полученный черновик
  @observable isSavedDraft = false; // Сохранялся ли черновик

  /**
   * Сброс полученного черновика
   */
  @action resetCurrentDraft = () => {
    this.currentDraft = null;
    this.isSavedDraft = false;
  };

  @action updateCommonParamInDraft = () => {
    if (this.isSavedDraft || this.currentDraft) {
      this.updateDraft();
    }
  };

  /**
   * Подставить данные из черновика в форму
   */
  @action setDraftToForm = (draft: any) => {
    // @ts-ignore
    this.rootStore.advertsStore.setAdToForm(draft);
  };

  /**
   * Получение черновика
   */
  @action getDraft = async () => {
    runInAction(() => {
      this.state = 'pending';
    });
    try {
      const res = await getDraftAPI();
      runInAction(() => {
        this.state = 'done';
      });
      if (res?.ok) {
        runInAction(() => {
          this.currentDraft = res.result;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        // @ts-ignore
        if (error?.response?.data?.error === 'not_found') {
          this.currentDraft = null;
        }
      });
    }
  };

  /**
   * Обновление черновика
   */
  @action updateDraft = async (parameters: IAnyObject = {}) => {
    if (!this.rootStore.advertsStore.isEdit) {
      runInAction(() => {
        this.state = 'pending';
      });
      try {
        const draft = this.rootStore.addTruckStore._generationData();
        await addDraftAPI(draft);
        runInAction(() => {
          this.state = 'done';
          if (!parameters.notSave) {
            this.isSavedDraft = true;
          }
        });
      } catch {
        runInAction(() => {
          this.state = 'error';
        });
      }
    }
  };

  /**
   * Удаление черновика
   */
  @action deleteDraft = async () => {
    runInAction(() => {
      this.state = 'pending';
    });
    try {
      runInAction(() => {
        this.resetCurrentDraft();
      });
      await deleteDraftAPI();
      runInAction(() => {
        this.state = 'done';
        this.isSavedDraft = false;
      });
    } catch {
      runInAction(() => {
        this.state = 'error';
      });
    }
  };
}

export default DraftStore;
