import styled from 'styled-components';

export const MainPageSkeletonStyles = styled.div`
  padding-top: 68px;

  .skeleton-title {
    margin-bottom: 22px;
    height: 68px;
    width: 50%;
  }

  .skeleton-tab {
    height: 63px;
    width: 15%;
  }

  .skeleton-search {
    height: 223px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 52px;
  }

  .skeleton-fav-btn {
    height: 42px;
    width: 15%;
  }

  .skeleton-search-btn {
    height: 42px;
    width: 20%;
  }

  .skeleton-search-result {
    height: 1000px;
  }

  @media screen and (max-width: 1080px) {
    .skeleton-search {
      height: 133px;
    }
  }

  @media screen and (max-width: 800px) {
    .skeleton-search {
      height: 205px;
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      justify-content: center;
    }

    .skeleton-fav-btn {
      display: none;
    }

    .skeleton-search-btn {
      width: 40%;
    }
  }

  @media screen and (max-width: 512px) {
    .skeleton-search {
      height: 301px;
    }

    .skeleton-tab {
      display: none;
    }

    .skeleton-title {
      margin-bottom: 40px;
      height: 68px;
      width: 100%;
    }
  }
`;
