import axiosInstance from './axios';

async function truckModelsAPI(model, branId) {
  if (!branId) {
    return [];
  }

  const config = {
    method: 'POST',
    url: '/public/api/v1/autocomplete/models',
    data: {
      prefix: model || '',
      brand_id: branId,
    },
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default truckModelsAPI;
