import { observable, action, computed, runInAction } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import getchecksAPI from 'src/services/getchecksAPI';
import getFullVinReportAPI from 'services/getFullVinReport';
import buyFullReportAPI from 'services/buyFullReportAPI';
import postTransportCheckAPI from 'services/postTransportCheckAPI';
import getUniqueNumbersAccess from 'src/services/getUniqueNumbersAccess';
import AsyncStateModel from 'models/AsyncStateModel';
import {
  IChecksStore,
  ICheckPopupParams,
  IChecks,
  ITechData,
  IRestrictions,
  IAccidents,
  IOwners,
  ITransportShortReport,
} from 'interfaces/IChecksStore';
import getNameTransportType from 'utils/getNameTransportType';

import { IRootStore } from 'stores/rootStore';
import { IStringObject } from 'interfaces/GenericInterfaces';
import { Exception } from '@sentry/react';

const billingErrors: IStringObject = {
  access_deny: 'У вас нет доступа для работы с виртуальным счетом.',
  payment_required: 'На вашем счете недостаточно средств. Пополните счет.',
  server_error: 'Сервис недоступен. Перезагрузите, пожалуйста, страницу или зайдите позже.',
  service_unavailable:
    'Сервис оплаты недоступен. Перезагрузите, пожалуйста, страницу или зайдите позже.',
  not_found: 'Что-то пошло не так. Перезагрузите страницу или попробуйте снова',
};

const fullReportErrors: IStringObject = {
  ...billingErrors,
  bad_request: 'У вас уже есть доступ к этому отчету. Перезагрузите, пожалуйста, страницу.',
};

const uniqueNumbersAccessErrors: IStringObject = {
  ...billingErrors,
  bad_request:
    'У вас уже есть доступ к vin и гос. номеру этого объявления. Перезагрузите, пожалуйста, страницу.',
};

class ChecksStore implements IChecksStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable checks: IChecks[] = [];
  @observable checksStatus = new AsyncStateModel();
  @observable checksTrucks = [];
  @observable transportShortReport: ITransportShortReport = {} as ITransportShortReport;

  @observable techData: ITechData = {
    data: {},
    progress_ok: false,
    invalid_fields: [],
  };

  @observable restrictions: IRestrictions = {
    data: [],
    progress_ok: false,
  };

  @observable accidents: IAccidents = {
    data: [],
    progress_ok: false,
  };

  @observable owners: IOwners = {
    data: [],
    progress_ok: false,
    valid_owner_count: false,
  };

  @observable reportOwnerCount: number = 0;
  @observable validOwnerCount: boolean = true;
  @observable buyingFullReportStatus = new AsyncStateModel();

  @observable checkPopupParams: ICheckPopupParams = {} as ICheckPopupParams;
  @observable checkTransportUrlStatus = new AsyncStateModel();
  @observable checkTransportUrlError = '';
  @observable checkTransportUrl = '';

  // TODO переписать на try catch и вынести логику рендера проверок на отдельные функции
  @observable isShowPopupGovNumVin = false;

  // Объект получения успешной покупки vin и гос.номера
  @observable uniqueNumbersAccess = [];

  @observable advertItemUniqueNumbersAccess: any = {};

  @observable statusGettingUniqueNumbersAccess = new AsyncStateModel();

  @observable priceReport = 29; // цена покупки полного отчета
  @observable priceGovNumVin = 10; // цена покупки вин и госномера

  @observable tractionType = '';
  @observable advertItemVin = '';
  @observable advertItemGovNum = '';
  @observable advertItemsVin = [];
  @observable advertItemsGovNum = [];

  @action setShowPopupGovNumVin = () => {
    this.isShowPopupGovNumVin = true;
  };

  @action setCloseShowPopupGovNumVin = () => {
    this.isShowPopupGovNumVin = false;
  };

  @action setUniqueNumbersAvailable = (state: boolean) => {
    // @ts-ignore
    this.rootStore.advertsStore.currentAdvert.unique_numbers_available = state;
  };

  setUniqueNumbersAccess = () => {
    const advert = cloneDeep(this.rootStore.advertsStore.currentAdvert);
    const { advert_item, advert_items } = advert;

    if (advert_item) {
      this.advertItemUniqueNumbersAccess = first(this.uniqueNumbersAccess);
      advert_item.vin = this.advertItemUniqueNumbersAccess.vin;
      advert_item.gov_num = this.advertItemUniqueNumbersAccess.gov_num;
    }

    if (advert_items) {
      this.uniqueNumbersAccess.forEach(
        (item: { vin?: string; gov_num?: string }, index: number) => {
          // @ts-ignore
          advert_items[index].transport.vin = item.vin;
          // @ts-ignore
          advert_items[index].transport.gov_num = item.gov_num;
        },
      );
    }
    this.rootStore.advertsStore.setCurrentAdvert(advert);
  };

  @action getUniqueNumbersAccess = async (advertId: { advert_id: string }): Promise<void> => {
    runInAction(() => {
      this.statusGettingUniqueNumbersAccess.request();
    });

    try {
      const res = await getUniqueNumbersAccess(advertId);
      this.statusGettingUniqueNumbersAccess.success();
      runInAction(() => {
        this.uniqueNumbersAccess = res.result.unique_numbers;
        this.setUniqueNumbersAccess();
      });
      this.rootStore.notificationStore.pushNotification('Оплата прошла успешно', 'success');
    } catch (_error) {
      this.statusGettingUniqueNumbersAccess.failure();
      // @ts-ignore
      const { error } = _error.response.data;
      const errorType = error === 'bad_request' ? 'warning' : 'error';
      const errorMessage =
        uniqueNumbersAccessErrors[error] || 'Произошла ошибка. Повторите попытку позже';
      this.rootStore.notificationStore.pushNotification(errorMessage, errorType, 5000);
    }
  };

  @action getGovNumVin = () => {
    const { advert_item, advert_items } = this.rootStore.advertsStore.currentAdvert;

    if (advert_item) {
      this.advertItemVin = advert_item.vin;
      this.advertItemGovNum = advert_item.gov_num;
    } else if (advert_items) {
      advert_items.map(({ transport }: any) => {
        // @ts-ignore
        this.tractionType = getNameTransportType(advert_items[0].type).toLowerCase();
        if (transport.vin) {
          // @ts-ignore
          this.advertItemsVin.push(transport.vin);
        }

        if (transport.gov_num) {
          // @ts-ignore
          this.advertItemsGovNum.push(transport.gov_num);
        }

        return [];
      });
    }
  };

  // TODO rewrite
  buttonDescription = (vinParam: any, govNumParam: any, simpleText?: string) => {
    if (vinParam || govNumParam) {
      const vin =
        !isEmpty(vinParam) &&
        isEmpty(govNumParam) &&
        `${simpleText ? `Показать VIN` : `Показать VIN за ${this.priceGovNumVin} ат.`}`;
      const govNum =
        !isEmpty(govNumParam) &&
        isEmpty(vinParam) &&
        `${simpleText ? `Показать гос.номер` : `Показать гос.номер за ${this.priceGovNumVin} ат.`}`;
      const vinGovNum =
        !isEmpty(govNumParam) &&
        !isEmpty(vinParam) &&
        `${
          simpleText
            ? `Показать гос.номер${this.advertItemsGovNum?.length > 1 ? 'a' : ''} и VIN`
            : `Показать гос.номер и VIN за ${this.priceGovNumVin} ат.`
        }`;

      return vin || govNum || vinGovNum;
    }

    return null;
  };

  setDesciption = (simpleText?: string) => {
    const { advert_items } = this.rootStore.advertsStore.currentAdvert;
    if (advert_items) {
      return this.buttonDescription(this.advertItemsVin, this.advertItemsGovNum, simpleText);
    }

    return this.buttonDescription(this.advertItemVin, this.advertItemGovNum, simpleText);
  };

  @action getChecks = async (advertId: string): Promise<void> => {
    this.checksStatus.request();
    try {
      const res = await getchecksAPI(advertId);
      runInAction(() => {
        this.checksStatus.success();
        this.checks = res.result.checks;
      });
    } catch (error) {
      this.checksStatus.failure();
      // eslint-disable-next-line no-console
      console.log(`Список проверок - ${error}`);
    }
  };

  @action getTransportShortReport = (): void => {
    // @ts-ignore
    const { advert_item, advert_items } = this.rootStore.advertsStore.currentAdvert;

    if (advert_items) {
      // @ts-ignore
      this.transportShortReport =
        !isEmpty(advert_items[0].transport.transport_short_report) &&
        advert_items[0].transport.transport_short_report;
      return;
    }

    // @ts-ignore
    this.transportShortReport =
      !isEmpty(advert_item?.transport_short_report) && advert_item?.transport_short_report;
  };

  @action getTransportFullReport = async (advertId: string): Promise<void> => {
    try {
      const res = await getFullVinReportAPI(advertId);
      runInAction(() => {
        this.techData = res.result.tech_data;
        this.restrictions = res.result.restrictions;
        this.accidents = res.result.accidents;
        this.owners = res.result.owners;
        this.reportOwnerCount = res.result.owners.report_owner_count;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  @action buyFullReport = async (id: string) => {
    this.buyingFullReportStatus.request();
    try {
      const res = await buyFullReportAPI(id);

      this.buyingFullReportStatus.success();
      runInAction(() => {
        this.rootStore.advertsStore.currentAdvert.full_report_available = true;
        this.uniqueNumbersAccess = res.result.unique_numbers;
        this.setUniqueNumbersAccess();
      });
      this.rootStore.notificationStore.pushNotification('Оплата прошла успешно', 'success');
    } catch (_error) {
      this.buyingFullReportStatus.failure();
      // @ts-ignore
      const { error } = _error.response.data;
      const errorType = error === 'bad_request' ? 'warning' : 'error';
      const errorMessage = fullReportErrors[error] || 'Произошла ошибка. Повторите попытку позже';
      this.rootStore.notificationStore.pushNotification(errorMessage, errorType, 5000);
    }
  };

  @action setCheckTransportUrlError = (status: string) => {
    this.checkTransportUrlError = status;
  };

  @action checkTransport = async (
    advertId: string,
    checkId: string,
    transportPosition?: number,
  ): Promise<void> => {
    this.checkTransportUrlStatus.request();
    this.setCheckTransportUrlError('');

    try {
      const res = await postTransportCheckAPI({
        advert_id: advertId,
        check_id: checkId,
        transport_position: transportPosition,
      });
      runInAction(() => {
        this.checkTransportUrlStatus.success();
        this.checkTransportUrl = res.result.check_result_id;
      });
    } catch (error) {
      // @ts-ignore
      this.setCheckTransportUrlError(error?.response?.data?.error || '');
      this.checkTransportUrlStatus.failure();
    }
  };

  @action setCheckPopupParams = (obj: ICheckPopupParams): void => {
    this.checkPopupParams = obj;
  };

  @action resetCheckPopupParams = (): void => {
    this.checkPopupParams.check_id = '';
  };

  @computed get isAdvertItemsMainVin() {
    return !isEmpty(this.rootStore.advertsStore.currentAdvert.advert_items?.[0].transport.vin);
  }

  @computed get isAdvertItemsMainGovNum() {
    return !isEmpty(this.rootStore.advertsStore.currentAdvert.advert_items?.[0].transport.gov_num);
  }

  @computed get isAdvertItemVin() {
    return !isEmpty(this.rootStore.advertsStore.currentAdvert.advert_item?.vin);
  }

  @computed get isAdvertItemGovNum() {
    return !isEmpty(this.rootStore.advertsStore.currentAdvert.advert_item?.gov_num);
  }
}

export default ChecksStore;
