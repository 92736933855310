const getTransportCategory = (advertItemType) => {
  switch (advertItemType) {
    case 'trailer':
    case 'semitrailer':
      return 'trailer';
    case 'special_machinery':
    case 'truck_crane':
    case 'excavator':
    case 'dozer':
    case 'loader':
    case 'other_special':
      return 'special_machinery';
    default:
      return advertItemType;
  }
};

export default getTransportCategory;
