import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import get from 'lodash/get';
import Filter from '../Filter';

const FilterList: FC = observer(() => {
  const {
    saveFilterStore: { filters },
    loginContactInfoStore: { loginContactInfo },
  } = useStores();

  return (
    <div>
      {filters.map(
        ({ _id, search_params, filter_name, query_string, subscriptions, geo_text }, index) => {
          return (
            <Filter
              key={_id}
              id={_id}
              searchParams={search_params}
              queryString={query_string}
              email={subscriptions?.email || loginContactInfo.e_mail}
              brand={get(search_params, 'brand')}
              model={get(search_params, 'model')}
              subscriptions={subscriptions}
              filterName={filter_name}
              advertType={get(search_params, 'advert_type')}
              capacityFrom={get(search_params, 'capacity.from')}
              capacityTo={get(search_params, 'capacity.to')}
              mileageFrom={get(search_params, 'mileage.from')}
              mileageTo={get(search_params, 'mileage.to')}
              workingHoursFrom={get(search_params, 'working_hours.from')}
              workingHoursTo={get(search_params, 'working_hours.to')}
              yearFrom={get(search_params, 'year.from')}
              yearTo={get(search_params, 'year.to')}
              priceFrom={get(search_params, 'price.from')}
              priceTo={get(search_params, 'price.to')}
              periodType={get(search_params, 'period_type')}
              geoText={geo_text}
              advertItemType={get(search_params, 'advert_item_type')}
              available={search_params.is_available && 'is_available'}
              new={search_params.only_new && 'only_new'}
              vin={search_params.with_vin && 'with_vin'}
              // withLizing={search_params.with_lizing && 'with_lizing'}
              driver={search_params.with_driver && 'with_driver'}
              proposalType={get(search_params, 'proposal_type')}
              index={index}
            />
          );
        },
      )}
    </div>
  );
});

export default FilterList;
