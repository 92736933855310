import React, { FC, useEffect } from 'react';
import Popover from 'ati-ui-react/components/Tip/Popover';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react-lite';
import { PopupHintContainer } from './PopupHintStyles';

interface IPopoverHint {
  isShowPopover: boolean;
  onCloseClick: () => void;
  mainContent: any;
  header: any;
}

const PopoverHint: FC<IPopoverHint> = observer(
  ({ isShowPopover, onCloseClick, mainContent, header, children }) => {
    const {
      favoritesStore: { isShowPopoverHintFavorite },
      saveFilterStore: { isShowPopoverHintFilter },
    } = useStores();

    useEffect(() => {
      if (isShowPopoverHintFavorite) {
        window.localStorage.setItem('hint-favorite-popover', 'true');
      }
    }, [isShowPopoverHintFavorite]);

    useEffect(() => {
      if (isShowPopoverHintFilter) {
        window.localStorage.setItem('hint-filter-popover', 'true');
      }
    }, [isShowPopoverHintFilter]);

    return (
      <PopupHintContainer>
        <Popover
          fixedBodyPosition
          isClosedOnOutsideClick
          visible={isShowPopover}
          onCloseClick={onCloseClick}
          theme="light"
          placement="bottom"
          isCentralAligmentOnAdaptive
          mainContentClassName={'content-section'}
          templateWrapperClassName={'templateWrapperClassName'}
          header={header}
          mainContent={mainContent}
          isAdaptive={false}
        >
          {children}
        </Popover>
      </PopupHintContainer>
    );
  },
);

export default PopoverHint;
