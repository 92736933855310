import axiosInstance from './axios';

async function truckBrandsAPI(brand, category) {
  const config = {
    method: 'POST',
    url: '/public/api/v1/autocomplete/brands',
    data: {
      prefix: brand || '',
      category,
    },
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default truckBrandsAPI;
