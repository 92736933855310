import { observable, action, runInAction } from 'mobx';

import getStarsAPI from 'services/stars';
import getMyAdvertsAPI from 'services/getMyAdvertsAPI';
import deleteAdvertAPI from 'services/deleteAdvertAPI';
import patchAdvertAPI from 'services/patchAdvertAPI';
import deleteMultiAPI from 'src/services/deleteMultiAPI';
import deleteFavoritesMultiAPI from 'src/services/deleteFavoritesMultiAPI';
import patchMultiAPI from 'src/services/patchMultiAPI';
import getTransportsCount from 'services/getTransportsCount';
import AsyncStateModel from 'models/AsyncStateModel';
import ProcessStateModel from 'models/ProcessStateModel';
import PaginationModel from 'models/PaginationModel';
import { ICabinetPageStore, IMetaData, IStateReasons } from 'interfaces/ICabinetPageStore';
import { IRootStore } from 'stores/rootStore';

class CabinetPageStore implements ICabinetPageStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable advertsLoadingState = new AsyncStateModel();
  @observable favoritesLoadingState = new AsyncStateModel();
  @observable archivingAdvertState = new AsyncStateModel();
  @observable deletionAdvertState = new AsyncStateModel();
  @observable recoveryAdvertState = new AsyncStateModel();
  @observable advertsCountLoadingState = new AsyncStateModel();
  @observable starsLoadingState = new AsyncStateModel();
  @observable callbackProcessState = new ProcessStateModel();
  @observable pagination = new PaginationModel();
  @observable myAccount = {};
  @observable myStars = {};

  @observable myActiveSaleAdverts = [];
  @observable myActiveRentAdverts = [];
  @observable myActivePurchaseAdverts = [];
  @observable myActiveProposalsPurchaseAdverts = [];
  @observable myActiveProposalsRentAdverts = [];
  @observable myArchivedAdverts = [];
  @observable myFavoritesAdverts = [];
  @observable selectedAdverts = new Set();

  @observable myActiveSaleAdvertsCount = 0;
  @observable myActiveRentAdvertsCount = 0;

  @observable myActivePurchaseAdvertsCount = 0;

  @observable myActiveProposalsPurchaseAdvertsCount = 0;
  @observable myActiveProposalsRentAdvertsCount = 0;
  @observable myArchivedAdvertsCount = 0;
  @observable myFavoritesAdvertsCount = 0;
  @observable advertsCountLoaded = false;

  @observable checkedArchivedAdvertState = new AsyncStateModel();
  @observable checkedDeletedAdvertState = new AsyncStateModel();
  @observable checkedRestoreAdvertState = new AsyncStateModel();
  @observable checkedFavoritesAdvertState = new AsyncStateModel();

  @observable isFetchAdvertsServerError = false;

  @observable groupModals: IStateReasons = {
    isReasonModal: false, // состояние модального окна для выбора причины
    isDeleteModal: false, // состтояние модального окна для подтверждения удаления
  };

  @action resetErrors = () => {
    this.isFetchAdvertsServerError = false;
  };

  /**
   * Получение звезд для собственного аккаунта
   */
  @action getMyStars = async (): Promise<void> => {
    const stars = await getStarsAPI([this.rootStore.profileStore.atiCode]);
    runInAction(() => {
      this.myStars = stars;
    });
  };

  /**
   * Получение моих объявлений
   */
  @action getMyAdverts = async (data: IMetaData) => {
    this.advertsLoadingState.request();

    try {
      const result = await getMyAdvertsAPI(data);
      runInAction(() => {
        this.advertsLoadingState.success();
        this.myAccount = result.result.accounts;
      });

      return result.result.adverts;
    } catch (error) {
      this.advertsLoadingState.failure();

      // @ts-ignore
      if (error.response?.status >= 500 && error.response?.status <= 511) {
        runInAction(() => {
          this.isFetchAdvertsServerError = true;
        });
      }
    }

    return [];
  };

  @action getMyActiveSaleAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({ status: 'active', type: 'sale' }),
    );
    runInAction(() => {
      this.myActiveSaleAdverts = adverts;
    });
  };

  @action getMyActiveRentAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({ status: 'active', type: 'rent' }),
    );
    runInAction(() => {
      this.myActiveRentAdverts = adverts;
    });
  };

  @action getMyActivePurchaseAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({ status: 'active', type: 'purchase' }),
    );
    runInAction(() => {
      this.myActivePurchaseAdverts = adverts;
    });
  };

  @action getMyActiveProposalsPurchaseAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({
        status: 'active',
        type: 'purchase',
        proposal_type: 'purchase',
      }),
    );
    runInAction(() => {
      this.myActiveProposalsPurchaseAdverts = adverts;
    });
  };

  @action getMyActiveProposalsRentAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({
        status: 'active',
        type: 'purchase',
        proposal_type: 'rent',
      }),
    );
    runInAction(() => {
      this.myActiveProposalsRentAdverts = adverts;
    });
  };

  @action getMyArchivedAdverts = async (): Promise<void> => {
    const adverts = await this.getMyAdverts(
      this.getAdvertsAccordingToParams({ status: 'archived' }),
    );
    runInAction(() => {
      this.myArchivedAdverts = adverts;
    });
  };

  @action getMyFavoritesAdverts = async (): Promise<void> => {
    this.favoritesLoadingState.request();
    await this.rootStore.favoritesStore.getFavorites(this.getAdvertsAccordingToParams());

    if (Object.keys(this.rootStore.favoritesStore.favoritesAccounts).length > 0) {
      await this.rootStore.favoritesStore.getFavoritesStars();
    }

    runInAction(() => {
      this.myFavoritesAdverts = this.rootStore.favoritesStore.favoritesAdverts.map(
        (advert: any) => ({
          ...advert,
          is_favorite: true,
          // TODO: флаг для кнопки "Избранное", уберется с рефакторингом миникарточки
          isCabinet: true,
        }),
      );
      this.favoritesLoadingState.success();
    });
  };

  @action resetMyAdverts = () => {
    this.myActiveSaleAdverts = [];
    this.myActiveRentAdverts = [];
    this.myActivePurchaseAdverts = [];
    this.myActiveProposalsPurchaseAdverts = [];
    this.myActiveProposalsRentAdverts = [];
    this.myArchivedAdverts = [];
    this.myFavoritesAdverts = [];
    this.rootStore.favoritesStore.favoritesAdverts = [];
  };

  /**
   * Получение количества объявлений
   */
  @action getAdvertsCount = () => {
    // eslint-disable-next-line promise/catch-or-return
    getTransportsCount()
      // eslint-disable-next-line promise/always-return
      .then((response) => {
        runInAction(() => {
          this.myActiveSaleAdvertsCount = response?.result?.sale;
          this.myActiveRentAdvertsCount = response?.result?.rent;
          this.myActivePurchaseAdvertsCount = response?.result?.purchase;
          this.myActiveProposalsPurchaseAdvertsCount = response?.result?.proposal_purchase;
          this.myActiveProposalsRentAdvertsCount = response?.result?.proposal_rent;
          this.myArchivedAdvertsCount = response?.result?.archived;
          this.myFavoritesAdvertsCount = response?.result?.favorites;
        });
      })
      .catch(() => {})
      // TODO нужно поправить
      // eslint-disable-next-line promise/always-return
      .then(() => {
        runInAction(() => {
          this.advertsCountLoaded = true;
        });
      });
  };

  // Get user owner adverts according to params
  @action getAdvertsAccordingToParams = (params?: any) => ({
    limit: this.pagination.limit,
    offset: this.pagination.offset,
    ...params,
  });

  @action handleCallbackWithState = (callback: () => void) => {
    this.callbackProcessState.start();
    callback();
    this.callbackProcessState.stop();
  };

  @action pushErrorNotification = () => {
    this.rootStore.notificationStore.pushNotification(
      'Что-то пошло не так. Попробуйте еще раз или перезагрузите страницу',
      'error',
    );
  };

  @action moveAdToArchive = async (advertId: string, extraOptionsObj = {}, callback = () => {}) => {
    this.archivingAdvertState.request();
    try {
      await patchAdvertAPI({
        _id: advertId,
        status: 'archived',
        ...extraOptionsObj,
      });
      this.archivingAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявление снято с публикации и перенесено в архив',
        'success',
      );
      this.selectedAdverts.delete(advertId);
    } catch {
      this.archivingAdvertState.failure();
      this.pushErrorNotification();
    }
    this.handleCallbackWithState(callback);
  };

  @action restoreAd = async (advertId: string, callback = () => {}): Promise<void> => {
    this.recoveryAdvertState.request();
    try {
      await patchAdvertAPI({
        _id: advertId,
        status: 'active',
      });
      this.recoveryAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявление успешно восстановлено из архива',
        'success',
      );
      this.selectedAdverts.delete(advertId);
    } catch {
      this.recoveryAdvertState.failure();
      this.pushErrorNotification();
    }
    this.handleCallbackWithState(callback);
  };

  @action deleteAd = async (advertId: string, callback = () => {}): Promise<void> => {
    this.deletionAdvertState.request();
    try {
      await deleteAdvertAPI(advertId);
      this.deletionAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявление было успешно удалено',
        'success',
      );
      this.selectedAdverts.delete(advertId);
    } catch {
      this.deletionAdvertState.failure();
      this.pushErrorNotification();
    }
    this.handleCallbackWithState(callback);
  };

  // ----- Групповые операции -------

  /**
   * Выделить объявление
   * @param {string} id
   */
  @action selectAdvert = (id: string): void => {
    this.selectedAdverts.add(id);
  };

  /**
   * Выделить несколько объявлений
   * @param {array} ids
   */
  @action selectAdverts = (ids: string[] = []): void => {
    ids.forEach((id) => {
      this.selectedAdverts.add(id);
    });
  };

  /**
   * Снять выделение
   * @param {string} id
   */
  @action unselectAdvert = (id: string): void => {
    this.selectedAdverts.delete(id);
  };

  /**
   * Снять выделение с нескольких объявлений
   * @param {array} ids
   */
  @action unselectAdverts = (ids: string[] = []): void => {
    ids.forEach((id) => {
      this.selectedAdverts.delete(id);
    });
  };

  /**
   * Снять все выделения
   */
  @action unselectAllAdverts = () => {
    this.selectedAdverts.clear();
  };

  /*
    Удалить выделенные объявления
   */
  @action removeCheckedAdverts = async (): Promise<void> => {
    this.deletionAdvertState.request();
    try {
      await deleteMultiAPI({ adverts_ids: [...this.selectedAdverts] });
      this.checkedDeletedAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявления были успешно удалены',
        'success',
      );
    } catch {
      this.checkedDeletedAdvertState.failure();
      this.pushErrorNotification();
    }
  };

  /*
    Удалить выделенные объявления из избранных
  */
  @action removeCheckedFavoritesAdverts = async (): Promise<void> => {
    this.deletionAdvertState.request();
    try {
      await deleteFavoritesMultiAPI({ adverts_ids: [...this.selectedAdverts] });
      this.checkedFavoritesAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявления были успешно удалены из избранных',
        'success',
      );
    } catch {
      this.checkedFavoritesAdvertState.failure();
      this.pushErrorNotification();
    }
  };

  /*
    Передвинуть выделенные объявления в архив
   */
  @action moveAdToArchiveCheckedAdverts = async (diff: {
    [key: string]: string;
  }): Promise<void> => {
    this.deletionAdvertState.request();
    const response = await patchMultiAPI({
      adverts_ids: [...this.selectedAdverts],
      diff: { status: 'archived', ...diff },
    });

    if (response.result[200].length > 0) {
      this.checkedArchivedAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявления сняты с публикации и перенесены в архив',
        'success',
      );
    } else {
      this.checkedArchivedAdvertState.failure();
      this.pushErrorNotification();
    }
  };

  @action restoreCheckedAdverts = async (): Promise<void> => {
    this.deletionAdvertState.request();

    const response = await patchMultiAPI({
      adverts_ids: [...this.selectedAdverts],
      diff: { status: 'active' },
    });

    if (response.result[200].length > 0) {
      this.checkedRestoreAdvertState.success();
      this.rootStore.notificationStore.pushNotification(
        'Объявления успешно восстановлены из архива',
        'success',
      );
    } else {
      this.checkedRestoreAdvertState.failure();
      this.pushErrorNotification();
    }
  };

  /* ---- Экшены для модальных окошек в плашке групповых операций ---- */
  @action setOpenModal = (modal: string) => {
    this.groupModals[modal] = true;
  };

  @action setCloseModal = (modal: string) => {
    this.groupModals[modal] = false;
  };
  /* --------------------- */

  @action resetCabinetPageStore = () => {
    this.pagination.reset();
    this.myActiveSaleAdverts = [];
    this.myActiveRentAdverts = [];
    this.myActiveProposalsPurchaseAdverts = [];
    this.myActiveProposalsRentAdverts = [];
    this.myArchivedAdverts = [];
    this.myFavoritesAdverts = [];
    this.selectedAdverts = new Set();
    this.myActiveSaleAdvertsCount = 0;
    this.myActiveRentAdvertsCount = 0;
    this.myActivePurchaseAdvertsCount = 0;
    this.myArchivedAdvertsCount = 0;
    this.myFavoritesAdvertsCount = 0;
    this.myActiveProposalsPurchaseAdvertsCount = 0;
    this.myActiveProposalsRentAdvertsCount = 0;
    this.advertsCountLoaded = false;
  };
}

export default CabinetPageStore;
