import styled from 'styled-components';

export const ToCabinetButtonContainer = styled.div`
  .link-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &:hover {
    .truck-icon svg path {
      fill: #3a7bbf;
    }
    .text-to-cabinet a {
      color: #3a7bbf;
      text-decoration: underline;
    }
  }

  .text-to-cabinet {
    display: inline-block;
    margin-left: 6px;

    a {
      color: #478cc8;
    }
  }

  .truck-icon {
    svg {
      position: relative;
      top: -2px;
    }

    svg path {
      fill: #478cc8;
    }
  }

  &:hover {
    .text-to-cabinet a {
      text-decoration: none;
    }
  }

  @media (max-width: 1023px) {
    width: 28px;
    height: 28px;
    border: 1px solid #478cc8;
    border-radius: 4px;
    box-sizing: border-box;

    .text {
      display: none;
    }

    .truck-icon {
      svg {
        position: inherit;
      }

      svg path {
        fill: #478cc8;
      }
    }
  }

  @media (max-width: 512px) {
    && .text-to-cabinet {
      font-size: ${({ theme }) => theme.fontSize.sm};
    }
  }
`;
