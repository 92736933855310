import axios from 'axios';

async function getDictionariesAPI() {
  const config = {
    method: 'GET',
    url: '/public/api/v1/dicts',
  };

  const res = await axios(config);
  return res.data;
}

export default getDictionariesAPI;
