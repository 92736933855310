import axios from 'axios';
import getEnvironDomain from 'utils/getEnvironDomain';

async function getOwnershipsAPI() {
  const config = {
    method: 'GET',
    url: `https://api.${getEnvironDomain()}/dictionaries/ownerships`,
  };

  const res = await axios(config);
  return res.data;
}

export default getOwnershipsAPI;
