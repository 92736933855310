import { observable, action, runInAction } from 'mobx';
import { addFavoritesAPI, getFavoritesAPI, deleteFavoritesAPI } from 'services/favoritesAPI';
import getStarsAPI from 'src/services/stars';
import AsyncStateModel from 'models/AsyncStateModel';
import ProcessStateModel from 'models/ProcessStateModel';
import { IFavoritesStore } from 'interfaces/IFavoritesStore';
import { IRootStore } from 'stores/rootStore';

class FavoritesStore implements IFavoritesStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable statusGettingFavorites = new AsyncStateModel(); // Состояние (для асинхронного запроса)
  @observable statusAddingFavorites = new AsyncStateModel(); // Состояние (для асинхронного запроса)
  @observable statusDeletingFavorites = new AsyncStateModel(); // Состояние (для асинхронного запроса)
  @observable callbackProcessState = new ProcessStateModel();
  @observable favoritesAdverts = []; // Избранные объявления
  @observable localFavoritesID: string[] = []; // ID объявлений, только что добавленных в избранное
  @observable requestFavoritesID: string[] = []; // ID объявлений, которые в процессе добавленя/удаления из избранного
  @observable favoritesAccounts = {};
  @observable favoritesStars = {};
  @observable isShowPopoverHintFavorite = false;

  /**
   * Получение звезд для избранных объявлений
   */
  @action getFavoritesStars = async () => {
    const favoritesID = Object.values(this.favoritesAccounts).map((el: any) => el.account_id);
    const stars = await getStarsAPI(favoritesID);
    runInAction(() => {
      this.favoritesStars = stars;
    });
  };

  /**
   * Получение избранных
   */
  @action getFavorites = async (data: any) => {
    runInAction(() => {
      this.statusGettingFavorites.request();
    });
    try {
      const res = await getFavoritesAPI(data);
      runInAction(() => {
        if (res?.ok) {
          this.favoritesAdverts = res.result.adverts;
          this.favoritesAccounts = res?.result.accounts;
        }
        this.statusGettingFavorites.success();
      });
    } catch (error) {
      runInAction(() => {
        this.favoritesAdverts = [];
        this.favoritesAccounts = [];
        this.statusGettingFavorites.failure();
      });

      // @ts-ignore
      if (error.response.status >= 500 && error.response.status <= 511) {
        runInAction(() => {
          // @ts-ignore
          this.rootStore.cabinetPageStore.isFetchAdvertsServerError = true;
        });
      }
    }
  };

  /**
   * Добавление объявления в избранное
   */
  @action addFavorites = async (id: any) => {
    // @ts-ignore
    if (!this.rootStore.advertsStore.isEdit) {
      runInAction(() => {
        this.statusAddingFavorites.request();
        this.requestFavoritesID = [id, ...this.requestFavoritesID];
      });
      try {
        await addFavoritesAPI(id);
        runInAction(() => {
          // @ts-ignore
          this.localFavoritesID = [id, ...this.localFavoritesID];
          this.statusAddingFavorites.success();
          this.requestFavoritesID = this.requestFavoritesID.filter((_id) => _id !== id);
        });
      } catch {
        runInAction(() => {
          this.statusAddingFavorites.failure();
          this.requestFavoritesID = this.requestFavoritesID.filter((_id) => _id !== id);
        });
      }
    }
  };

  /**
   * Удаление объявления из избранного
   */
  @action deleteFavorites = async (id: any, callback = () => {}) => {
    runInAction(() => {
      this.statusDeletingFavorites.request();
      this.requestFavoritesID = [id, ...this.requestFavoritesID];
    });
    try {
      await deleteFavoritesAPI(id);
      runInAction(async () => {
        this.statusDeletingFavorites.success();
        this.localFavoritesID = this.localFavoritesID.filter((_id) => _id !== id);
        this.requestFavoritesID = this.requestFavoritesID.filter((_id) => _id !== id);
        // @ts-ignore
        this.rootStore.cabinetPageStore.selectedAdverts.delete(id);
        await this.callbackProcessState.start();
        await callback();
        await this.callbackProcessState.stop();
      });
    } catch {
      runInAction(() => {
        this.statusDeletingFavorites.failure();
        this.requestFavoritesID = this.requestFavoritesID.filter((_id) => _id !== id);
      });
    }
  };

  @action setPopoverHintFavoriteVisible = (value: boolean): void => {
    this.isShowPopoverHintFavorite = value;
  };
}

export default FavoritesStore;
