import axiosInstance from './axios';

async function checkExistanceAdvertAPI(params) {
  const config = {
    method: 'GET',
    url: '/api/v1/transports/existence',
    params,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default checkExistanceAdvertAPI;
