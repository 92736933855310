import { observable, action, computed } from 'mobx';
import { IAsyncStateModel } from 'interfaces/IAsyncStateModel';

class AsyncStateModel implements IAsyncStateModel {
  constructor(initialState = 'default') {
    this.state = initialState;
  }

  @observable state: string;

  @action default() {
    this.state = 'default';
  }

  @action request() {
    this.state = 'request';
  }

  @action success() {
    this.state = 'success';
  }

  @action failure() {
    this.state = 'failure';
  }

  @computed get isRequest() {
    return this.state === 'request';
  }

  @computed get isSuccess() {
    return this.state === 'success';
  }

  @computed get isFailure() {
    return this.state === 'failure';
  }
}

export default AsyncStateModel;
