import axiosInstance from './axios';

async function patchAdvertAPI(data) {
  const config = {
    method: 'PATCH',
    url: '/api/v1/transports',
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default patchAdvertAPI;
