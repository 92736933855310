const removeFalsyValues = (object) => {
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value && typeof value === 'object') {
      removeFalsyValues(value);
    }
    if (
      // TODO исправить
      // eslint-disable-next-line unicorn/explicit-length-check
      (value && typeof value === 'object' && !Object.keys(value).length) ||
      value === null ||
      value === undefined ||
      value === 0 ||
      value === ''
    ) {
      if (Array.isArray(object)) {
        object.splice(key, 1);
      } else {
        delete object[key];
      }
    }
  });
  return object;
};

export default removeFalsyValues;
