import { observable, action, computed } from 'mobx';
import { IPagination } from 'interfaces/IPagination';

const initialValueOfPage = 1;
const initialValueOfLimit = 10;
const initialValueOfCountAdverts = 0;

class PaginationModel implements IPagination {
  @observable page: number;
  @observable limit: number;
  @observable countAdverts: number;
  @observable isChanged: boolean;

  constructor(initialPage = initialValueOfPage) {
    this.page = initialPage;
    this.limit = initialValueOfLimit;
    this.countAdverts = initialValueOfCountAdverts;
    this.isChanged = false;
  }

  @computed get offset(): number {
    return this.limit * (this.page - 1);
  }

  @computed get countPages(): number {
    return Math.ceil(this.countAdverts / this.limit);
  }

  @action setLimit = (limit: number): void => {
    this.limit = limit || initialValueOfLimit;
    // Обработка случая установки лимита, большего чем число объявлений
    if (limit > this.countAdverts) {
      this.page = 1;
      // Обработка случая установки большого лимита на последней странице
    } else if (this.offset > limit * this.countPages) {
      this.page = this.countPages;
    }
    this.isChanged = true;
  };

  @action setCountAdverts = (count: number): void => {
    this.countAdverts = count;
  };

  @action setPage = (page: number): void => {
    if (page > this.countPages) {
      this.page = this.countPages;
    } else {
      this.page = page;
    }
    this.isChanged = true;
  };

  @action resetPage = (): void => {
    this.page = initialValueOfPage;
    this.isChanged = false;
  };

  @action resetLimit = (): void => {
    this.limit = initialValueOfLimit;
  };

  @action reset = () => {
    this.page = initialValueOfPage;
    this.limit = initialValueOfLimit;
    this.countAdverts = initialValueOfCountAdverts;
    this.isChanged = false;
  };
}

export default PaginationModel;
