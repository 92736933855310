import axiosInstance from './axios';

async function getFiltersAPI(params) {
  const config = {
    method: 'GET',
    url: `/api/v1/filters`,
    params,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getFiltersAPI;
