import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';

import getEnvironDomain from 'utils/getEnvironDomain';
import BreadcrumbsComponent from 'ati-ui-react/components/Breadcrumbs';
import Link from 'ati-ui-react/components/Link';
import QuestionOut from 'ati-ui-react/components/icons/QuestionOut';
import QuestionSolid from 'ati-ui-react/components/icons/QuestionSolid';
import getEllipsisText from 'utils/getEllipsisText';

import PopoverFilters from 'generic/PopoverFilters';
import ToCabinetButton from 'generic/ToCabinetButton';
import FavoriteLink from 'generic/FavoriteLink';
import {
  BreadcrumbsStyles,
  BreadcrumbsContainer,
  BreadcrumbsMenuStyles,
} from './BreadcrumbsStyles';

const Breadcrumbs = observer(() => {
  const [isShown, setIsShown] = useState(false);
  const {
    pagesStore: { breadcrumbs, isShowedBreadcrumbs, currentPage, isSearchPage },
    advertsStore: { getTruckName, currentAdvert },
    appStore: { width },
  } = useStores();

  const history = useHistory();
  const goToPath = (path) => (e) => {
    e.preventDefault();
    history.push(path);
  };

  const shortTruckName = useMemo(() => getEllipsisText(getTruckName(), 100), [currentAdvert]);

  const linksStore = {
    cabinet: {
      name: 'Ваши объявления',
      href: '/cabinet',
      onClick: goToPath('/cabinet'),
    },
    form: {
      name: 'Добавить объявление',
      href: '/cabinet/form',
      onClick: goToPath('/cabinet/form'),
    },
    'after-publication': {
      name: 'Объявление опубликовано',
    },
    advert: {
      name: shortTruckName,
    },
  };

  const getLinks = () => {
    const startLinks = [
      {
        name: 'Главная',
        href: `https:/${getEnvironDomain()}`,
        id: '1',
      },
      {
        name: 'Продажа и аренда транспорта',
        href: '/',
        id: '2',
        onClick: goToPath('/'),
      },
    ];
    const pages = breadcrumbs.split('/').slice(1);
    const resultLinks = [
      ...startLinks,
      ...pages.map((item) => linksStore[item]).filter((el) => el),
    ];
    resultLinks[resultLinks.length - 1].href = '';
    return resultLinks;
  };

  const links = getLinks();

  if (!isShowedBreadcrumbs) {
    return null;
  }

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsStyles>
        <BreadcrumbsComponent links={links} />
        {currentPage.match(/cabinet|form/) && (
          <div
            className="how-add-ad"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <Link href={`//help.${getEnvironDomain()}/truckmarket`} target="_blank" small gray>
              <div className="icons">
                <span>Как разместить объявление</span>
                <span className="how-icon">
                  {isShown ? (
                    <QuestionSolid color="#478cc8" width={13} height={13} />
                  ) : (
                    <QuestionOut width={13} height={13} />
                  )}
                </span>
              </div>
            </Link>
          </div>
        )}
      </BreadcrumbsStyles>
      {isSearchPage && (
        <BreadcrumbsMenuStyles>
          <div className="icons">
            <div className="icon-section">
              <PopoverFilters />
            </div>
            <div className="icon-section">
              <FavoriteLink />
            </div>
            <div className="icon-section">
              <ToCabinetButton />
            </div>
          </div>
        </BreadcrumbsMenuStyles>
      )}
    </BreadcrumbsContainer>
  );
});

export default Breadcrumbs;
