import { observable, action, computed, toJS } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import normalizeValue from 'utils/normalizeValue';
import mapAddFormFields from 'utils/mapAddFormFields';
import { filteringFunctions, validationFunctions, defaultErrorTexts } from 'utils/validateForm';
import { privatePersonID } from 'constants/ownershipsID';
import { IRootStore } from './rootStore';

class FormValidateStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Модель валидации и фильтрации
   *
   * Структура объектов для проверки:
   * <имя поля из объекта fields>: {
   *  filterType: <имя типа фильтрации из filteringFunctions>,
   *  validationTypes: {
   *    <имя типа валидации из validationFunctions>: <значение (динамические значения должны быть функциями)>,
   *  },
   * }
   */
  @observable validationModel = {
    // Поля второго шага регистрации
    name: {
      filterType: 'text',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredRegName,
        minLength: 2,
        maxLength: 150,
      },
    },
    fio: {
      filterType: 'fio',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredRegFIO,
        minLength: 2,
        maxLength: 40,
      },
    },
    mobile: {
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredRegMobile,
        mobile: {
          // @ts-ignore
          countryID: () => this.rootStore.addTruckStore.countryID,
        },
      },
    },
    // Поля объявления
    brand: {
      valueField: 'name',
      validationTypes: {
        required: true,
        maxLength: 50,
      },
    },
    model: {
      valueField: 'model',
      // filterType: 'model',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredModel,
        maxLength: 130,
      },
    },
    body_brand: {
      valueField: 'name',
      validationTypes: {
        required: false,
        maxLength: 50,
      },
    },
    year: {
      filterType: 'number',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredYear,
        length: 4,
        year: true,
      },
    },
    mileage: {
      filterType: 'number',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredMillage,
      },
    },
    owner_count: {
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredOwnerCount,
      },
    },
    vin: {
      filterType: 'vin',
      validationTypes: {
        length: 17,
      },
    },
    gov_num: {
      filterType: 'gov_num',
      validationTypes: {
        gov_num: {
          // @ts-ignore
          isRequired: this.isRequiredGovNum,
          country: (type: string) => this.getCurrentGovCountry(type),
        },
      },
    },
    engine_power: {
      filterType: 'number',
    },
    engine_volume: {
      filterType: 'number',
    },

    photos: {
      filterType: 'photos',
      validationTypes: {
        required: () => !this.isPurchase,
        maxLength: 10,
      },
    },
    city_verbose: {
      validationTypes: {
        required: true,
      },
    },
    price: {
      filterType: 'number',
      validationTypes: {
        required: true,
      },
    },
    // min_period: {
    //   filterType: 'number',
    //   validationTypes: {
    //     required: () => this.isRent,
    //   },
    // },
    comment: {
      filterType: 'text',
    },
    accident_description: {
      filterType: 'text',
    },
    address: {
      filterType: 'text',
    },
    volume: {
      filterType: 'float',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredVolume,
        maxNum: 9999,
      },
    },
    capacity: {
      filterType: 'float',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredCapacity,
        maxNum: 999,
      },
    },
    length: {
      filterType: 'float',
      validationTypes: {
        maxNum: 99,
      },
    },
    height: {
      filterType: 'float',
      validationTypes: {
        maxNum: 99,
      },
    },
    width: {
      filterType: 'float',
      validationTypes: {
        maxNum: 99,
      },
    },
    max_speed: {
      filterType: 'number',
    },
    fuel_consumption_100: {
      filterType: 'number',
    },
    tank_volume: {
      filterType: 'number',
    },
    tanks_count: {
      filterType: 'number',
    },
    // Тягач
    tractor_type: {
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredTractorType,
      },
    },
    saddle_height: {
      filterType: 'number',
    },
    // Автобус
    bus_type: {
      validationTypes: {
        required: () => this.isBus,
      },
    },
    seats_count: {
      filterType: 'number',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredSeatsCount,
      },
    },
    // Спецтехника
    advert_item_type: {
      validationTypes: {
        advert_item_type: () => this.rootStore.addTruckStore.fields.advert_item_type,
      },
    },
    working_hours: {
      filterType: 'number',
      validationTypes: {
        // @ts-ignore
        required: this.isRequiredWorkingHours,
      },
    },
    working_weight: {
      filterType: 'number',
    },
    max_jib_upper_height: {
      filterType: 'number',
    },
    min_jib_departure: {
      filterType: 'number',
    },
    max_jib_departure: {
      filterType: 'number',
    },
    // Экскаватор
    excavator_type: {
      validationTypes: {
        required: () => this.rootStore.addTruckStore.fields.advert_item_type === 'excavator',
      },
    },
    excavator_bucket_volume: {
      filterType: 'number',
    },
    max_digging_height: {
      filterType: 'number',
    },
    max_digging_depth: {
      filterType: 'number',
    },
    dumping_height: {
      filterType: 'number',
    },
    // Бульдозер
    blade_width: {
      filterType: 'number',
    },
    tracks_width: {
      filterType: 'number',
    },
    fully_weight: {
      filterType: 'number',
    },
    ground_pressure: {
      filterType: 'number',
    },
    max_cutting_depth: {
      filterType: 'number',
    },
    max_loosening_depth: {
      filterType: 'number',
    },
    max_blade_position_height: {
      filterType: 'number',
    },
    // Погрузчик
    loader_type: {
      validationTypes: {
        required: () => this.rootStore.addTruckStore.fields.advert_item_type === 'loader',
      },
    },
    speed_without_load: {
      filterType: 'number',
    },
    speed_with_load: {
      filterType: 'number',
    },
    load_upping_speed: {
      filterType: 'number',
    },
    // Другой тип спецтехники
    special_type: {
      filterType: 'text',
      validationTypes: {
        required: () => this.rootStore.addTruckStore.fields.advert_item_type === 'other_special',
      },
    },
  };

  /**
   * Объект для хранения ошибок
   */
  @observable errorFields = {};

  /**
   * Поле, находящееся в фокусе
   * (ошибки показываются только для полей вне фокуса)
   */
  @observable fieldInFocus = '';

  /**
   * Статус валидности формы
   */
  @observable isValidForm = true;

  /**
   * Необходимость скрола к ошибке
   */
  @observable needScrollToError = false;

  /**
   * Текущий тип объявления
   */
  @computed get isSale() {
    return this.rootStore.addTruckStore.fields.advert_type === 'sale';
  }

  @computed get isRent() {
    return this.rootStore.addTruckStore.fields.advert_type === 'rent';
  }

  @computed get isPurchase() {
    return this.rootStore.addTruckStore.fields.advert_type === 'purchase';
  }

  /**
   * Текущий тип транспорта
   */
  @computed get isTruck() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'truck';
  }

  @computed get isTractor() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'tractor';
  }

  @computed get isTrailer() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'trailer';
  }

  @computed get isSemitrailer() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'semitrailer';
  }

  @computed get isBus() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'bus';
  }

  @computed get isSpecialMachinery() {
    return this.rootStore.addTruckStore.transportCategory === 'special_machinery';
  }

  @computed get isRoadTrain() {
    return this.rootStore.addTruckStore.fields.advert_item_type === 'road_train';
  }

  @computed get isRoadTrainTruck() {
    return !!(
      this.isRoadTrain &&
      !!this.rootStore.addTruckStore.fields.advert_items.find((item: any) => item.type === 'truck')
    );
  }

  @computed get isRoadTrainTractor() {
    return !!(
      this.isRoadTrain &&
      !!this.rootStore.addTruckStore.fields.advert_items.find(
        (item: any) => item.type === 'tractor',
      )
    );
  }

  @computed get isRegPrivatePerson() {
    return this.rootStore.addTruckStore.fields?.step_two_data?.ownership_id === privatePersonID;
  }

  /**
   * Получить страну гос номера
   */
  getCurrentGovCountry = (typeTransport: string) => {
    if (!this.isRoadTrain) return this.rootStore.addTruckStore.fields.advert_item?.gov_num_country;
    const transportObj = this.rootStore.addTruckStore.fields.advert_items.find(
      (item: any) => item.type === typeTransport,
    );
    return transportObj.transport?.gov_num_country;
  };

  /**
   * Обязательность заполнения определенных полей
   */
  isRequiredModel = () => {
    return !this.isPurchase;
  };

  isRequiredYear = (type: string) => {
    if (!this.isPurchase) return true;
    if (this.isRoadTrain) {
      return !this.rootStore.addTruckStore.fields.advert_items.find(
        (item: any) => item.type === type,
      )?.transport?.is_new;
    }
    return !this.rootStore.addTruckStore.fields.advert_item?.is_new;
  };

  isRequiredRegName = () => {
    return this.rootStore.profileStore.isFastReg && !this.isRegPrivatePerson;
  };

  isRequiredRegFIO = () => {
    return this.rootStore.profileStore.isFastReg;
  };

  isRequiredRegMobile = () => {
    return this.rootStore.profileStore.isFastReg;
  };

  isRequiredGovNum = (type: string) => {
    if (this.isRent || this.isPurchase) return false;
    if (this.isRoadTrain && (type === 'tractor' || type === 'truck')) {
      return !this.rootStore.addTruckStore.fields.advert_items.find(
        (item: any) => item.type === 'tractor' || item.type === 'truck',
      )?.transport?.is_new;
    }
    if (this.isRoadTrain && type === 'semitrailer') {
      return !this.rootStore.addTruckStore.fields.advert_items.find(
        (item: any) => item.type === 'semitrailer',
      )?.transport?.is_new;
    }
    if (this.isRoadTrain && type === 'trailer') {
      return !this.rootStore.addTruckStore.fields.advert_items.find(
        (item: any) => item.type === 'trailer',
      )?.transport?.is_new;
    }
    return (
      !this.rootStore.addTruckStore.fields.advert_item?.is_new &&
      this.rootStore.addTruckStore.transportCategory !== 'special_machinery'
    );
  };

  isRequiredWorkingHours = () =>
    !this.isRent &&
    !this.isPurchase &&
    !this.rootStore.addTruckStore.fields.advert_item?.is_new &&
    this.rootStore.addTruckStore.transportCategory === 'special_machinery';

  isRequiredMillage = (type: string) => this.isRequiredGovNum(type);
  isRequiredOwnerCount = (type: string) => {
    return (
      this.isSale &&
      (this.isTruck || this.isTractor || this.isBus || type === 'truck' || type === 'tractor')
    );
  };

  isRequiredVolume = (type: string) =>
    !this.isPurchase &&
    (type === 'truck' ||
      type === 'trailer' ||
      type === 'semitrailer' ||
      this.isTruck ||
      this.isTrailer ||
      this.isSemitrailer);

  isRequiredCapacity = (type: string) =>
    !this.isPurchase &&
    (this.isTruck ||
      this.isTractor ||
      this.isTrailer ||
      this.isSemitrailer ||
      this.rootStore.addTruckStore.fields.advert_item_type === 'truck_crane' ||
      type === 'truck' ||
      type === 'tractor' ||
      type === 'trailer' ||
      type === 'semitrailer');

  isRequiredTractorType = (type: string) => this.isTractor || type === 'tractor';
  isRequiredSeatsCount = (type: string) => this.isBus && !this.isPurchase;

  /**
   * Смена статуса needScrollToError
   */
  @action setNeedScrollToError = (value: boolean) => {
    this.needScrollToError = value;
  };

  /**
   * Установка поля в фокусе
   */
  @action setFieldInFocus = (field: string, type?: string) => {
    const currentField = type ? `${field}.${type}` : field;
    this.fieldInFocus = currentField;
  };

  /**
   * Сброс поля в фокусе
   */
  @action clearFocus = () => {
    this.fieldInFocus = '';
  };

  /**
   * Сброс объекта с ошибками
   */
  @action clearErrorFields = () => {
    this.errorFields = {};
    this.isValidForm = true;
  };

  /**
   * Добавление ошибки
   */
  @action addError = (field: string, reason: string) => {
    // @ts-ignore
    this.errorFields[field] = {
      [field]: {
        isValid: false,
        type: field,
        reason,
      },
    };
  };

  /**
   * Удаление ошибки
   */
  @action removeError = (field: string, type?: string) => {
    const currentField = type ? `${field}.${type}` : field;
    // @ts-ignore
    delete this.errorFields[currentField];
  };

  /**
   * Фильтрация вводимых значений
   * @param  {any} inputValue
   * @param  {string} field
   */
  filter = (inputValue: any, field: string) => {
    if (!inputValue) return inputValue;
    // @ts-ignore
    const filterType = this.validationModel[field]?.filterType;
    // @ts-ignore
    const valueField = this.validationModel[field]?.valueField;
    let value = inputValue;
    if (valueField) {
      value = inputValue?.[valueField];
    }
    if (!filterType) return inputValue;
    // @ts-ignore
    const filteredValue = filteringFunctions[filterType](value);
    return valueField
      ? {
          ...inputValue,
          [valueField]: filteredValue,
        }
      : filteredValue;
  };

  /**
   * Валидация вводимых значений
   * @param  {any} inputValue
   * @param  {string} field
   */
  validate = (inputValue: any, field: string, type: string) => {
    // @ts-ignore
    const validationTypes = this.validationModel[field]?.validationTypes;
    // @ts-ignore
    const valueField = this.validationModel[field]?.valueField;
    let value = inputValue;
    if (valueField) {
      value = inputValue?.[valueField];
    }
    if (validationTypes) {
      // Перебираем типы валидации
      Object.entries(validationTypes).forEach(([keyType, valueType]) => {
        let computedValueType = valueType;
        // Если значение - функция => вычисляем
        if (typeof valueType === 'function') {
          computedValueType = valueType(type);
        } else if (isObject(valueType)) {
          // Если значение - объект => проверяем на наличие функций внутри и вычисляем если они есть
          const newObj = {};
          Object.entries(valueType).forEach(([objKey, objValue]) => {
            if (typeof objValue === 'function') {
              // @ts-ignore
              newObj[objKey] = objValue(type);
            } else {
              // @ts-ignore
              newObj[objKey] = objValue;
            }
          });
          computedValueType = newObj;
        }
        // Получаем статус валидации конкретного типа валидации
        // @ts-ignore
        const isValid = validationFunctions[keyType](value, computedValueType);
        const currentField = type ? `${field}.${type}` : field;
        if (!isValid) {
          // TODO: refactor
          // @ts-ignore
          const errorField = this.errorFields[currentField] ? this.errorFields[currentField] : {};
          // @ts-ignore
          this.errorFields[currentField] = {
            ...errorField,
            [keyType]: {
              isValid,
              type: keyType,
              // @ts-ignore
              reason: defaultErrorTexts?.[keyType]
                ? // @ts-ignore
                  defaultErrorTexts[keyType](computedValueType)
                : 'Ошибка при заполнении поля',
            },
          };
          // @ts-ignore
        } else if (this.errorFields[currentField]?.[keyType]) {
          // @ts-ignore
          delete this.errorFields[currentField][keyType];
          // @ts-ignore
          if (isEmpty(this.errorFields[currentField])) {
            // @ts-ignore
            delete this.errorFields[currentField];
          }
        }
      });
    }
  };

  /**
   * Функция получения ошибки для конкретного поля из объекта fields (не находящегося в фокусе)
   * с возможностью подмены теста ошибки на свой
   * @param  {string} field
   * @param  {string, object} newErrors - необязательный агрумент, строка или объект с текстами ошибок
   *                                      string - строка заменяет любую ошибку на эту строку
   *                                      object - ключи объекта должны совпадать с ключами из validationFunctions
   */
  getFieldError = (field: string, newErrors: any, type?: string) => {
    if (newErrors === '') return '';
    const currentField = this.isRoadTrain && type ? `${field}.${type}` : field;
    // @ts-ignore
    const errors = this.errorFields[currentField];
    const isError = errors && !isEmpty(errors);
    if (!isError || this.fieldInFocus === currentField) return '';
    if (newErrors && typeof newErrors === 'string') return newErrors;
    return Object.entries(errors)
      .map(([key, err]) => {
        // @ts-ignore
        if (isObject(newErrors) && newErrors?.[key] !== undefined) {
          // @ts-ignore
          return newErrors[key] === null ? '' : newErrors[key];
        }
        // @ts-ignore
        return err.reason;
      })
      .join('. ');
  };

  /**
   * Полная валидация формы
   */
  fullFormValidation = () => {
    this.clearErrorFields();
    const { transportCategory, transportSubcategory } = this.rootStore.addTruckStore;
    const category = transportSubcategory || transportCategory;
    const model = toJS(this.rootStore.addTruckStore._getNewAdvertObj(category));
    const checkedObject = cloneDeep(toJS(this.rootStore.addTruckStore.fields));

    mapAddFormFields(
      checkedObject,
      (obj: any, key: string, type: string) => {
        const value = obj[key];
        this.validate(normalizeValue(value), key, type);
      },
      model,
    );

    // TODO исправить
    // eslint-disable-next-line unicorn/no-reduce
    // @ts-ignore
    this.isValidForm = Object.entries(this.errorFields).reduce((acc, [, error]) => {
      if (!error) return acc;
      return isEmpty(error) ? acc : false;
    }, true);
  };

  /**
   * Повторная валидация формы, если она невалидна
   * (нужно вызывать при изменении значений формы, от которых зависит валидация других значений)
   */
  revalidationForm = () => {
    if (!this.isValidForm) {
      this.fullFormValidation();
    }
  };

  /**
   * Финальная валидация, срабатывающая при клике по кнопке "Отправить"
   */
  finalValidation = () => {
    this.fullFormValidation();
    this.setNeedScrollToError(!this.isValidForm);
  };
}

export default FormValidateStore;
