import React, { FC } from 'react';
import Skeleton from 'ati-ui-react/components/Skeleton';
import PageContainer from 'generic/PageContainer';
import { CabinetPageSkeletonStyles } from './CabinetPageSkeletonStyles';

const CabinetPageSkeleton: FC = () => {
  return (
    <PageContainer page="cabinet">
      <CabinetPageSkeletonStyles>
        <div className="container">
          <Skeleton className="skeleton-title" />
          <Skeleton className="skeleton-btn mob" />
        </div>

        <div className="container">
          <Skeleton className="skeleton-tab" />
          <Skeleton className="skeleton-tab" />
          <Skeleton className="skeleton-tab" />
          <Skeleton className="skeleton-tab" />
          <Skeleton className="skeleton-tab" />
          <Skeleton className="skeleton-btn desk" />
        </div>
        <Skeleton className="skeleton-panel" />
        <Skeleton className="skeleton-card" />
        <Skeleton className="skeleton-card" />
        <Skeleton className="skeleton-card" />
      </CabinetPageSkeletonStyles>
    </PageContainer>
  );
};

export default CabinetPageSkeleton;
