import { observable, action, computed } from 'mobx';

class MobileTestStore {
  @observable testResult = 'pending';
  @action runTest = () => {
    this.testResult = 'success';
  };
}

export default MobileTestStore;
