import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import { SidebarContainer, SidebarUserdata } from './SidebarStyles';
import NewsBlock from '../NewsBlock';

const Sidebar: FC = observer(() => {
  const {
    profileStore: { isNoAdv },
  } = useStores();

  return (
    <SidebarContainer>
      <SidebarUserdata>
        {!isNoAdv && (
          <div min-screen-width="1500">
            <span ati-userdata-info="doc-preset=loads_right_scroll;doc-extra-content=lastNews" />
          </div>
        )}
        <NewsBlock />
      </SidebarUserdata>
    </SidebarContainer>
  );
});

export default Sidebar;
