import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import { useHistory } from 'react-router-dom';
import Truck from 'ati-ui-react/components/icons/Truck';
import Link from 'ati-ui-react/components/Link';
import nbspier from 'ati-utils/nbspier';

import { ToCabinetButtonContainer } from './ToCabinetButtonStyles';

const ToCabinetButton: FC = observer(() => {
  const {
    profileStore: { isUser },
    popupStore: { openLoginPopup },
    findTrucksStore: { tabLocation },
    pagesStore,
    tabsStore,
    appStore: { width },
  } = useStores();

  const history = useHistory();

  const handleClickToCabinet = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (!isUser) {
      openLoginPopup();
      return;
    }

    if (pagesStore.currentPage !== 'search') {
      tabsStore.setCurrentTab('sale');
      history.push(`/cabinet?tab=sale`);
      return;
    }

    history.push(`/cabinet?tab=${tabLocation}`);
  };

  return (
    <ToCabinetButtonContainer>
      <Link classNameContainer="text-to-cabinet" small onClick={handleClickToCabinet}>
        <span className="link-content">
          <Truck width={14} height={14} className="truck-icon" />
          <span className="text">{nbspier('Мои объявления')}</span>
        </span>
      </Link>
    </ToCabinetButtonContainer>
  );
});

export default ToCabinetButton;
