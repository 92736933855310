import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import LoginPopup from 'generic/LoginPopup';
import ScrollToTop from 'generic/ScrollToTop';
import Notification from 'generic/Notification';
import ScreenObserver from 'generic/ScreenObserver';
import MainPageSkeleton from 'components/MainPage/components/MainPageSkeleton';
import CabinetPageSkeleton from 'components/CabinetPage/components/CabinetPageSkeleton';
import TruckCardPageSkeleton from 'components/TruckCardPage/components/TruckCardPageSkeleton';
import FormPageSkeleton from 'components/FormPage/components/FormPageSkeleton';
import AfterPublicationPageSkeleton from 'components/AfterPublicationPage/components/AfterPublicationPageSkeleton';
import lazyWithRetry from 'utils/lazyWithRetry';

const MainPage = lazyWithRetry(() => import(/* webpackChunkName: "MainPage" */ '../MainPage'));
const TruckCardPage = lazyWithRetry(
  () => import(/* webpackChunkName: "TruckCardPage" */ '../TruckCardPage'),
);
const CabinetPage = lazyWithRetry(
  () => import(/* webpackChunkName: "CabinetPage" */ '../CabinetPage'),
);
const FormPage = lazyWithRetry(() => import(/* webpackChunkName: "FormPage" */ '../FormPage'));
const AfterPublicationPage = lazyWithRetry(
  () => import(/* webpackChunkName: "AfterPublicationPage" */ '../AfterPublicationPage'),
);

const App = observer(() => {
  const {
    saveFilterStore: { statusRemoveFilter, statusSubscribeFilter, statusSavedFilter, getFilters },
  } = useStores();

  useEffect(() => {
    getFilters();
  }, [statusRemoveFilter.isSuccess, statusSubscribeFilter.isSuccess, statusSavedFilter.isSuccess]);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <ScreenObserver />
      <Notification />
      <LoginPopup />
      <Switch>
        <Route
          path="/trucks"
          render={(routeProps) => (
            <Suspense fallback={<MainPageSkeleton />}>
              <MainPage {...routeProps} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/cabinet"
          render={(routeProps) => (
            <Suspense fallback={<CabinetPageSkeleton />}>
              <CabinetPage {...routeProps} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/cabinet/form"
          render={(routeProps) => (
            <Suspense fallback={<FormPageSkeleton />}>
              <FormPage {...routeProps} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/after-publication/:id"
          render={(routeProps) => (
            <Suspense fallback={<AfterPublicationPageSkeleton />}>
              <AfterPublicationPage {...routeProps} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/:advType/:type/:brand/:model?/:timestamp?"
          render={(routeProps) => (
            <Suspense fallback={<TruckCardPageSkeleton />}>
              <TruckCardPage {...routeProps} />
            </Suspense>
          )}
        />
        <Redirect from="/" to="/trucks" />
      </Switch>
    </ThemeProvider>
  );
});

export default App;
