import axios from 'axios';

async function getMyContactsAPI() {
  const config = {
    method: 'GET',
    url: '/api/v1/helpers/contacts',
  };

  const res = await axios(config);
  return res.data;
}

export default getMyContactsAPI;
