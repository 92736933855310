import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemTractorModel from './items/ItemTractorModel';
import ItemTrailerModel from './items/ItemTrailerModel';

class RoadTrainTractorFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется с составными типами ТС
    this.advert_items = observable([
      {
        type: 'tractor',
        transport: toJS(new ItemTractorModel(options)),
      },
      {
        type: 'semitrailer',
        transport: toJS(new ItemTrailerModel(options)),
      },
    ]);
    if (options.isAdditionalTrailer) {
      this.advert_items.push({
        type: 'trailer',
        transport: toJS(new ItemTrailerModel(options)),
      });
    }
  }

  @observable advert_item_type = 'road_train'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default RoadTrainTractorFormModel;
