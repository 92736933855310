import { action, observable } from 'mobx';
import { IHeaderSubmenuStore } from 'interfaces/IHeaderSubmenuStore';

class HeaderSubmenuStore implements IHeaderSubmenuStore {
  @observable isVisible: boolean = false;
  @observable refSubmenu: string = '';

  @action toggleVisible = (): void => {
    this.isVisible = !this.isVisible;
  };

  @action setVisible = (value: boolean): void => {
    this.isVisible = value;
  };
}

export default HeaderSubmenuStore;
