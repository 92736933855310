import toNumber from 'lodash/toNumber';
import getSearchParameter from 'utils/getSearchParameter';

const getPageFromQS = (nameOfParameter = 'page'): number => {
  const initPageString = getSearchParameter(nameOfParameter);
  const initPageNum = toNumber(initPageString);
  if (initPageNum && typeof initPageNum === 'number') {
    return initPageNum;
  }
  return 1;
};

export default getPageFromQS;
