const decline = (num, [form1, form2, form3]) => {
  let string = form2;
  const remainder = num % 10;
  if (remainder > 4 || remainder === 0 || (num % 100 > 10 && num % 100 < 20)) {
    string = form3;
  } else if (remainder === 1) {
    string = form1;
  }
  return string;
};

export default decline;
