import countryIDs from 'constants/countryIDs';

const validateMobile = (value: string, countryID: string) => {
  if (!value || value === '+') {
    return { isValid: false, reason: 'Введите номер телефона' };
  }

  const re = /^[+\-()0-9 ]{9,25}$/gi;
  const reRuLongPhone = /^\+78.{10,10}$/gi;
  const absoluteValue = value.replace(/[^+\d]/g, '');
  const defaultError = 'Проверьте номер\u00A0― похоже, в\u00A0нём\u00A0опечатка';
  const lessThanError = 'Проверьте номер\u00A0― в\u00A0нём не\u00A0хватает цифр';
  const greaterThanError = 'Проверьте номер\u00A0― в\u00A0нём лишние цифры';

  let minLength = 9;
  const maxLength = 18;

  switch (countryID) {
    case countryIDs.ru:
    case countryIDs.kz:
      minLength = 12;
      break;
    case countryIDs.ua:
    case countryIDs.by:
      minLength = 13;
      break;
    default:
      break;
  }

  if (absoluteValue.length < minLength) {
    return { isValid: false, reason: lessThanError };
  }

  if (absoluteValue.length > maxLength) {
    return { isValid: false, reason: greaterThanError };
  }

  if (re.test(value) && !reRuLongPhone.test(absoluteValue)) {
    return { isValid: true, reason: '' };
  }

  return { isValid: false, reason: defaultError };
};

export default validateMobile;
