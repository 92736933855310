import styled from 'styled-components';

export const AfterPublicationPageSkeletonStyles = styled.div`
  margin-top: 50px;

  .skeleton-row {
    margin-bottom: 25px;
    border-radius: 4px;
  }
`;
