import { remove } from 'mobx';

/**
 * Удаление нескольких наблюдаемых свойств из объекта
 * @param  {object} collection - объект, из которого нужно удалить наблюдаемые свойства
 * @param  {array} keys - массив ключей, которые нужно удалить
 */
export default (collection, keys) => {
  keys.forEach((key) => remove(collection, key));
};
