import getEnvironDomain from 'utils/getEnvironDomain';
import axiosInstance from './axios';

async function getFirmAvatarAPI(idFirm, size) {
  const config = {
    method: 'GET',
    url: `https://api.${getEnvironDomain()}/v2/avatars/${idFirm}/${size}?withNotFound=true`,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getFirmAvatarAPI;
