import axiosInstance from './axios';

async function postSaveFilterAPI(passData) {
  const config = {
    method: 'POST',
    url: '/api/v1/filters',
    data: passData,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default postSaveFilterAPI;
