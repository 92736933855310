import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemTruckModel from './items/ItemTruckModel';

class TruckFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется вместе с простыми типами ТС
    this.advert_item = observable(toJS(new ItemTruckModel(options)));
  }

  @observable advert_item_type = 'truck'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default TruckFormModel;
