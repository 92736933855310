import { observable, action, runInAction } from 'mobx';
import getLoginContactInfoAPI from 'services/getLoginContactInfoAPI';
import { ILoginContactInfoStore } from 'interfaces/ILoginContactInfoStore';

class LoginContactInfoStore implements ILoginContactInfoStore {
  @observable loginContactInfo = {
    name: '',
    mobile: '',
    phone: '',
    e_mail: '',
  };

  @observable isLogin = false;

  @action getLoginContactInfo = async () => {
    try {
      const res = await getLoginContactInfoAPI();
      runInAction(() => {
        this.loginContactInfo = res;
        this.isLogin = true;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        `Не удалось получить контактную информацию залогиненного пользователя - ${error}`,
      );

      // @ts-ignore
      if (error?.response?.status === 401) {
        runInAction(() => {
          this.isLogin = false;
        });
      }
    }
  };
}

export default LoginContactInfoStore;
