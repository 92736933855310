import axiosInstance from './axios';

async function statisticsAPI(data) {
  const config = {
    method: 'GET',
    url: '/api/v1/stats/views',
    params: data,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default statisticsAPI;
