import axiosInstance from './axios';

export async function getDraftAPI() {
  const config = {
    method: 'GET',
    url: '/api/v1/draft',
  };

  const res = await axiosInstance(config);
  return res.data;
}

export async function addDraftAPI(newDraft) {
  const config = {
    method: 'POST',
    url: '/api/v1/draft',
    data: newDraft,
  };
  const res = await axiosInstance(config);
  return res.data;
}

export async function deleteDraftAPI() {
  const config = {
    method: 'DELETE',
    url: '/api/v1/draft',
  };

  const res = await axiosInstance(config);
  return res.data;
}
