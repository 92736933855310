import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';

import Breadcrumbs from 'generic/Breadcrumbs';
import Sidebar from 'generic/Sidebar';
import HeaderSubmenu from 'generic/HeaderSubmenu';
import Submenu from 'generic/Submenu';
import HeaderSubmenuMobile from 'components/HeaderSubmenuMobile';

import theme from 'src/theme';
import { Content, PageContainerStyles, ContentWrapper } from './PageContainerStyles';

export type Page = 'search' | 'cabinet' | 'advert' | 'form' | 'afterPublication';

const pages = {
  search: {
    bgColor: theme.colors.color_white,
    showSidebar: true,
  },
  advert: {
    bgColor: theme.colors.color_white,
    showSidebar: false,
  },
  cabinet: {
    bgColor: theme.colors.color_bg,
    showSidebar: false,
  },
  form: {
    bgColor: theme.colors.color_bg,
    showSidebar: false,
  },
  afterPublication: {
    bgColor: theme.colors.color_bg,
    showSidebar: false,
  },
};

interface IPageContainer {
  page: Page;
}

const PageContainer: FC<IPageContainer> = observer(({ page, children }) => {
  const {
    appStore: { width, isMobileApp },
  } = useStores();
  const pageConfig = pages[page];

  return (
    <PageContainerStyles bgColor={pageConfig.bgColor} isMobileApp={isMobileApp}>
      <ContentWrapper>
        <Breadcrumbs />
        <Content>{children}</Content>
      </ContentWrapper>
      {pageConfig.showSidebar && <Sidebar />}
    </PageContainerStyles>
  );
});

export default PageContainer;
