import get from 'lodash/get';
import map from 'lodash/map';
import { computed, observable } from 'mobx';
import { IUser } from 'interfaces/IUser';

const noAdvServiceId = 563;

class ProfileStore {
  @observable user: IUser = (window?.atiUser || {}) as IUser;

  @computed get isUser(): boolean {
    return !!get(this.user, 'is_user');
  }

  @computed get atiCode(): string {
    return get(this.user, 'profile.ati_code');
  }

  @computed get contactID(): number {
    return get(this.user, 'profile.contact.id');
  }

  @computed get isNoAdv(): boolean {
    const services = get(this.user, 'profile.payed_services.services') || [];
    const servicesIDs = map(services, 'service_id');
    return servicesIDs.includes(noAdvServiceId);
  }

  @computed get isFastReg(): boolean {
    return !!get(this.user, 'profile.passed_only_fast_registration');
  }

  @computed get balance(): number {
    return get(this.user, 'profile.balance') || 0;
  }

  @computed get isPositiveBalance(): boolean {
    return this.balance >= 0;
  }
}

export default ProfileStore;
