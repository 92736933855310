import { observable, action, runInAction, autorun } from 'mobx';
import debounce from 'lodash/debounce';
import truckBrandsAPI from 'services/truckBrandsAPI';
import isEmpty from 'lodash/isEmpty';
import { ITruckBrandsStore } from 'interfaces/ITrucksBrandsStore';
import { IRootStore } from './rootStore';

class TruckBrandsStore implements ITruckBrandsStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    autorun(() => {
      if (isEmpty(this.rootStore.findTrucksStore.fields.brand)) {
        this.resetSuggestions();
      }
    });
  }

  @observable suggestions = [];
  @observable value = '';
  @observable isSelected = false;

  @action getBrand = async (brand: string): Promise<void> => {
    const res = await truckBrandsAPI(brand);
    runInAction(() => {
      this.suggestions = res.result || [];
    });
  };

  @action getBrandValue = debounce(({ value }: { value: string }): void => {
    this.getBrand(value);
  }, 150);

  @action onChange = (value: string): void => {
    this.value = value;
  };

  @action setSelected = (value: boolean) => {
    this.isSelected = value;
  };

  @action resetSuggestions = (): void => {
    this.suggestions = [];
    this.value = '';
  };

  @action resetBrandProperty = () => {
    this.rootStore.findTrucksStore.fields.brand = '';
  };
}

export default TruckBrandsStore;
