import styled, { css } from 'styled-components';

export const SubscribeLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #478cc8;
  cursor: pointer;

  .filter-subscribe {
    border-bottom: 1px dashed;

    &:hover {
      border-bottom: 1px solid;
    }
  }

  .filter-subscribe-icon {
    margin-right: 5px;
  }
`;
