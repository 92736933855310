import { observable, action } from 'mobx';
import { IModalStore } from 'interfaces/IModalStore';

class ModalStore implements IModalStore {
  @observable isModalOpen = false;

  @action openModal = () => {
    this.isModalOpen = true;
  };

  @action closeModal = () => {
    this.isModalOpen = false;
  };

  @action reload = () => {
    // @ts-ignore
    document.location.reload(true);
  };
}

export default ModalStore;
