import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import CheckBold from 'ati-ui-react/components/icons/CheckBold';

import { SubscribeLinkContainer } from './SubscribeLinkStyles';

interface ISubscribeLink {
  onHandleSubscribe: (e: any) => void;
  onHandleUnsubscribe: (e: any) => void;
  subscription: boolean;
  id: string;
  email?: string;
}

const SubscribeLink: FC<ISubscribeLink> = observer(
  ({ onHandleSubscribe, onHandleUnsubscribe, subscription, id, email }) => {
    const [isHovered, setHovered] = useState<boolean>(false);
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);

    const {
      saveFilterStore: {
        customEmail,
        statusSubscribeFilter,
        isChangedEmail,
        isChanged,
        statusRemoveFilter,
      },
    } = useStores();

    const currentEmail = id === customEmail.id;

    useEffect(() => {
      if (statusSubscribeFilter.isSuccess || statusRemoveFilter.isSuccess) {
        isChangedEmail(false);
      }
    }, [statusSubscribeFilter.state, statusRemoveFilter.state]);

    const subscribe = <span className="filter-subscribe">Подписаться на рассылку</span>;

    const renderDescription = () => {
      if (currentEmail && isChanged) {
        return subscribe;
      }

      if (!subscription) {
        return (
          <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="filter-subscribe"
          >
            {!isHovered ? (
              <>
                <CheckBold
                  width="14"
                  height="10"
                  color="#478cc8"
                  className="filter-subscribe-icon"
                />
                Вы подписаны на рассылку
              </>
            ) : (
              'Отписаться от рассылки'
            )}
          </span>
        );
      }

      return subscribe;
    };

    return (
      <SubscribeLinkContainer
        onClick={subscription || isChanged ? onHandleSubscribe : onHandleUnsubscribe}
      >
        {renderDescription()}
      </SubscribeLinkContainer>
    );
  },
);

export default SubscribeLink;
