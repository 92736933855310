import { action, observable, computed, runInAction, toJS, reaction } from 'mobx';
import getTransportsAPI from 'services/getTransportsAPI';

import removeFalsyValues from 'utils/removeFalsyValues';
import updateUrlParam from 'utils/updateUrlParam';
import paramsToObject from 'utils/queryParamsSearch';
import getSearchAdvertType from 'utils/getSearchAdvertType';
import getCountFilledFields from 'utils/getCountFilledFields';
import noWipeAwayQs from 'utils/noWipeAwayQs';

import getPageFromQS from 'utils/getPageFromQS';
import pull from 'lodash/pull';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import compact from 'lodash/compact';
import getAllValuesObject from 'utils/getAllValuesObject';
import SearchFormSaleModel from 'models/SearhFormSaleModel';
import searchFieldsSaleModel from 'models/SearchFieldsSaleModel';
import queryStringFormModel from 'models/QueryStringFormModel';
import PaginationModel from 'models/PaginationModel';
import AsyncStateModel from 'models/AsyncStateModel';
import searchLocation from 'constants/searchLocation';

import { IFindTrucksStore } from 'interfaces/IFindTrucksStore';
import { IPagination } from 'interfaces/IPagination';
import { IAsyncStateModel } from 'interfaces/IAsyncStateModel';
import { ISearchFieldsSaleModel, ICity } from 'interfaces/ISearchFieldsSaleModel';

import RootStore from './rootStore';

type updateFromTo = {
  event: string;
  value: number;
  field: string;
};

// Массив ключей объекта fields, которые скрываются в форме
const fieldsThatCollapse: string[] = [
  'capacity',
  'mileage',
  'working_hours',
  'year',
  'price',
  'only_new',
  'is_available',
  'with_vin',
  'with_driver',
  // 'with_lizing',
];
const initialPage = getPageFromQS();

const objProposalType: { [key: string]: string } = {
  '/trucks/proposals-purchase': 'purchase',
  '/trucks/proposals-rent': 'rent',
};

class FindTrucksStore implements IFindTrucksStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.fieldsValues,
      () => {
        // TODO fix detect save filters without get request on every change form value
        this.rootStore.saveFilterStore.getFilters(); // Monkey patch
        this.rootStore.saveFilterStore.setFilterSave(false);
        this.rootStore.saveFilterStore.checkIsFilterExists();
      },
    );
  }

  @observable isValue = false;
  // @ts-ignore
  @observable tabLocation: string = searchLocation[window.location.pathname];
  @observable searchState: IAsyncStateModel = new AsyncStateModel();
  @observable fields: ISearchFieldsSaleModel = new SearchFormSaleModel();
  @observable pagination: IPagination = new PaginationModel(initialPage);

  @action updateFromTo = ({ event, value, field }: updateFromTo) => {
    const eventType: string = event.replace('Value', '');
    this.fields[field][eventType] = value;
  };

  @action updateSelect = ({ field, value }: { field: string; value: string }) => {
    this.fields[field] = value;
  };

  @action updateGeo = (geo: ICity) => {
    this.fields.city = geo;
  };

  @action updateCheckBox = ({ field, value }: { field: string; value: any }) => {
    this.fields[field] = value;
  };

  @action updateBrandModel = ({ field, name }: { field: string; name: string }) => {
    this.fields[field] = name;
  };

  @computed get isRentSearch() {
    return this.tabLocation === 'rent';
  }

  @computed get countSearchFilters() {
    const fields = [
      this.fields.model,
      this.fields.brand,
      this.fields.capacity?.from || this.fields.capacity?.to,
      this.fields.mileage?.from || this.fields.mileage?.to,
      this.fields.working_hours?.from || this.fields.working_hours?.to,
      this.fields.year?.from || this.fields.year?.to,
      this.fields.price?.from || this.fields.price?.to,
      this.rootStore.geoSearchStore?.value,
      this.fields.advert_item_type,
      this.fields.is_available,
      this.fields.only_new,
      this.fields.with_vin,
      // this.fields.with_lizing,
      this.fields.with_driver,
    ];

    return pull(compact(fields), 'all_types_trucks').length;
  }

  @computed get fieldsValues() {
    const prepareFields = omit(this.fields, ['ordering']);

    const fields = pull(
      getAllValuesObject(prepareFields),
      'sale',
      'rent',
      'purchase',
      'day',
      'all_types_trucks',
    );
    return fields;
  }

  @action isFormQsExists = () => {
    const params = new URLSearchParams(window.location.search);
    const qsKeys = [...params.keys()];
    const allowKeys = new Set([
      'capacity_from',
      'capacity_to',
      'mileage_from',
      'mileage_to',
      'year_from',
      'year_to',
      'price_from',
      'price_to',
      'geo',
      'ordering',
      'advert_item_type',
      'is_available',
      'only_new',
      'with_vin',
      'with_driver',
      // 'with_lizing',
      'brand',
      'model',
    ]);

    const found = qsKeys.some((key) => allowKeys.has(key));
    this.rootStore.saveFilterStore.countFields = qsKeys.length;

    if (found) {
      this.isValue = true;
    }
  };

  /**
   * Счетчик заполненных скрываемых полей в форме
   */
  @computed get countHideFilledFields() {
    const fields: { [key: string]: string } = toJS(this.fields);
    const countedObject: { [key: string]: string } = {};
    removeFalsyValues(fields);

    fieldsThatCollapse.forEach((key) => {
      countedObject[key] = fields?.[key];
    });
    const isYear = this.rootStore.appStore.width > 512 && countedObject?.year;

    return isYear ? getCountFilledFields(countedObject) - 1 : getCountFilledFields(countedObject);
  }

  @action getFormFieldsExistKeys = (): void => {
    const filteredValues = keys(this.requestSearch());
    pull(filteredValues, 'limit', 'advert_type');
    this.rootStore.saveFilterStore.countFields = filteredValues.length;
  };

  @action getFilterFields = () => {
    const fields = omit(this.requestSearch(), ['limit', 'offset', 'ordering']);
    return fields;
  };

  @action setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    const queryParamsSearch = paramsToObject(entries);

    // @ts-ignore
    this.fields = queryStringFormModel(queryParamsSearch);

    // TODO стоит подумать перенести в сам стор
    this.rootStore.truckBrandsStore.value = this.fields.brand || '';
    this.rootStore.searchModelsStore.value = this.fields.model || '';

    // @ts-ignore
    if (queryParamsSearch.geo) {
      // @ts-ignore
      this.rootStore.geoSearchStore.value = queryParamsSearch.geo.split('_')[1] || '';
    }
  };

  @action setAdvertType = (type: string) => {
    this.fields.advert_type = type;
  };

  @action requestSearch = () => {
    const searchFields = searchFieldsSaleModel({
      ...this.fields,
      limit: this.pagination.limit,
      offset: this.pagination.offset,
      advert_type: this.rootStore.advertsStore.currentAdvert.advert_type,
      ...(getSearchAdvertType() === 'purchase' && {
        proposal_type: objProposalType[window.location.pathname],
      }),
    });

    return removeFalsyValues(searchFields);
  };

  @action initialSearchState = () => {
    if (this.fieldsValues) {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${noWipeAwayQs({ ordering: this.fields.ordering })}`,
      );
    }
    const initFormProps = new SearchFormSaleModel();
    const addCurrentProps = { ordering: this.fields.ordering };
    this.fields = merge(initFormProps, addCurrentProps);
    this.pagination.resetPage();
    this.rootStore.geoSearchStore.resetSuggestions();
  };

  @action handleInitialSearchState = async () => {
    await this.initialSearchState();
    await this.getTrucksResults();
  };

  @action setTabLocation = (location: string) => {
    this.tabLocation = location;
  };

  @action getTrucksResults = async () => {
    this.searchState.request();
    try {
      const res = await getTransportsAPI(this.requestSearch());
      const atiIds = Object.values(res.result.accounts).map((el: any) => el.account_id);
      runInAction(() => {
        this.rootStore.advertsStore.adverts = res.result.adverts;
        this.rootStore.advertsStore.accounts = res.result.accounts;
        this.rootStore.advertsStore.getStars(atiIds);
        this.pagination.setCountAdverts(res.result.count);
        this.rootStore.advertsStore.adsRequested = true;
        this.searchState.success();
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      runInAction(() => {
        this.searchState.failure();
      });
    }
  };

  @action resetSearchParameter = (searchParameter: string) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(`${searchParameter}_from`);
    params.delete(`${searchParameter}_to`);
    updateUrlParam(params);
    if (this.fields[searchParameter]) {
      Object.keys(this.fields[searchParameter]).forEach((el) => {
        this.fields[searchParameter][el] = null;
      });
    }
  };
}

export default FindTrucksStore;
