import { observable, action, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import truckModelsAPI from 'services/truckModelsAPI';
import { ITruckModelsStore } from 'interfaces/ITruckModelsStore';
import { IRootStore } from './rootStore';

class TruckModelsStore implements ITruckModelsStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable suggestions = [];
  @observable value = '';

  @action getModel = async (model: string, brandId: string): Promise<void> => {
    const res = await truckModelsAPI(model, brandId);
    runInAction(() => {
      this.suggestions = res.result || [];
    });
  };

  @action getModelValue = debounce(({ value }: { value: string }, brandId: string): void => {
    this.getModel(value, brandId);
  }, 150);

  @action onChange = (value: string): void => {
    this.value = value;
  };

  @action resetSuggestions = (): void => {
    this.suggestions = [];
    this.value = '';
  };

  @action resetSuggestion = () => {
    this.suggestions = [];
  };

  @action resetModelProperty = () => {
    this.rootStore.findTrucksStore.fields.model = '';
  };
}

export default TruckModelsStore;
