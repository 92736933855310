const normalizeValue = (value) => {
  if (!value) return null;
  const type = typeof value;
  if (type === 'object' || type === 'boolean') return value;
  const trimmedValue = value ? value.toString().trim() : value;
  const filteredNulls = Number(trimmedValue) === 0 ? null : trimmedValue;
  return filteredNulls;
};

export default normalizeValue;
