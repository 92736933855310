import styled from 'styled-components';

const AppBlock = styled.main`
  font-family: ${(props) => props.theme.fontVerdana};
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;
  -webkit-font-smoothing: antialiased;
`;

const ErrorContent = styled.div`
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 0 0;

  @media (max-width: 567px) {
    padding: 30px 0 0 0;
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: 20px;
`;

export { AppBlock, ErrorContent, HeadingWrapper };
