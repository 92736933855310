import { observable, action, runInAction, autorun } from 'mobx';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import searchModelsAPI from 'services/searchModelsAPI';
import { ISearchModelsStore } from 'interfaces/ISearchModelsStore';
import { IRootStore } from './rootStore';

class SearchModelsStore implements ISearchModelsStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    autorun(() => {
      if (
        isEmpty(this.rootStore.findTrucksStore.fieldsValues) ||
        (this.rootStore.truckBrandsStore.isSelected &&
          this.rootStore.truckBrandsStore.value.toUpperCase() !==
            this.suggestionBrand.toUpperCase())
      ) {
        this.resetSuggestions();
        this.resetModelProperty();
      }
    });
  }

  @observable suggestions = [];
  @observable value = '';
  @observable suggestionBrand = '';

  @action getModel = debounce(async ({ prefix, brand_id }): Promise<void> => {
    const res = await searchModelsAPI({ prefix, brand_id });
    runInAction(() => {
      this.suggestions = res.result || [];
    });
  }, 150);

  @action onChange = (value: string): void => {
    this.value = value;
  };

  @action setValue = (value: string): void => {
    this.value = value;
  };

  @action getSuggestionBrand = (brand: string) => {
    this.suggestionBrand = brand;
  };

  @action resetSuggestions = (): void => {
    this.suggestions = [];
    this.value = '';
  };

  @action resetSuggestion = () => {
    this.suggestions = [];
  };

  @action resetModelProperty = () => {
    this.rootStore.findTrucksStore.fields.model = '';
  };
}

export default SearchModelsStore;
