import styled from 'styled-components';

export const BreadcrumbsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 512px) {
    padding: 0 8px 0 0;
    margin-top: 8px;
  }
`;

export const BreadcrumbsStyles = styled.div`
  padding: 20px 0px;
  width: 100%;

  @media screen and (max-width: 512px) {
    padding: 10px 10px;
    flex-direction: column;
  }
  display: flex;

  .how-add-ad {
    margin-left: auto;
    padding-left: 30px;
    display: flex;
    align-items: center;

    .icons {
      display: flex;

      .how-icon {
        margin-left: 6px;
      }
    }

    @media screen and (max-width: 512px) {
      margin-left: 0;
      padding-top: 7px;
      padding-left: 0px;
    }
  }
`;

export const BreadcrumbsMenuStyles = styled.div`
  display: flex;
  align-items: center;

  .icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    & .icon-section {
      margin-left: 12px;
      cursor: pointer;
    }
  }
`;
