import { observable, action, runInAction } from 'mobx';
import getDictionariesAPI from 'services/getDictionariesAPI';
import { IDictionariesStore, IOwnerCountTypes, IPeriodTypes } from 'interfaces/IDictionnariesStore';
import { IRootStore } from './rootStore';

class DictionariesStore implements IDictionariesStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable dictionaries = {};
  @observable engine_types = {};
  @observable gearbox_types = {};
  @observable body_types = {};
  @observable cabin_types = {};
  @observable suspension_types = {};
  @observable wheel_formulas = {};
  @observable drive_types = {};
  @observable currency_types = {};
  @observable traction_classes = {};
  @observable bus_types = {};
  @observable excavator_types = {};
  @observable loader_types = {};
  @observable tractor_types = {};
  @observable period_types = {} as IPeriodTypes;
  @observable owner_count_types = {} as IOwnerCountTypes;
  @observable advert_promotion_prices = {};
  @observable advert_promotion_prices_by_period = {};

  @action getDictionaries = async () => {
    const dicts = await getDictionariesAPI();
    runInAction(() => {
      this.dictionaries = dicts.result;
      this.engine_types = dicts.result.engine_types;
      this.gearbox_types = dicts.result.gearbox_types;
      this.body_types = dicts.result.body_types;
      this.cabin_types = dicts.result.cabin_types;
      this.suspension_types = dicts.result.suspension_types;
      this.wheel_formulas = dicts.result.wheel_formulas;
      this.drive_types = dicts.result.drive_types;
      this.currency_types = dicts.result.currency_types;
      this.traction_classes = dicts.result.traction_classes;
      this.bus_types = dicts.result.bus_types;
      this.excavator_types = dicts.result.excavator_types;
      this.loader_types = dicts.result.loader_types;
      this.tractor_types = dicts.result.tractor_types;
      this.period_types = dicts.result.period_types;
      this.owner_count_types = dicts.result.owner_count_types;
      this.advert_promotion_prices = dicts.result.advert_promotion_prices;
      this.advert_promotion_prices_by_period = dicts.result.advert_promotion_prices_by_period;
    });
  };
}

export default DictionariesStore;
