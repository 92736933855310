import axiosInstance from './axios';

async function deleteAdvertAPI(advertId) {
  const config = {
    method: 'DELETE',
    url: `/api/v1/transports/${advertId}`,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default deleteAdvertAPI;
