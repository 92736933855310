import axiosInstance from './axios';

async function getUniqueNumbersAccess(advertId) {
  const config = {
    method: 'POST',
    url: '/api/v1/billing/transports/unique_numbers_access',
    data: advertId,
  };

  const response = await axiosInstance(config);
  return response.data;
}

export default getUniqueNumbersAccess;
