import axiosInstance from './axios';

async function buyFullReportAPI(id) {
  const config = {
    method: 'POST',
    url: '/api/v1/billing/avtocod/full_report_access',
    data: {
      advert_id: id,
    },
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default buyFullReportAPI;
