import { observable, extendObservable } from 'mobx';
import additionalItemFields from './additionalItemFields';

class ItemTruckModel {
  constructor(options) {
    if (options.advertType) {
      extendObservable(this, additionalItemFields[options.advertType]?.truck || {});
    }
  }

  // Информация о ТС
  @observable is_new = false; // Состояние транспорта
  @observable brand = {}; // Объект марки
  @observable model = {}; // Объект модели
  @observable mileage = ''; //  Пробег в км
  @observable gearbox_type = null; // Тип коробки передач.Значение из словаря
  // Характеристики
  @observable body_type = null; // Тип кузова. Значение из словаря
  @observable volume = ''; //  Объем кузова в м3
  @observable capacity = ''; // Грузоподъемность в тоннах
  @observable cabin_type = null; // Тип кабины. Значение из словаря
  // Дополнительно
  @observable suspension_type = null; // Тип подвески. Значение из словаря
  @observable wheel_formula = null; // Колесная формула. Значение из словаря
  @observable drive_type = null; // Привод. Значение из словаря
  @observable right_side_helm = null; // Правый руль
  @observable computer = null; // Бортовой компьютер
  @observable central_oil_management = null; // Центральная смазка
  @observable conditioner = null; // Кондиционер в кабине
  @observable heater_in_cabin = null; // Отопитель салона
  @observable engine_preheater = null; // Предпусковой обогрев двигателя
}

export default ItemTruckModel;
