import getNameTransportType from 'utils/getNameTransportType';

const setRussianFilterName = (name: string): string => {
  if (!name) return 'Все типы транспорта';

  return getNameTransportType(name, {
    special_type: 'Спецтехника (другое)',
  });
};

export default setRussianFilterName;
