import { observable, action, computed } from 'mobx';
import getUrlParameter from 'ati-utils/getUrlParameter';
import getCookie from 'ati-utils/getCookie';
import { IAppStore } from 'interfaces/IAppStore';
import cn from 'classnames';

const isCookieApp = getCookie('atiapp') === '1';

class AppStore implements IAppStore {
  // Check for iOS or Android app
  @observable isMobileApp = false;
  @observable width = window.innerWidth;
  @observable height = window.innerHeight;

  @action checkIsMobileApp = () => {
    this.isMobileApp = getUrlParameter('atiapp') === '1' || isCookieApp;
  };

  @action updateWidthAndHeight = (width: number, height: number) => {
    this.width = width;
    this.height = height;
  };

  @computed get isDesktop() {
    return this.width >= 1024;
  }

  @computed get isLaptop() {
    return this.width >= 768 && this.width < 1024;
  }

  @computed get isTablet() {
    return this.width >= 480 && this.width < 768;
  }

  @computed get isMobile() {
    return this.width < 480;
  }

  @computed get screenClassNames() {
    return cn({
      desktop: this.isDesktop,
      laptop: this.isLaptop,
      tablet: this.isTablet,
      mobile: this.isMobile,
    });
  }
}

export default AppStore;
