export default () => {
  let advert_type = 'sale';
  switch (window.location.pathname.replace(/\/+$/, '')) {
    case '/trucks/rent':
      advert_type = 'rent';
      break;
    case '/trucks/proposals-purchase':
    case '/trucks/proposals-rent':
      advert_type = 'purchase';
      break;
    default:
      break;
  }
  return advert_type;
};
