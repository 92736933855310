import { observable, action, computed, runInAction } from 'mobx';
import complaintAPI from 'services/complaintAPI';
import { IComplaintStore } from 'interfaces/IComplaintStore';
import { IRootStore } from 'stores/rootStore';

class ComplaintStore implements IComplaintStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable state = 'pending';
  @observable complaintText = '';
  @observable complaintReason = 'not_relevant';

  @action updateText = (text: string) => {
    this.complaintText = text.replace(/(^[" "]+)|((^[\n]+))/g, '');
  };

  @action resetText = () => {
    this.complaintText = '';
  };

  @action updateReason = (value: string) => {
    this.complaintReason = value;
  };

  @action resetReason = () => {
    this.complaintReason = 'not_relevant';
  };

  @action resetState = () => {
    this.state = 'pending';
  };

  @computed get isLoading() {
    return this.state === 'loading';
  }

  @computed get isSent() {
    return this.state === 'done';
  }

  @computed get isNotSent() {
    return this.state === 'error';
  }

  @action sendComplaint = (transportUrl: string) => {
    runInAction(() => {
      this.state = 'loading';
    });
    complaintAPI({
      transport_url: transportUrl,
      reason: this.complaintReason,
      comment: this.complaintText,
    })
      // TODO исправить
      // eslint-disable-next-line promise/always-return
      .then(() => {
        runInAction(() => {
          this.state = 'done';
          this.rootStore.notificationStore.pushNotification(
            'Сообщение успешно отправлено',
            'success',
          );
          this.resetText();
          this.resetReason();
        });
      })
      .catch(() => {
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при отправке сообщения. Пожалуйста, повторите позже.',
          'error',
        );
        runInAction(() => {
          this.state = 'error';
        });
      });
  };
}

export default ComplaintStore;
