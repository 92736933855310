import { action, computed, observable, runInAction, values } from 'mobx';
import postSaveFilterAPI from 'src/services/postSaveFilter';
import getFiltersAPI from 'src/services/getFiltersAPI';
import deleteFilterAPI from 'src/services/deleteFilterAPI';
import isEqual from 'lodash/isEqual';
import AsyncStateModel from 'models/AsyncStateModel';
import { ISaveFilterStore, ISavedFilter, IFilter } from 'interfaces/ISaveFilterStore';
import { IRootStore } from './rootStore';

class SaveFilterStore implements ISaveFilterStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable savedFilter: IFilter = {} as IFilter;
  @observable subscribeFilter: IFilter = {} as IFilter;
  @observable filters: ISavedFilter[] = [];
  @observable countFields: number = 0;
  @observable countUrlKeys: number = 0;
  @observable isShowFilterLink: boolean = false;
  @observable isFilterSave: boolean = false;
  @observable isFilterRemove: boolean = false;
  @observable isIncorrectEmail: boolean = false;
  @observable isChanged: boolean = false;
  @observable isFilterValues: boolean = false;
  @observable filterId: string = '';
  @observable isVisiblePopover: boolean = false;
  @observable isSetFilter: boolean = false;

  @observable customEmail = {
    id: '',
    email: '',
  };

  @observable statusSavedFilter = new AsyncStateModel();
  @observable statusGetFilters = new AsyncStateModel();
  @observable statusSubscribeFilter = new AsyncStateModel();
  @observable statusRemoveFilter = new AsyncStateModel();

  @observable isFilterExists: boolean = false;
  @observable isMaxSaveFilters: boolean = false;
  @observable errorStatusSavedFilter: string = '';

  @observable isShowPopoverHintFilter = false;

  @computed get filtersIds(): string[] {
    return this.filters.map((filter) => filter._id);
  }

  @computed get capacityFrom() {
    return this.rootStore.findTrucksStore.fields.capacity.from;
  }

  @computed get getUrlString() {
    return new URLSearchParams(window.location.search);
  }

  @action resetCustomEmail = (): void => {
    this.customEmail.email = '';
  };

  @action setFilterSave = (value: boolean): void => {
    this.isFilterSave = value;
  };

  @action setFiltersError = (value: boolean): void => {
    this.isMaxSaveFilters = value;
  };

  @action setIfFilter = (value: boolean): void => {
    this.isSetFilter = value;
  };

  @action checkIsFilterExists = (): void => {
    this.isFilterExists = this.filters.some(({ search_params }) =>
      isEqual(search_params, this.rootStore.findTrucksStore.getFilterFields()),
    );
  };

  @action removeFilter = (id: string) => {
    const filteredData = this.filters.filter((item) => item._id !== id);
    this.filters = filteredData;
  };

  @action getCustomEmail = (id: string, email: string): void => {
    this.customEmail.id = id;
    this.customEmail.email = email;
  };

  @action resetIncorrectEmail = (value: boolean): void => {
    this.isIncorrectEmail = value;
  };

  @action isChangedEmail = (value: boolean): void => {
    this.isChanged = value;
  };

  @action setFilterValues = (value: boolean) => {
    this.isFilterValues = value;
  };

  @action getFilterId = (id: string) => {
    this.filterId = id;
  };

  @action setVisiblePopover = (value: boolean) => {
    this.isVisiblePopover = value;
  };

  @action setFilterActions = (id: string) => {
    this.getFilterId(id);
    this.setFilterSave(false);
    this.isFilterExists = true;
  };

  @action setFilterToForm = (filter: any, id: string, city?: string) => {
    this.setFilterActions(id);
    this.rootStore.findTrucksStore.fields = filter;

    this.rootStore.searchModelsStore.value = filter.model || '';
    this.rootStore.truckBrandsStore.value = filter.brand || '';
    this.rootStore.geoSearchStore.value = city || '';
  };

  /* ********************************************************************* */
  // API REQUESTS START
  /* ********************************************************************* */

  @action getFilters = async (): Promise<void> => {
    this.statusGetFilters.request();
    try {
      const res = await getFiltersAPI({ limit: 30 });
      runInAction(() => {
        this.statusGetFilters.success();
        this.filters = res.result.filters;
      });
    } catch (error) {
      this.statusGetFilters.failure();
      // eslint-disable-next-line no-console
      console.log(`Не удалось получить сохраненные фильтры ${error}`);
    }
  };

  @action postSaveFilter = async (filter: IFilter): Promise<void> => {
    this.statusSavedFilter.request();
    try {
      const res = await postSaveFilterAPI(filter);
      runInAction(() => {
        this.statusSavedFilter.success();
        this.savedFilter = res.result;
      });
    } catch (error) {
      runInAction(() => {
        this.statusSavedFilter.failure();
        // @ts-ignore
        this.errorStatusSavedFilter = error.response.data.error;
        this.isIncorrectEmail = true;
      });
      // eslint-disable-next-line no-console
      console.log(`Фильтр не удалось сохранить - ${error}`);
    }
  };

  @action postSubscribeFilter = async (filter: IFilter): Promise<void> => {
    this.statusSubscribeFilter.request();
    try {
      const res = await postSaveFilterAPI(filter);
      runInAction(() => {
        this.statusSubscribeFilter.success();
        this.subscribeFilter = res.result;
      });
    } catch (error) {
      runInAction(() => {
        this.statusSubscribeFilter.failure();
        this.isIncorrectEmail = true;
      });
      // eslint-disable-next-line no-console
      console.log(`Не удалось подписаться на фильтр - ${error}`);
    }
  };

  @action postRemoveFilters = async (filterIds: any): Promise<void> => {
    this.statusRemoveFilter.request();
    try {
      const res = await deleteFilterAPI(filterIds);
      runInAction(() => {
        this.statusRemoveFilter.success();
        this.isFilterRemove = res.ok;
      });
    } catch (error) {
      runInAction(() => {
        this.statusRemoveFilter.failure();
      });
      // eslint-disable-next-line no-console
      console.log(`${error}`);
    }
  };

  /* ********************************************************************* */
  // API REQUESTS END
  /* ********************************************************************* */

  @action setPopoverHintFilterVisible = (value: boolean): void => {
    this.isShowPopoverHintFilter = value;
  };
}

export default SaveFilterStore;
