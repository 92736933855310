import styled from 'styled-components';

export const RemoveFilterContainer = styled.div`
  display: flex;
  align-items: center;
  color: #d0021b;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .delete-text {
    margin-left: 6px;
  }
`;
