import axiosInstance from './axios';

async function getLoginContactInfoAPI() {
  const config = {
    method: 'GET',
    url: '/webapi/firms/v1.0/firms/contact',
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default getLoginContactInfoAPI;
