import styled from 'styled-components';

export const FormPageSkeletonStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  .skeleton-wrapper {
    width: 100%;
    max-width: 850px;
  }

  .skeleton-row {
    border-radius: 4px;
  }

  .skeleton-row.first {
    margin-bottom: 15px;
  }

  .skeleton-row.second {
    margin-bottom: 15px;
  }

  .skeleton-row.third {
    margin-bottom: 20px;
  }
`;
