import styled from 'styled-components';

export const CabinetPageSkeletonStyles = styled.div`
  padding-top: 60px;

  .skeleton-title {
    margin-bottom: 22px;
    height: 30px;
    width: 50%;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .skeleton-tab {
    height: 54px;
    width: 10%;
    margin-right: 7px;
    border-radius: 4px 4px 0 0;
    margin-top: 18px;
  }

  .skeleton-btn {
    height: 40px;
    width: 20%;
    margin-left: auto;
    border-radius: 15px;
  }

  .skeleton-panel {
    height: 30px;
    margin-bottom: 10px;
    border-radius: 0 4px 4px 4px;
  }

  .skeleton-card {
    border-radius: 4px;
    height: 137px;
    margin-bottom: 7px;
  }

  @media screen and (max-width: 1024px) {
    .skeleton-tab {
      height: 40px;
    }

    .skeleton-btn.desk {
      display: none;
    }

    .skeleton-tab {
      margin-right: 0;
      width: 18%;
    }
  }

  @media screen and (min-width: 1025px) {
    .skeleton-btn.mob {
      display: none;
    }
  }

  @media screen and (max-width: 512px) {
    .skeleton-tab {
      height: 30px;
    }
  }
`;
