import axios, { AxiosError, AxiosResponse } from 'axios';
import errors from 'stores/ErrorsStore';
import { captureException, captureMessage } from '../sentryLogger';

const validStatuses = new Set([401]);
const expectedResponses = {
  '/api/v1/draft': [404],
  '/public/api/v1/transports/getone': [404],
};
const isExpectedResponse = (response: { config?: any; status?: any }) => {
  const { url } = response.config;
  const { status } = response;
  if (validStatuses.has(status)) return true;
  // @ts-ignore
  if (expectedResponses[url] && expectedResponses[url].includes(status)) return true;
  return false;
};

const DEBUG = process.env.NODE_ENV === 'development';
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 401 && DEBUG) {
      // eslint-disable-next-line no-console
      console.warn('You are not authorized');
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response && !isExpectedResponse(error.response)) {
      if (error.response.status >= 500 && error.response.status <= 511) {
        captureException('ERROR: Unexpected API response', {
          config: error.config,
          request: error.request,
          response: error.response,
        });
        errors.handleErrors('serverInTrouble');
      } else {
        captureMessage('LOG: Unexpected API response', {
          config: error.config,
          request: error.request,
          response: error.response,
        });
        errors.handleErrors(String(error));
      }
      return Promise.reject(error);
    }

    errors.handleErrors(String(error));
    return Promise.reject(error);
  },
);

export default axiosInstance;
