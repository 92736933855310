import isObject from 'lodash/isObject';
import compact from 'lodash/compact';

const getAllValuesObject = (obj: { [key: string]: string }): string[] => {
  if (!isObject(obj)) return [];

  const res: Array<string> = [];

  Object.keys(obj).map((item) => {
    if (isObject(obj[item])) {
      // @ts-ignore
      return res.push(...getAllValuesObject(obj[item]));
    }
    return res.push(obj[item]);
  });

  return compact(res);
};

export default getAllValuesObject;
