import axiosInstance from './axios';

async function complaintAPI(data) {
  const config = {
    method: 'POST',
    url: '/api/v1/complaint',
    data,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default complaintAPI;
