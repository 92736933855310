import setAppVersion from 'ati-utils/setAppVersion';

const appName = 'truckmarket';
const date = process.env.CI_RELEASE_DATE || new Date().toJSON().slice(0, 10);
const branch = process.env.CI_COMMIT_REF_NAME || 'development';
const commit = process.env.CI_COMMIT_SHORT_SHA || 'development';

const release = `${appName}@branch:${branch}@commit:${commit}@${date}`;

setAppVersion(appName, { date, branch, commit });

export default release;
