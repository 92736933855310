import styled from 'styled-components';

export const PopoverFiltersContainer = styled.div`
  @media (max-width: 800px) {
    margin-left: auto;
  }

  .filter-list {
    max-width: 328px;
    max-height: 430px;
    overflow-y: auto;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0;
    margin-top: 32px !important;

    & > div + div {
      display: none;
    }

    @media (max-width: 1024px) {
      right: -80px;
    }

    @media (max-width: 470px) {
      width: 290px;
    }
  }
`;
