import { action, observable } from 'mobx';
import {
  INotificationStore,
  INotification,
  INotificationType,
} from 'interfaces/INotificationStore';

class NotificationStore implements INotificationStore {
  @observable notification: INotification | null = null;

  @action pushNotification = (
    text: string,
    type: INotificationType,
    delay: number = 2000,
    onCloseCallback?: () => void,
  ) => {
    this.closeNotification();
    this.notification = {
      text,
      type,
      delay,
      callback: onCloseCallback,
    };
  };

  @action closeNotification = () => {
    if (typeof this.notification?.callback === 'function') {
      this.notification.callback();
    }
    this.notification = null;
  };
}

export default NotificationStore;
