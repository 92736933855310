import { observable, action, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import geoSearchAPI from 'services/geoSearchAPI';
import { IGeo } from 'interfaces/IGeoSearchStore';

class GeoSearchStore {
  @observable suggestions = [];
  @observable value = '';

  @action getGeo = async (geo: IGeo) => {
    const res = await geoSearchAPI(geo);
    runInAction(() => {
      this.suggestions = res;
    });
  };

  @action getGeoValue = debounce(({ value }: { value: string }, type: number): void => {
    this.getGeo({ prefix: value, geo_types: type });
  }, 300);

  @action onChange = (value: string): void => {
    this.value = value;
  };

  @action resetSuggestions = (): void => {
    this.suggestions = [];
    this.value = '';
  };
}

export default GeoSearchStore;
