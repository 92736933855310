const getNameTransportType = (advertItemType, additionalyTypesObj) => {
  switch (advertItemType) {
    case 'truck':
      return 'Грузовик';
    case 'tractor':
      return 'Тягач';
    case 'trailer':
      return 'Прицеп';
    case 'semitrailer':
      return 'Полуприцеп';
    case 'road_train':
      return 'Автопоезд (сцепка)';
    case 'bus':
      return 'Автобус';
    case 'truck_crane':
      return 'Автокран';
    case 'excavator':
      return 'Экскаватор';
    case 'dozer':
      return 'Бульдозер';
    case 'loader':
      return 'Погрузчик';
    case 'other_special':
      return additionalyTypesObj?.special_type || '';
    default:
      return '';
  }
};

export default getNameTransportType;
