import axiosInstance from './axios';

export async function getFavoritesAPI(sendData) {
  const config = {
    method: 'GET',
    url: '/api/v1/favorites',
    params: sendData,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export async function addFavoritesAPI(id) {
  const config = {
    method: 'POST',
    url: '/api/v1/favorites',
    data: {
      _id: id,
    },
  };

  const res = await axiosInstance(config);
  return res.data;
}

export async function deleteFavoritesAPI(id) {
  const config = {
    method: 'DELETE',
    url: '/api/v1/favorites',
    data: {
      _id: id,
    },
  };

  const res = await axiosInstance(config);
  return res.data;
}
