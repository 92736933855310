import { observable, action, runInAction, computed, extendObservable, toJS } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import getTransportCategory from 'utils/getTransportCategory';
import getTransportSubcategory from 'utils/getTransportSubcategory';
import getNameTransportType from 'utils/getNameTransportType';

import getStarsAPI from 'services/stars';
import getUnAuthStarsAPI from 'src/services/unAuthStars';
import getCurrentAdvertAPI from 'services/getCurrentAdvertAPI';
import getFirmAvatarAPI from 'services/getFirmAvatarAPI';
import { IAdvertsStore, ICurrentAdvert } from 'interfaces/IAdvertsStore';
import { IAccountInfo } from 'interfaces/ISharedInterfaces';
import { IRootStore } from 'stores/rootStore';
import countryIDs from 'constants/countryIDs';

// import getone from '../mockData/getone.json'; // for test

import TruckFormModelStore from './TruckFormModelStore';

class AdvertsStore implements IAdvertsStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable adsRequested: boolean = false;
  @observable adverts: ICurrentAdvert[] = [];
  @observable accounts = {};
  @observable stars = {};
  @observable currentAdvert: ICurrentAdvert = {} as ICurrentAdvert;
  @observable currentAccount = {};
  @observable firmAvatar: null = null;
  @observable isEdit: boolean = false;
  @observable isMiniCardAnimation: boolean = true;
  @observable truckName: string = '';
  @observable advertSearchStatus: string = '';
  @observable isLockedEditVIN: boolean = false;

  @computed get advertLoaded(): boolean {
    return !isEmpty(this.currentAdvert);
  }

  @computed get isLoadingAdvert(): boolean {
    return this.advertSearchStatus === 'pending';
  }

  @action setTruckName = (name: string): void => {
    this.truckName = name;
  };

  @action getTruckName = (): string => {
    // @ts-ignore
    if (this.currentAdvert.advert_items) {
      const nameOfType = getNameTransportType(this.currentAdvert.advert_item_type, {});
      // @ts-ignore
      const advertItems = this.currentAdvert.advert_items.map((field) => {
        return [` ${field.transport.brand.name}`, field.transport?.model?.model]
          .filter(Boolean)
          .join(' ');
      });

      return `${nameOfType} ${advertItems}`;
    }

    const nameOfType = getNameTransportType(this.currentAdvert.advert_item_type, {
      // eslint-disable-next-line unicorn/explicit-length-check
      // @ts-ignore
      special_type: this.currentAdvert.advert_item?.special_type,
    });
    return [
      nameOfType,
      this.currentAdvert.advert_item?.brand.name,
      this.currentAdvert.advert_item?.model?.model,
    ]
      .filter((item) => item)
      .join(' ');
  };

  @action getStars = async (ids: string[]): Promise<void> => {
    try {
      const newStars = await getStarsAPI(ids);
      runInAction(() => {
        this.stars = {
          ...this.stars,
          ...newStars,
        };
      });
    } catch {
      const newStars = await getUnAuthStarsAPI(ids);
      runInAction(() => {
        this.stars = {
          ...this.stars,
          ...newStars,
        };
      });
    }
  };

  // FIXME: дописать когда метод получения аватарки будет работать через webapi
  @action getFirmAvatar = async <T>(idFirm: T, size: T) => {
    this.firmAvatar = await getFirmAvatarAPI(idFirm, size);
  };

  @action getCurrentAdvert = async (url: string): Promise<void> => {
    try {
      runInAction(() => {
        this.advertSearchStatus = 'pending';
      });
      const res = await getCurrentAdvertAPI({ url });
      runInAction(() => {
        this.advertSearchStatus = 'match';
      });
      this.setCurrentAdvert(res.result.advert);
      this.setCurrentAccount(res.result.account[res.result.advert?.owner_acc]);
      this.getStars(Object.values(res.result.account).map((el: any) => el.account_id));
    } catch (error) {
      // @ts-ignore
      if (error?.response?.data?.error === 'not_found') {
        runInAction(() => {
          this.advertSearchStatus = 'noMatch';
        });
      }
    }
  };

  @action updateCurrentAdvert = () => {
    this.getCurrentAdvert(this.currentAdvert.url);
  };

  @action setCurrentAccount = (acc: IAccountInfo): void => {
    this.currentAccount = acc;
  };

  /**
   *
   * @param ad - {object} - устанавливает текущее объявление в форму в режиме редактирования
   */
  @action setCurrentEditAdvert = (ad: ICurrentAdvert): void => {
    this.setEditOn();
    extendObservable(this.rootStore.addTruckStore.fields, { _id: ad._id });
    this.setAdToForm(ad);
    this.isLockedEditVIN = !!(
      ad?.advert_item?.vin_blocked || ad?.advert_items?.[0]?.transport?.vin_blocked
    );
  };

  @action resetLockedVIN = (): void => {
    this.isLockedEditVIN = false;
  };

  /**
   * Подставить данные из объекта объявления в форму
   * @param ad - {object} - объект объявления
   */
  @action setAdToForm = async (ad: any) => {
    // TODO исправить
    // eslint-disable-next-line unicorn/explicit-length-check
    if (!this.rootStore.addTruckStore.myContacts.length) {
      await this.rootStore.addTruckStore.getMyContacts();
    }
    runInAction(() => {
      const contacts = this.rootStore.addTruckStore.myContacts.filter(
        // @ts-ignore
        (i) => ad.contacts.includes(i.id) || !!ad.contacts.find((el) => el.id === i.id),
      );
      const category = getTransportCategory(ad.advert_item_type);
      const subcategory = getTransportSubcategory(ad);
      const isAdditionalTrailer =
        // @ts-ignore
        !!ad?.advert_items && ad.advert_items.find((item) => item.type === 'trailer');
      const mobile = ad?.step_two_data?.mobile;
      if (mobile && typeof mobile === 'string') {
        let countryID = countryIDs.ru;
        if (/^\+380/.test(mobile)) {
          countryID = countryIDs.ua;
        } else if (/^\+375/.test(mobile)) {
          countryID = countryIDs.by;
        } else if (!/^\+7/.test(mobile)) {
          countryID = countryIDs.other;
        }
        this.rootStore.addTruckStore.setCountryID(countryID);
      }
      // @ts-ignore
      this.rootStore.addTruckStore.setTransportCategory(category, subcategory);
      if (isAdditionalTrailer) {
        this.rootStore.addTruckStore.isAdditionalTrailer = true;
      }
      this.rootStore.addTruckStore.fields = ad;
      // TODO: monkey patch
      this.rootStore.addTruckStore.fields.advert_promotion = ad?.actual_promotion || '';
      this.rootStore.addTruckStore.fields.contacts = contacts;
      if (ad?.city_verbose) {
        this.rootStore.geoSearchStore.value = ad.city_verbose;
      }
      if (ad?.advert_item?.brand?.name) {
        this.rootStore.truckBrandsStore.value = ad.advert_item.brand.name;
      }
      if (ad?.advert_item?.model?.model) {
        this.rootStore.truckModelsStore.value = ad.advert_item.model.model;
      }
    });
  };

  @action setEditOn = (): void => {
    this.isEdit = true;
  };

  @action setEditOff = (): void => {
    this.isEdit = false;
    this.rootStore.addTruckStore.fields = new TruckFormModelStore();
  };

  @action setCurrentAdvert = (ad: ICurrentAdvert): void => {
    this.currentAdvert = ad;
  };

  @action resetCurrentAdvert = (): void => {
    this.currentAdvert = {} as ICurrentAdvert;
  };

  @computed get isSpecialMachine(): boolean {
    // @ts-ignore
    return getTransportCategory(this.currentAdvert.advert_item_type) === 'special_machinery';
  }

  @computed get transportCategory(): string {
    // @ts-ignore
    return getTransportCategory(this.currentAdvert.advert_item_type);
  }

  @action setMiniCardAnimation = (isMiniCardAnimation: boolean): void => {
    this.isMiniCardAnimation = isMiniCardAnimation;
  };
}

export default AdvertsStore;
