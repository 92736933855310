import { IAnyObject } from 'interfaces/GenericInterfaces';

const govNum = {
  gov_num: '', // Гос.номер
  gov_num_country: 'ru', // Страна регистрации гос.номера
};

const ownerCount = {
  owner_count: '', // Количество владельцев
};

const year = {
  year: '', // Год выпуска
};

const saddleHeight = {
  saddle_height: '', // Высота седельного устройства
};

const vin = {
  vin: '', //  VIN номер
};

const engine = {
  engine_type: '', // Тип двигателя. Значение из словаря
  engine_power: '', // Мощность двигателя в лс
  engine_volume: '', // Объем двигателя в литрах
};

const size = {
  length: '', // Длина м
  height: '', // Высота м
  width: '', // Ширина м
};

const maxSpeed = {
  max_speed: '', // Максимальная скорость км/ч
};

const fuelConsumption = {
  fuel_consumption_100: '', // Расход топлива на 100км
};

const tankCount = {
  tanks_count: '', // Количество баков
};

const tankVolume = {
  tank_volume: '', // Вместимость бака
};

const bodyBrand = {
  body_brand: {}, // Бренд рефрижератора
};

const additionalItemFields: IAnyObject = {
  sale: {
    truck: {
      ...year,
      ...govNum,
      ...ownerCount,
      ...vin,
      ...engine,
      ...size,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
      ...bodyBrand,
    },
    tractor: {
      ...year,
      ...govNum,
      ...ownerCount,
      ...saddleHeight,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    trailer: {
      ...year,
      ...govNum,
      ...vin,
      ...size,
      ...bodyBrand,
    },
    semitrailer: {
      ...year,
      ...govNum,
      ...vin,
      ...size,
      ...bodyBrand,
    },
    bus: {
      ...year,
      ...govNum,
      ...ownerCount,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankVolume,
    },
    special_machinery: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    truck_crane: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    excavator: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    dozer: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    loader: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    other_special: {
      ...year,
      ...govNum,
      ...vin,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
  },
  rent: {
    truck: {
      ...year,
      ...engine,
      ...size,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    tractor: {
      ...year,
      ...saddleHeight,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    trailer: {
      ...year,
      ...size,
    },
    semitrailer: {
      ...year,
      ...size,
    },
    bus: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankVolume,
    },
    special_machinery: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    truck_crane: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    excavator: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    dozer: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    loader: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
    other_special: {
      ...year,
      ...engine,
      ...maxSpeed,
      ...fuelConsumption,
      ...tankCount,
      ...tankVolume,
    },
  },
  purchase: {
    truck: { ...year, ...bodyBrand },
    tractor: { ...year, ...bodyBrand },
    trailer: { ...year, ...bodyBrand },
    semitrailer: { ...year, ...bodyBrand },
    bus: { ...year, ...bodyBrand },
    special_machinery: { ...year, ...bodyBrand },
    truck_crane: { ...year, ...bodyBrand },
    excavator: { ...year, ...bodyBrand },
    dozer: { ...year, ...bodyBrand },
    loader: { ...year, ...bodyBrand },
    other_special: { ...year, ...bodyBrand },
  },
};

export default additionalItemFields;
