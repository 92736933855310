import { observable, action, runInAction, computed } from 'mobx';
import getCurrentAdvertAPI from 'services/getCurrentAdvertAPI';
import getStarsAPI from 'services/stars';
import getUnAuthStarsAPI from 'services/unAuthStars';
import getLastPayment from 'services/getLastPayment';
import AsyncStateModel from 'models/AsyncStateModel';
import promotionAPI from 'services/promotionAPI';
import decline from 'utils/decline';
import { IAsyncStateModel } from 'interfaces/IAsyncStateModel';
import { ICurrentAdvert } from 'interfaces/IAdvertsStore';
import { IRootStore } from 'stores/rootStore';
import { IAccountInfo } from 'interfaces/ISharedInterfaces';
import {
  IAfterPublicationStore,
  IStars,
  IPromotionType,
  IPromotionDuration,
  IBuyingPromotionNotification,
  IPromotionData,
} from 'interfaces/IAfterPublicationStore';
import virtualAccountDepositAPI from 'src/services/virtualAccountDepositAPI';
import postRequisitesAPI from 'src/services/postRequisitesAPI';

const initialPromotion = 'turbo_sale';
const initialDuration = 7;

const fallbackNotification = {
  text: 'Произошла ошибка. Попробуйте повторить попытку позже.',
  type: 'error',
};

const promotionNotifications: IBuyingPromotionNotification = {
  200: {
    text: 'К объявлению применён пакет продвижения',
    type: 'success',
  },
  400: {
    text: 'К объявлению уже применено продвижение',
    type: 'warning',
  },
  402: {
    text: 'Недостаточно средств на балансе',
    type: 'error',
  },
  403: {
    text: 'Нет доступа к виртуальному счету',
    type: 'error',
  },
};

const promotionNames: Record<IPromotionType, string> = {
  turbo_sale: 'Турбопродажа',
  highlighting: 'Выделение цветом',
  priority: 'Приоритетный показ',
};

const requisiteTypeId = 3;
const ownershipId = 25;
const currency = 1;
const payType = 8;
const virtualPaymentsOperationId = 41;

class AfterPublicationStore implements IAfterPublicationStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable promotionTypes: IPromotionType[] = ['turbo_sale', 'highlighting', 'priority'];
  @observable promotionDurations: IPromotionDuration[] = [3, 7, 14, 30];
  @observable selectedPromotion: IPromotionType = initialPromotion;
  @observable selectedDuration: IPromotionDuration = initialDuration;
  @observable buyingPromotionStatus: IAsyncStateModel = new AsyncStateModel(); // Статус асинхронного запроса, покупка продвижения
  @observable buyingPromotionByCardStatus: IAsyncStateModel = new AsyncStateModel(); // Статус асинхронного запроса, покупка продвижения по карте
  @observable status: string = ''; // Статус асинхронного запроса
  @observable gettingLastPaymentStatus: IAsyncStateModel = new AsyncStateModel(); // Статус асинхронного запроса, получение последней транзакции
  @observable publishedAdvertID: string = ''; // ID Опубликованного объявления
  @observable publishedAdvert: ICurrentAdvert = {} as ICurrentAdvert; // Опубликованное объявление
  @observable publishedAccount: IAccountInfo = {} as IAccountInfo; // Аккаунт опубликованного объявления
  @observable stars: IStars = {} as IStars; // Звезды для аккаунта опубликованного объявления
  // TODO узнать объект и типизировать
  @observable lastPayment = {}; // Информация о последней транзакции

  @computed get isWrongID(): boolean {
    return this.status === 'noMatch';
  }

  @computed get isPurchase(): boolean {
    return this.publishedAdvert.advert_type === 'purchase';
  }

  @computed get amountMoneyNeeded(): number {
    const balance = this.rootStore.profileStore.balance || 0;
    return Number(this.promotionPrice) - balance;
  }

  @computed get promotionPrice(): number {
    const promoPrice = this.rootStore.dictionariesStore.advert_promotion_prices_by_period[
      this.selectedPromotion
    ]?.[this.selectedDuration];
    return Number(promoPrice);
  }

  @computed get isLowBalance(): boolean {
    return this.amountMoneyNeeded > 0;
  }

  @computed get isEnoughMoney(): boolean {
    return this.rootStore.profileStore.user.profile.balance >= (this.promotionPrice || 0);
  }

  @action setAdvertID = (id: string): void => {
    this.publishedAdvertID = id;
  };

  @action resetAdvertID = (): void => {
    this.publishedAdvertID = '';
  };

  @action setPublishedAdvert = <T>(advert: T): void => {
    // @ts-ignore
    this.publishedAdvert = advert;
  };

  @action resetPublishedAdvert = (): void => {
    this.publishedAdvert = {} as ICurrentAdvert;
  };

  @action selectPromotion = (promotion: IPromotionType): void => {
    this.selectedPromotion = promotion;
  };

  @action selectDuration = (duration: IPromotionDuration): void => {
    this.selectedDuration = duration;
  };

  @action resetAfterPublicationStore = (): void => {
    this.resetAdvertID();
    this.resetPublishedAdvert();
    this.selectedPromotion = initialPromotion;
    this.selectedDuration = initialDuration;
  };

  @action getAdvert = async (id: string): Promise<void> => {
    try {
      runInAction(() => {
        this.status = 'pending';
      });
      const res = await getCurrentAdvertAPI({ _id: id });

      runInAction(() => {
        this.setPublishedAdvert(res.result.advert);
        const accountData: any = Object.values(res.result.account)[0];
        // @ts-ignore
        this.publishedAccount = accountData;
        this.getStars([accountData.account_id]);
        this.status = 'match';
      });
    } catch {
      runInAction(() => {
        this.status = 'noMatch';
      });
    }
  };

  @action getLastPaymentStatus = async (id: string): Promise<void> => {
    this.gettingLastPaymentStatus.request();
    try {
      const res = await getLastPayment(id);
      runInAction(() => {
        this.lastPayment = res.result;
      });
      this.gettingLastPaymentStatus.success();
    } catch {
      this.gettingLastPaymentStatus.failure();
    }
  };

  @action getStars = async (ids: string[]): Promise<void> => {
    try {
      const newStars = await getStarsAPI(ids);
      runInAction(() => {
        this.stars = Object.values(newStars)[0] as IStars;
      });
    } catch {
      const newStars = await getUnAuthStarsAPI(ids);
      runInAction(() => {
        this.stars = {
          ...this.stars,
          ...newStars,
        };
      });
    }
  };

  @action buyPromotion = async (
    data: IPromotionData,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => {
    const { promotion, period, advertId } = data;
    let responseStatus;
    this.buyingPromotionStatus.request();
    try {
      const res = await promotionAPI({
        promotion,
        advert_id: advertId,
        period: String(period),
      });
      this.buyingPromotionStatus.success();
      if (successCallback) {
        successCallback();
      }
      if (res.ok) {
        responseStatus = 200;
      }
    } catch (error) {
      this.buyingPromotionStatus.failure();
      if (errorCallback) {
        errorCallback();
      }
      // @ts-ignore
      responseStatus = error?.response?.status;
    }

    this.pushNotification(responseStatus, promotion, period);
  };

  // eslint-disable-next-line consistent-return
  @action buyPromotionByCard = async (data: IPromotionData) => {
    const price = this.promotionPrice;
    // @ts-ignore
    const urlParams = new URLSearchParams(data);
    const { origin, pathname } = window.location;
    const mobileAppParam = this.rootStore.appStore.isMobileApp ? '&atiapp=1' : '';
    const uri = `${origin}${pathname}?${urlParams.toString()}${mobileAppParam}`;
    const failUri = `${origin}${pathname}?directPaymentError=true${mobileAppParam}`;
    this.buyingPromotionByCardStatus.request();
    try {
      const invoice = await virtualAccountDepositAPI({
        currency,
        payType,
        atis: price,
        sendDocsByPost: false,
        Sum: price,
        Debts: 0,
        UtmTags: null,
        DirectPayment: {
          Uri: uri,
          failUri,
          // 0 - потому что списываем деньги сами, своим методом продвижения
          VirtualAccountWriteOff: 0,
          VirtualPaymentsOperationId: virtualPaymentsOperationId,
        },
      });
      this.buyingPromotionByCardStatus.success();
      return invoice.redirectUrl;
    } catch {
      this.buyingPromotionByCardStatus.failure();
      this.rootStore.notificationStore.pushNotification(
        fallbackNotification.text,
        // @ts-ignore
        fallbackNotification.type,
        5000,
      );
    }
  };

  @action pushNotification = (
    responseStatus: number,
    selectedPromotion: IPromotionType,
    selectedDuration: IPromotionDuration,
  ) => {
    const notification = promotionNotifications[responseStatus] || fallbackNotification;
    const promoName = promotionNames[selectedPromotion];
    const text =
      responseStatus === 200
        ? `${notification.text} «${promoName}» на ${selectedDuration} ${decline(selectedDuration, [
            'день',
            'дня',
            'дней',
          ])}`
        : notification.text;
    this.rootStore.notificationStore.pushNotification(text, notification.type, 5000);
  };
}

export default AfterPublicationStore;
