import getEnvironDomain from 'utils/getEnvironDomain';
import axiosInstance from './axios';

async function fetchStatuses(userIds) {
  const queryString = userIds.map((userId) => `user=${userId}`).join('&');

  const config = {
    method: 'GET',
    url: `https://chat.${getEnvironDomain()}/presence/status?${queryString}`,
    withCredentials: true,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default fetchStatuses;
