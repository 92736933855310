import styled from 'styled-components';

export const SidebarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.alabaster};
  display: none;
  padding: 30px 30px 30px 30px;

  @media (min-width: 1544px) {
    display: block;
  }
`;

export const SidebarUserdata = styled.div`
  overflow: hidden;
  width: 300px;
  position: sticky;
  top: 62px;
`;
