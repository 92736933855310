import axiosInstance from './axios';

async function getMyAdvertsAPI(sendData) {
  const config = {
    method: 'GET',
    url: '/api/v1/transports',
    params: sendData,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default getMyAdvertsAPI;
