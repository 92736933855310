import { observable, extendObservable } from 'mobx';
import additionalItemFields from './additionalItemFields';

class ItemTrailerModel {
  constructor(options) {
    if (options.advertType) {
      extendObservable(this, additionalItemFields[options.advertType]?.trailer || {});
    }
  }

  // Информация о ТС
  @observable is_new = false; // Состояние транспорта
  @observable brand = {}; // Объект марки
  @observable model = {}; // Объект модели
  @observable mileage = ''; //  Пробег в км
  // Характеристики
  @observable body_type = null; // Тип кузова. Значение из словаря
  @observable volume = ''; //  Объем кузова в м3
  @observable capacity = ''; // Грузоподъемность в тоннах
  // Дополнительно
  @observable suspension_type = null; // Тип подвески. Значение из словаря
  @observable drive_type = null; // Привод. Значение из словаря
}

export default ItemTrailerModel;
