import { observable } from 'mobx';
import { ISearchFieldsSaleModel, ICity } from 'interfaces/ISearchFieldsSaleModel';

class SearchFormSaleModel implements ISearchFieldsSaleModel {
  @observable capacity = {
    from: 0,
    to: 0,
  };

  @observable mileage = {
    from: 0,
    to: 0,
  };

  @observable working_hours = {
    from: 0,
    to: 0,
  };

  @observable year = {
    from: 0,
    to: 0,
  };

  @observable price = {
    from: 0,
    to: 0,
  };

  @observable period_type = '';

  @observable city: ICity = {
    id: '',
    name: '',
    country_id: 0,
    region_id: 0,
    type: 0,
  };

  @observable ordering = '';
  @observable advert_item_type = '';

  @observable is_available = false;
  @observable only_new = false;
  @observable with_vin = false;
  @observable with_lizing = false;
  @observable with_driver = false;
  @observable brand = '';
  @observable model = '';
  @observable proposal_type = '';
}

export default SearchFormSaleModel;
