import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import isEmpty from 'lodash/isEmpty';
import SavedSearch from 'ati-ui-react/components/icons/SavedSearch';
import PopoverHint from 'generic/PopoverHint/PopoverHint';
import useWindowSize from 'hooks/useWindowSize';

import { SavedSearchLinkContainer } from './SavedSearchesLinkStyles';

interface ISavedSearchesLink {
  onHandleClick: () => void;
}

const SavedSearchesLink: FC<ISavedSearchesLink> = observer(({ onHandleClick }) => {
  const [width] = useWindowSize();
  const {
    saveFilterStore: { filters, isShowPopoverHintFilter, setPopoverHintFilterVisible },
  } = useStores();

  const popupDescription =
    'Выберите интересующий, чтобы увидеть подходящие объявления или подпишитесь на Email рассылку.';

  return (
    <>
      {!isEmpty(filters) && (
        <PopoverHint
          isShowPopover={isShowPopoverHintFilter}
          onCloseClick={() => setPopoverHintFilterVisible(false)}
          mainContent={popupDescription}
          header="Сохраненные фильтры находятся здесь"
        >
          <SavedSearchLinkContainer onClick={onHandleClick}>
            <div className="saved-link-search">
              <SavedSearch className="favorite-search-icon" width={18} />
              <span className="text">Сохраненные&nbsp;поиски</span>
            </div>
          </SavedSearchLinkContainer>
        </PopoverHint>
      )}
    </>
  );
});

export default SavedSearchesLink;
