import { observable, extendObservable } from 'mobx';
import additionalItemFields from './additionalItemFields';
import specificationsSpecialMachines from './specificationsSpecialMachines';

class ItemSpecialMachineModel {
  constructor(options) {
    const advertType = options?.advertType;
    const advertItemType = options?.advertItemType;

    if (advertType) {
      extendObservable(this, additionalItemFields[advertType]?.special_machinery || {});

      const specificationsFields = specificationsSpecialMachines[advertType][advertItemType];
      if (specificationsFields) {
        extendObservable(this, specificationsFields);
      }
    }
  }

  // Информация о ТС
  @observable is_new = false; // Состояние транспорта
  @observable brand = {}; // Объект марки
  @observable model = {}; // Объект модели
  @observable working_hours = ''; // Моточасы
  @observable gearbox_type = null; // Тип коробки передач.Значение из словаря

  // Характеристики устанавливаются в AddTruckStore -> setSpecialMachineryType

  // Дополнительно
  @observable wheel_formula = null; // Колесная формула. Значение из словаря
  @observable drive_type = null; // Привод. Значение из словаря
  @observable right_side_helm = null; // Правый руль
  @observable computer = null; // Бортовой компьютер
  @observable central_oil_management = null; // Центральная смазка
  @observable conditioner = null; // Кондиционер в кабине
  @observable engine_preheater = null; // Предпусковой обогрев двигателя
  @observable working_weight = null; // Рабочий вес
}

export default ItemSpecialMachineModel;
