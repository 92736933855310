import styled from 'styled-components';

export const PopupHintContainer = styled.div`
  .content-section {
    .dis-popover {
      display: block;
      cursor: pointer;
      font-weight: bold;
      margin-top: 6px;

      &:hover {
        color: #478cc8;
      }
    }
  }
  .templateWrapperClassName {
    width: 300px;
    font-size: 12px;
    margin-top: -5px;
    color: #39444f;
    line-height: 1.8;
    border-radius: 3px;

    button {
      top: 10px;
      right: 10px;
    }
  }
`;
