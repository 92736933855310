import { observable, action } from 'mobx';
import getEnvironDomain from 'utils/getEnvironDomain';
import { IPopupStore } from 'interfaces/IPopupStore';
import carDealerID from 'constants/carDealerID';
import { IRootStore } from 'stores/rootStore';

class PopupStore implements IPopupStore {
  private rootStore: IRootStore;
  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable isLoginPopupOpen = false;

  @observable next = '';

  @action openLoginPopup = (nextProp = '') => {
    // Для iOS приложения - открываем страницу id.ati.su, для остальных - попап авторизации
    if (this.rootStore.appStore.isMobileApp) {
      window.open(
        `//id.${getEnvironDomain()}/login/?atiapp=1&next=${encodeURIComponent(
          window.location.href,
        )}`,
        '_self',
      );
    } else {
      this.isLoginPopupOpen = true;
      this.next = nextProp;
    }
  };

  @action closeLoginPopup = () => {
    this.isLoginPopupOpen = false;
    this.next = '';
  };

  @action handleUserLogin = () => {
    if (this.next) {
      document.location.href = this.next;
    } else {
      window.location.reload();
    }
  };

  @action handleUserRegister = () => {
    window.open(
      `//id.${getEnvironDomain()}/register/?prof=${carDealerID}&redir=true&next=${encodeURIComponent(
        window.location.href,
      )}`,
      '_blank',
    );
  };

  @action handleRestorePasswordRedirect = () => {
    // @ts-ignore
    window.location = `//${getEnvironDomain()}/SendPassword.aspx`;
  };

  enableFastReg = () => {
    window.headerRegistartionLinkParams = `?prof=${carDealerID}&redir=true&next=${encodeURIComponent(
      window.location.href,
    )}`;
  };
}

export default PopupStore;
