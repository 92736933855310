import axios from 'axios';

async function getchecksAPI(advertId) {
  const config = {
    method: 'GET',
    url: `/api/v1/helpers/get_available_checks/${advertId}`,
  };

  const res = await axios(config);
  return res.data;
}

export default getchecksAPI;
