import getEnvironDomain from 'utils/getEnvironDomain';
import release from './release';

const environments = {
  dev: 'development',
  staging: 'staging',
  production: 'production',
};

const DEVELOPMENT = process.env.NODE_ENV === 'development';

function getEnv() {
  if (DEVELOPMENT) return environments.dev;
  if (typeof window !== 'undefined') return environments[window.atiEnv?.env] || environments.dev;
  return environments.dev;
}

const isEnabled = !DEVELOPMENT && window.Errno;
let errno = null;
const allowedPaths = ['/static/js/'];

(function init() {
  if (isEnabled) {
    errno = new window.Errno({
      dsn: `https://5e53404feed3462d9c4883d9966f439c@s1.${getEnvironDomain()}/83`,
      release,
      environment: getEnv(),
      autoSessionTracking: false,
      integrations: [new window.Sentry.Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
      ignoreErrors: ['yaCounter', 'Network Error', 'ChunkLoadError', 'code 401', 'Header:'],
      beforeSend: (event) => {
        const filename = event.exception?.values?.[0].stacktrace?.frames?.[0]?.filename || '';
        // Отправляем только ошибки из наших фалов
        if (allowedPaths.some((path) => filename.includes(path))) return event;
        return null;
      },
    });
  }
})();

function captureException(error, options) {
  if (!isEnabled || !errno) return;
  errno.captureMessage(error, options);
}

function captureMessage(message, options) {
  if (!isEnabled || !errno) return;

  errno.captureMessage(message, options);
}

export { captureException, captureMessage };
