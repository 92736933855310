import axiosInstance from './axios';

async function getUnAuthStarsAPI(idList) {
  const config = {
    method: 'POST',
    url: '/webapi/public/icons/v1/',
    data: idList,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getUnAuthStarsAPI;
