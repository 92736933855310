import axiosInstance from './axios';

async function getTransportsSaleAPI(sendData) {
  const config = {
    method: 'POST',
    url: '/public/api/v1/transports/search',
    data: sendData,
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default getTransportsSaleAPI;
