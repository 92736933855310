import isObject from 'utils/isObject';

const isTrueValue = (value) => {
  return !!value || value === 0;
};

const hastTrueValue = (obj) => {
  const arrKeyValue = Object.entries(obj);
  // TODO исправить
  // eslint-disable-next-line unicorn/no-reduce
  return arrKeyValue.reduce((acc, [key, value]) => (isTrueValue(value) ? true : acc), false);
};

/**
 * Подсчет количества заполненных полей
 * (объект, который содержит хотябы одно true значение - считается за одно заполненное поле)
 * @param  {} obj - объект с ключами для подсчета
 * @param  {} count - опциональный параметр, стартовое значение счётчика
 */
const getCountFilledFields = (obj, count = 0) => {
  if (isObject(obj)) {
    const arrKeyValue = Object.entries(obj);
    // eslint-disable-next-line unicorn/explicit-length-check
    if (!arrKeyValue.length) return count;
    // eslint-disable-next-line unicorn/no-reduce
    return arrKeyValue.reduce((acc, [key, value]) => {
      if (isObject(value)) {
        return hastTrueValue(value) ? acc + 1 : acc;
      }
      return isTrueValue(value) ? acc + 1 : acc;
    }, count);
  }
  return count;
};

export default getCountFilledFields;
