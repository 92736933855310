import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react-lite';
import Star from 'ati-ui-react/components/icons/Star';
import PopoverHint from 'generic/PopoverHint';

import { FavoriteLinkContainer } from './FavoriteLinkStyles';

const FavoriteLink: FC = observer(() => {
  const {
    profileStore: { isUser },
    popupStore: { openLoginPopup },
    tabsStore,
    favoritesStore: { isShowPopoverHintFavorite, setPopoverHintFavoriteVisible },
  } = useStores();

  const onHandleClick = (e: React.MouseEvent) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    tabsStore.setCurrentTab('favorites');
    if (!isUser) {
      e.preventDefault();
      openLoginPopup();
    }
  };

  const popupDescription = 'Выбирайте интересующие объявления и просматривайте единым списком.';

  return (
    <PopoverHint
      isShowPopover={isShowPopoverHintFavorite}
      onCloseClick={() => {
        setPopoverHintFavoriteVisible(false);
      }}
      mainContent={popupDescription}
      header="Избранные объявления находятся здесь"
    >
      <FavoriteLinkContainer>
        <Link className="favorite-link" to="/cabinet?tab=favorites" onClick={onHandleClick}>
          <Star width={14} height={14} className="star" />
          <span className="text">Избранное</span>
        </Link>
      </FavoriteLinkContainer>
    </PopoverHint>
  );
});

export default FavoriteLink;
