import styled from 'styled-components';

export const SavedSearchLinkContainer = styled.div`
  display: flex;
  align-items: center;

  .favorite-search-icon svg path {
    fill: #478cc8;
  }

  @media (max-width: 1023px) {
    width: 28px;
    height: 28px;
    border: 1px solid #478cc8;
    border-radius: 4px;
    box-sizing: border-box;

    .text {
      display: none;
    }
  }

  &:hover {
    .saved-link-search {
      text-decoration: underline;
      color: #478cc8;
    }
    .favorite-search-icon svg path {
      fill: #3a7bbf;
    }
  }

  .saved-link-search {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #478cc8;
    font-size: 12px;

    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 512px) {
      font-size: 12px;
    }
  }

  .favorite-search-icon {
    margin-right: 5px;

    svg path {
      fill: #478cc8;
    }

    @media (max-width: 1023px) {
      margin: 0;
    }
  }
`;
