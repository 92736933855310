import { observable } from 'mobx';

class TruckFormModelStore {
  @observable status = 'active'; // Значение из словаря advert_statuses, Default:active
  @observable advert_type = 'sale'; // Значение из словаря advert_types
  @observable owner_acc = null; // firm_id владельца
  @observable owner_contact = null; //  contact id владельца
  @observable contacts = []; //  contact id владельца
  @observable photos = []; // массив url'ов фото
  @observable photo_url = null; // фото, которое надо установить основным
  @observable city_verbose = null; // Город
  @observable country_verbose = null; // Страна
  @observable city_ati_id = null; //  ID города из базы ATI
  @observable country_ati_id = null; //  ID страны из базы ATI
  @observable region_ati_id = null; // ID региона из базы ATI
  @observable price = ''; // Цена
  @observable currency = 'rub'; // Валюта, string, Default:rub
  @observable comment = ''; //  Подробное текстовое описание
  @observable advert_item_type = null; // Тип продаваемого транспорта,значение из словаря supported_types
  @observable address = ''; // адрес
  @observable advert_item = {
    is_new: false, // Состояние транспорта
    brand: {}, // Объект марки
    model: {}, // Объект модели
    year: '', // Год выпуска
    mileage: '', //  Пробег в км
    vin: '', //  VIN номер
    gov_num: '', // Гос.номер

    engine_type: '', // Тип двигателя. Значение из словаря
    engine_power: '', // Мощность двигателя в лс
    engine_volume: '', // Объем двигателя в литрах
    gearbox_type: null, // Тип коробки передач.Значение из словаря
    body_type: null, // Тип кузова. Значение из словаря
    volume: '', //  Объем кузова в м3
    capacity: '', // Грузоподъемность в тоннах
    length: '', // Длина м
    height: '', // Высота м
    width: '', // Ширина м
    cabin_type: null, // Тип кабины. Значение из словаря
    suspension_type: null, // Тип подвески. Значение из словаря
    wheel_formula: null, // Колесная формула. Значение из словаря
    drive_type: null, // Привод. Значение из словаря
    max_speed: '', // Максимальная скорость км/ч
    fuel_consumption_100: '', // Расход топлива на 100км
    tank_volume: '', // Вместимость бака
    tanks_count: '', // Число баков
    right_side_helm: null, // Правый руль
    computer: null, // Бортовой компьютер
    central_oil_management: null, // Центральная смазка
    conditioner: null, // Кондиционер в кабине
    heater_in_cabin: null, // Отопитель салона
    engine_preheater: null, // Предпусковой обогрев двигателя

    // Тягач
    tractor_type: '', // Тип тягача
    saddle_height: '', // Высота седельного устройства
    // Спецтехника
    working_hours: '', // Моточасы
    // Автокран
    max_jib_upper_height: '', // Высота подъёма
    min_jib_departure: '', // Мин.вылет стрелы
    max_jib_departure: '', // Макс.вылет стрелы
    // Экскаватор
    excavator_type: '', // Тип экскаватора
    excavator_bucket_volume: '', // Объём ковша
    max_digging_height: '', // Высота копания
    max_digging_depth: '', // Глубина копания
    dumping_height: '', // Высота разгрузки
    // Бульдозер
    traction_class: '', // Тяговый класс
    blade_width: '', // Ширина отвала
    tracks_width: '', // Ширина гусениц
    fully_weight: '', // Полная масса
    ground_pressure: '', // Давление на грунт
    max_cutting_depth: '', // Макс. глубина среза
    max_loosening_depth: '', // Макс. глубина рыхления
    max_blade_position_height: '', // Макс. высота подъема отвала
    // Погрузчик
    loader_type: '', //
    // max_jib_upper_height: '', // Высота подъема
    speed_without_load: '', // Скорость без груза
    speed_with_load: '', // Скорость с грузом
    load_upping_speed: '', // Скорость подъема груза
    // Другой тип спецтехники
    special_type: '', // Другой тип спецтехники

    // Автобус
    bus_type: '', // тип автобуса
    seats_count: '', // поле для автобуса - количество мест
  };
}

export default TruckFormModelStore;
