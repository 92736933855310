import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import Popover from 'ati-ui-react/components/Popover';
import SavedSearchesLink from 'components/MainPage/components/Search/components/SavedSearchesLink';
import { addMetric } from 'utils/addMetrics';
import FilterList from './components/FilterList';

import { PopoverFiltersContainer } from './PopoverFiltersStyles';

const PopoverFilters: FC = observer(() => {
  const {
    saveFilterStore: {
      filters,
      isVisiblePopover,
      setVisiblePopover,
      getFilters,
      setPopoverHintFilterVisible,
    },
    favoritesStore: { setPopoverHintFavoriteVisible },
  } = useStores();

  if (filters.length < 0) return null;

  const onHandleDisplayOff = () => {
    setVisiblePopover(false);
  };

  const onHandleDisplayOn = async () => {
    await getFilters();
    setVisiblePopover(true);
    setPopoverHintFilterVisible(false);
    setPopoverHintFavoriteVisible(false);
    addMetric('saved-search-show-list');
  };

  return (
    <PopoverFiltersContainer>
      <Popover
        element={<FilterList />}
        closeOnOverlayClick
        visible={isVisiblePopover}
        eventType="mousedown"
        place="bottom"
        className="filter-list"
        onClose={onHandleDisplayOff}
      >
        <SavedSearchesLink onHandleClick={onHandleDisplayOn} />
      </Popover>
    </PopoverFiltersContainer>
  );
});

export default PopoverFilters;
