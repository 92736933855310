import { observable, extendObservable, action, runInAction, toJS, set, computed } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import postAnAdAPI from 'services/postAnAdAPI';
import getMyContactsAPI from 'services/getMyContactsAPI';
import getOwnershipsAPI from 'services/getOwnershipsAPI';
import checkExistanceAdvertAPI from 'src/services/checkExistanceAdvertAPI';
import mapAddFormFields from 'utils/mapAddFormFields';
import removeObservableFields from 'utils/removeObservableFields';
import { addMetric } from 'utils/addMetrics';
import { privatePersonID } from 'constants/ownershipsID';
import AsyncStateModel from 'models/AsyncStateModel';
import AdvertFormModel from 'models/AdvertFormModel/AdvertFormModel';
import TruckFormModel from 'models/AdvertFormModel/TruckFormModel';
import TractorFormModel from 'models/AdvertFormModel/TractorFormModel';
import TrailerFormModel from 'models/AdvertFormModel/TrailerFormModel';
import BusFormModel from 'models/AdvertFormModel/BusFormModel';
import SpecialMacineFormModel from 'models/AdvertFormModel/SpecialMacineFormModel';
import RoadTrainTractorFormModel from 'models/AdvertFormModel/RoadTrainTractorFormModel';
import RoadTrainTruckFormModel from 'models/AdvertFormModel/RoadTrainTruckFormModel';
import ItemTrailerModel from 'models/AdvertFormModel/items/ItemTrailerModel';
import additionalItemFields from 'models/AdvertFormModel/items/additionalItemFields';
import specificationsSpecialMachines from 'models/AdvertFormModel/items/specificationsSpecialMachines';
import additionalAdFields from 'models/AdvertFormModel/additionalAdFields';
import { IAnyObject } from 'interfaces/GenericInterfaces';
import { IRootStore } from './rootStore';

/**
 * Поля, значения которых должны быть преобразованы в числа
 */
// eslint-disable-next-line unicorn/prefer-set-has
const fieldsToBeNumbers = [
  'price',
  'year',
  'mileage',
  'working_hours',
  'working_weight',
  'engine_power',
  'engine_volume',
  'volume',
  'capacity',
  'length',
  'height',
  'width',
  'max_speed',
  'fuel_consumption_100',
  'tank_volume',
  'tanks_count',
  'country_ati_id',
  'region_ati_id',
];

/**
 * Числовые значения, которые могут быть нулями
 */
// eslint-disable-next-line unicorn/prefer-set-has
const canBeZero = ['country_ati_id', 'region_ati_id'];

class AddTruckStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable postAdvertState = new AsyncStateModel(); // Состояние (для асинхронного запроса)
  @observable myContacts = []; // Контакты фирмы пользователя
  @observable isAdditionalTrailer = false; // Дополнительный прицеп для автопоезда с типом тяги "Тягач"
  @observable transportCategory = ''; // Категория транспорта для текущей формы
  @observable transportSubcategory = ''; // Подкатегория для текущей категории транспорта
  @observable isLowBalance = false;
  @observable isShouldUpdatePhotoComponent = false; // при изменении данной переменной перерендеривается компонент фото в форме
  @observable ownerships = []; // список организационно-правовых форм
  @observable countryID = '0'; // ID страны, для поля mobile
  @observable duplicateAdvertsByVIN: any = {}; // URL объявления, vin которого совпадает с введенным в форме

  /**
   * Модель объявления
   * (по умолчанию устанавливается модель вернхнего уровня, без вложенных элементов)
   */
  @observable fields = new AdvertFormModel();

  @computed get isSelectedCategory() {
    return !!this.transportCategory;
  }

  @action setCountryID = (ID: string) => {
    this.countryID = ID;
  };

  /**
   * Перерендер компонента фото
   */
  @action updatePhotoComponent = () => {
    this.isShouldUpdatePhotoComponent = !this.isShouldUpdatePhotoComponent;
  };

  /**
   * Сброс параметров формы
   */
  @action resetForm = () => {
    this.isAdditionalTrailer = false;
    this.resetSuggestions();
    this.rootStore.formValidationStore.clearErrorFields();
  };

  /**
   * Сброс контактов
   */
  @action resetContacts = () => {
    this.myContacts = [];
  };

  /**
   * Установка типа объявления (продажа/аренда/заявки)
   */
  @action setAdvertType = (newAdvertType: string) => {
    const prevAdvertType = this.fields.advert_type;
    set(this.fields, { advert_type: newAdvertType });
    this._modifyModelWhenAdvertTypeChanged(newAdvertType, prevAdvertType);
  };

  /**
   * Установка категории транспорта
   * @param  {string} category - [truck, tractor, trailer, road_train, bus, special_machinery]
   * @param  {string} subcategory
   */
  @action setTransportCategory = (category: string, subcategory = '') => {
    this.transportCategory = category;
    this.transportSubcategory = subcategory;
    this.setTransportType(subcategory || category);
  };

  /**
   * Сброс категории транспорта
   */
  @action resetTransportCategory = () => {
    this.transportCategory = '';
  };

  /**
   * Установка типа транспорта
   * (не сбрасывает данные при смене типа в рамках одной категории транспорта)
   * @param  {string} newAdvertItemType
   */
  @action setTransportType = (newAdvertItemType: string) => {
    let formFields;
    const isChangeWithinOneCategory = this._checkChangeWithinOneCategory(newAdvertItemType);
    if (isChangeWithinOneCategory) {
      formFields = this.fields;
      formFields.advert_item_type = newAdvertItemType;
    } else {
      this.resetForm();
      formFields = this._getNewAdvertObj(newAdvertItemType);
    }
    formFields.advert_type = this.fields.advert_type;
    if (this.fields.advert_type === 'purchase') {
      // @ts-ignore
      formFields.proposal_type = this.fields.proposal_type;
    }
    this.fields = formFields;
  };

  /**
   * Сброс всех полей и параметров формы
   */
  @action clearFormFields = () => {
    this.resetForm();
    const formFields = this._getNewAdvertObj(this.transportCategory);
    formFields.advert_type = this.fields.advert_type;
    this.fields = formFields;
    this.updatePhotoComponent();
  };

  /**
   * Сброс автосаджестов
   */
  @action resetSuggestions = () => {
    this.rootStore.truckBrandsStore.resetSuggestions();
    this.rootStore.truckModelsStore.resetSuggestions();
    this.rootStore.geoSearchStore.resetSuggestions();
  };

  /**
     Cброс поля продвижения объявления
   */
  @action resetAdvertPromotion = () => {
    this.fields.advert_promotion = '';
  };

  /**
   * Добавить трейлер (для автопоезда с типом тяги "Тягач")
   */
  @action addTrailer = () => {
    this.isAdditionalTrailer = true;
    const advertType = this.fields.advert_type;
    // @ts-ignore
    this.fields.advert_items = observable([
      // @ts-ignore
      ...this.fields.advert_items,
      {
        type: 'trailer',
        transport: toJS(new ItemTrailerModel({ advertType })),
      },
    ]);
  };

  /**
   * Удалить трейлер (для автопоезда с типом тяги "Тягач")
   */
  @action removeTrailer = () => {
    this.isAdditionalTrailer = false;
    // @ts-ignore
    this.fields.advert_items = this.fields.advert_items.filter((item) => item.type !== 'trailer');
  };

  /**
   * Получения значения из объекта fields
   * @param  {string} field - поле из объекта fields
   */
  getAdvertProperty = (field: string) => {
    // @ts-ignore
    return this.fields?.[field];
  };

  /**
   * Получения значения из объекта fields.step_two_data
   * @param  {string} field - поле из объекта step_two_data
   */
  getAdvertRegProperty = (field: string) => {
    // @ts-ignore
    return this.fields?.step_two_data?.[field];
  };

  /**
   * Получения значения из объекта fields.advert_item
   * @param  {string} field - поле из объекта fields.advert_item или fields.advert_items[type]
   * @param  {string} type - обязателен для получения значения составных типов ТС
   */
  getAdvertItemProperty = (field: string, type: string) => {
    return this.rootStore.formValidationStore.isRoadTrain && type
      ? // @ts-ignore
        this.fields.advert_items.find((item) => item.type === type).transport?.[field]
      : // @ts-ignore
        this.fields.advert_item?.[field];
  };

  /**
   * Обновление свойства объявления (верхний уровень)
   * @param  {any} value - новое значение
   * @param  {string} field - поле из объекта fields
   */
  @action updateAdvertProperty = (value: any, field: string) => {
    // eslint-disable-next-line unicorn/no-fn-reference-in-iterator
    const filteredValue = this.rootStore.formValidationStore.filter(value, field);
    // @ts-ignore
    this.fields[field] = filteredValue;
    this.rootStore.formValidationStore.validate(filteredValue, field);
  };

  /**
   * Обновление свойства транспорта в объявлении
   * @param  {any} value - новое значение
   * @param  {string} field - поле из объекта fields.advert_item или fields.advert_items[type]
   * @param  {string} type - обязателен для обновления значения составных типов ТС
   */
  @action updateAdvertItemProperty = (value: any, field: string, type: string) => {
    // eslint-disable-next-line unicorn/no-fn-reference-in-iterator
    const filteredValue = this.rootStore.formValidationStore.filter(value, field);
    if (type && this.fields.advert_item_type === 'road_train') {
      // @ts-ignore
      const index = this.fields.advert_items.findIndex((item) => item.type === type);
      // @ts-ignore
      this.fields.advert_items[index].transport[field] = filteredValue;
    } else {
      // @ts-ignore
      this.fields.advert_item[field] = filteredValue;
    }
    this.rootStore.formValidationStore.validate(filteredValue, field, type);
  };

  /**
   * Обновление полей 2го шага регистрации
   */
  @action updateTwoStepProperty = (value: any, field: string) => {
    // eslint-disable-next-line unicorn/no-fn-reference-in-iterator
    const filteredValue = this.rootStore.formValidationStore.filter(value, field);
    // @ts-ignore
    this.fields.step_two_data[field] = filteredValue;
    this.rootStore.formValidationStore.validate(filteredValue, field);
  };

  @action updateMobile = (value: string) => {
    // @ts-ignore
    this.fields.step_two_data.mobile = value;
    this.rootStore.formValidationStore.validate(value, 'mobile');
  };

  /**
   * Получение функции для получения значения из объекта fields на основе уровня,
   * на котором лежит поле ('advert' - уровень объявления, 'item' - уровень транспорта в объявлении, registration - поля второго шага регистрации)
   * @param  {string} levelField
   */
  getProperty = (levelField = 'item') => {
    const functions: any = {
      advert: this.getAdvertProperty,
      item: this.getAdvertItemProperty,
      registration: this.getAdvertRegProperty,
    };
    return functions[levelField];
  };

  /**
   * Получение функции для обновления значения в объекте fields на основе уровня,
   * на котором лежит поле ('advert' - уровень объявления, 'item' - уровень транспорта в объявлении, registration - поля второго шага регистрации)
   * @param  {string} levelField
   */
  updateProperty = (levelField = 'item') => {
    const functions: any = {
      advert: this.updateAdvertProperty,
      item: this.updateAdvertItemProperty,
      registration: this.updateTwoStepProperty,
    };
    return functions[levelField];
  };

  /**
   * Установка типа спецтехники
   */
  @action setSpecialMachineryType = (e: any) => {
    const prevItemType = this.fields.advert_item_type;
    const newItemType = e?.value;
    this.updateAdvertProperty(newItemType, 'advert_item_type');
    this._modifySpecialMachineryModelWhenItemTypeChanged(newItemType, prevItemType);
  };

  /**
   * Обновление значений города в объекте fields
   * @param  {string || number} id  - id города
   * @param  {string} name - адрес
   * @param  {string || number} regionId - id региона
   */
  @action updateCity = (id: string | number, name: string, regionId: string | number) => {
    // eslint-disable-next-line unicorn/no-fn-reference-in-iterator
    const filteredValue = this.rootStore.formValidationStore.filter(name, 'city_verbose');
    // @ts-ignore
    this.fields.city_ati_id = id;
    this.fields.city_verbose = filteredValue;
    // @ts-ignore
    this.fields.region_ati_id = regionId;
    this.rootStore.formValidationStore.validate(filteredValue, 'city_verbose');
  };

  /**
   * Получение контактов фирмы пользователя
   */
  @action getMyContacts = async () => {
    const res = await getMyContactsAPI();
    runInAction(() => {
      this.myContacts = res?.result?.contacts || [];
    });
  };

  /**
   * Добавление контакта в объект fields
   */
  @action addContact = (contact: any) => {
    const { id } = contact;
    if (!this.fields.contacts.find((item) => item.id === id)) {
      this.fields.contacts = [...this.fields?.contacts, contact];
    }
  };

  /**
   * Удаление контакта из объекта fields
   */
  @action removeContact = (contact: any) => {
    const { id } = contact;
    this.fields.contacts = this.fields.contacts.filter((item) => id !== item.id);
    // @ts-ignore
    if (!this.myContacts.find((item) => item.id === id)) {
      // @ts-ignore
      this.myContacts.push(contact);
    }
  };

  @action getUploadImages = (images: []) => {
    // @ts-ignore
    const countImages = this.fields.photos?.length;
    // Это monkey patch - нужно пофиксить
    // @ts-ignore
    const removeBlobs = images?.filter((item) => !item.source.includes('blob'));
    // @ts-ignore
    const newImages = removeBlobs.map((item) => item.source);
    // @ts-ignore
    this.fields.photos = newImages;
    // @ts-ignore
    this.fields.photo_url = this.fields.photos?.[0];
    // @ts-ignore
    if (countImages !== this.fields.photos?.length) {
      this.rootStore.draftStore.updateDraft();
    }
  };

  @action onDeleted = (item: any) => {
    if (item) {
      // @ts-ignore
      this.rootStore.formValidationStore.validate(this.fields.photos, 'photos');
    }
  };

  @action checkDuplicateAdvertsByVIN = async (vin: string) => {
    try {
      const res = await checkExistanceAdvertAPI({ vin });
      if (res.ok) {
        runInAction(() => {
          this.duplicateAdvertsByVIN[vin] = res.result?.url;
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  @action onHandleBalance = (balance: any) => {
    this.isLowBalance = balance;
  };

  /**
   * Публикация объявления (бесплатное, либо баланса достаточно для выбранной услуги)
   */
  @action publishAdvert = () => {
    this.rootStore.formValidationStore.finalValidation();

    if (this.rootStore.formValidationStore.isValidForm && this.isLowBalance) {
      this.rootStore.modalStore.openModal();
    } else {
      this.postAnAd();
    }
  };

  /**
   * Публикация объявления (без продвижения)
   */
  @action publishAdvertWithoutPromotion = () => {
    this.rootStore.formValidationStore.finalValidation();

    runInAction(() => {
      this.fields.advert_promotion = '';
      this.postAnAd();
    });
  };

  /**
   * Отправка объявления
   */
  @action postAnAd = async () => {
    if (this.rootStore.formValidationStore.isValidForm) {
      this.postAdvertState.request();
      try {
        const res = await postAnAdAPI(this._generationData());
        if (!this.rootStore.advertsStore.isEdit) {
          this.rootStore.draftStore.resetCurrentDraft();
        }
        this.rootStore.afterPublicationStore.setAdvertID(res.result._id);
        this.postAdvertState.success();
        this.addMetricOfPaid();
      } catch (error) {
        this.postAdvertState.failure();
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  @action addMetricOfPaid = () => {
    if (
      this.rootStore.advertsStore.isEdit &&
      this.fields.advert_promotion &&
      // @ts-ignore
      !this.fields.actual_promotion
    ) {
      addMetric('paid-for-already-published');
    }
  };

  @action getOwnerships = async () => {
    const res = await getOwnershipsAPI();
    const normalizeOwnerships = res
      .map((obj: any) => ({
        value: obj.Id,
        label: obj.Name,
      }))
      .filter((obj: any) => obj.value !== privatePersonID);
    runInAction(() => {
      this.ownerships = normalizeOwnerships;
    });
  };

  /* ------------------------  Служебные функции  ------------------------ */

  /**
   * Модификация модели объявления при смене типа (advert_type) объявления
   * @param  {'sale' | 'rent' | 'purchase'} newAdvertType - новый тип
   * @param  {'sale' | 'rent' | 'purchase'} prevAdvertType - предыдущий тип
   */
  @action _modifyModelWhenAdvertTypeChanged = (newAdvertType: string, prevAdvertType: string) => {
    const advertItemType = this.fields.advert_item_type;
    const [keysToRemove, fieldsToAdd] = this._getDifferentKeys(
      additionalAdFields[prevAdvertType],
      additionalAdFields[newAdvertType],
    );

    // Удаляем лишние поля с уровня объявления
    removeObservableFields(this.fields, keysToRemove);
    // Добавляем нужные поля на уровень объявления
    set(this.fields, fieldsToAdd);

    // Для автопоезда
    if (this.rootStore.formValidationStore.isRoadTrain) {
      // @ts-ignore
      this.fields.advert_items.forEach((item) => {
        const [toRemove, toAdd] = this._getDifferentKeys(
          additionalItemFields[prevAdvertType][item.type] || {},
          additionalItemFields[newAdvertType][item.type] || {},
        );
        // Удаляем лишние поля с уровня транспорта
        removeObservableFields(item.transport, toRemove);
        // Добавляем нужные поля на уровень транспорта
        extendObservable(item.transport, toAdd);
      });
      // Для простого транспорта
      // @ts-ignore
    } else if (this.fields?.advert_item) {
      const [toRemove, toAdd] = this._getDifferentKeys(
        additionalItemFields[prevAdvertType][advertItemType] || {},
        additionalItemFields[newAdvertType][advertItemType] || {},
      );

      // Удаляем лишние поля с уровня транспорта
      // @ts-ignore
      removeObservableFields(this.fields.advert_item, toRemove);
      // Добавляем нужные поля на уровень транспорта
      // @ts-ignore
      extendObservable(this.fields.advert_item, toAdd);

      // Поля спецтехники
      if (
        this.transportCategory === 'special_machinery' &&
        specificationsSpecialMachines[prevAdvertType][advertItemType]
      ) {
        const [specialMachineFieldsToRemove, specialMachineFieldsToAdd] = this._getDifferentKeys(
          specificationsSpecialMachines[prevAdvertType][advertItemType],
          specificationsSpecialMachines[newAdvertType][advertItemType],
        );
        // @ts-ignore
        removeObservableFields(this.fields.advert_item, specialMachineFieldsToRemove);
        // @ts-ignore
        extendObservable(this.fields.advert_item, specialMachineFieldsToAdd);
      }
    }
  };

  /**
   * Модификация модели спецтехники при смене типа техники (advert_item_type)
   * @param  {string} newItemType - новый тип спецтехники
   * @param  {string} prevItemType - предыдущий тип спецтехники
   */
  @action _modifySpecialMachineryModelWhenItemTypeChanged = (
    newItemType: string,
    prevItemType: string,
  ) => {
    const advertType = this.fields.advert_type;
    set(this.fields, { advert_item_type: newItemType });

    // Удаляем характеристики предыдущего типа
    const prevSpecificationsFields = Object.keys(
      // @ts-ignore
      specificationsSpecialMachines[advertType][prevItemType] || {},
    );
    // @ts-ignore
    removeObservableFields(this.fields.advert_item, prevSpecificationsFields);

    // Добавляем характеристики нового типа
    // @ts-ignore
    const specificationsFields = specificationsSpecialMachines[advertType][newItemType] || {};
    // @ts-ignore
    extendObservable(this.fields.advert_item, specificationsFields);
  };

  /**
   * Проверка что изменение типа ТС происходит
   * в рамках одной категории транспорта
   */
  _checkChangeWithinOneCategory = (newType: string) => {
    const currentType = this.fields.advert_item_type;

    if (
      (currentType === 'trailer' && newType === 'semitrailer') ||
      (currentType === 'semitrailer' && newType === 'trailer')
    )
      return true;
    return false;
  };

  /**
   * Получение параметров формы
   */
  _getFormOptions = () => ({
    advertType: this.fields.advert_type,
    advertItemType: this.fields.advert_item_type,
    isAdditionalTrailer: this.isAdditionalTrailer,
    isOnlyFastRegistration: this.rootStore.profileStore.isFastReg,
  });

  /**
   * Получение нового объекта (модели) формы
   * @param  {string} newAdvertItemType
   */
  @action _getNewAdvertObj = (newAdvertItemType: string) => {
    const options = this._getFormOptions();
    let newAdvertObj;
    switch (newAdvertItemType) {
      case 'truck':
        newAdvertObj = new TruckFormModel(options);
        break;
      case 'tractor':
        newAdvertObj = new TractorFormModel(options);
        break;
      case 'trailer':
        newAdvertObj = new TrailerFormModel(options);
        break;
      case 'bus':
        newAdvertObj = new BusFormModel(options);
        break;
      case 'special_machinery':
        newAdvertObj = new SpecialMacineFormModel(options);
        break;
      case 'road_train':
      case 'road_train_tractor':
        newAdvertObj = new RoadTrainTractorFormModel(options);
        break;
      case 'road_train_truck':
        newAdvertObj = new RoadTrainTruckFormModel(options);
        break;
      // Изменение типа ТС в рамках одной категории
      default:
        newAdvertObj = cloneDeep(toJS(this.fields));
        newAdvertObj.advert_item_type = newAdvertItemType;
        break;
    }
    return newAdvertObj;
  };

  /**
   * Генерация объекта данных для отправки
   * (очистка пустых полей и конвертация некоторых значений в числа)
   */
  _generationData = () => {
    return mapAddFormFields(this.fields, (obj: any, key: string) => {
      const value = obj[key];
      if (
        (!value || (typeof value === 'object' && isEmpty(value))) &&
        value !== false &&
        value !== 0
      ) {
        delete obj[key];
      } else if (fieldsToBeNumbers.includes(key)) {
        const val = toNumber(value);
        if (val === 0 && !canBeZero.includes(key)) {
          delete obj[key];
        } else {
          obj[key] = val;
        }
      }
    });
  };

  /**
   * Функция получения массива ключей - для удаления, и объекта - для расширения модели объявления
   * Принимает объекты расширения старой и новой моделей
   */
  _getDifferentKeys = (oldFields: IAnyObject, newFields: IAnyObject) => {
    const oldKeys = Object.keys(oldFields);
    const newKeys = Object.keys(newFields);

    const keysToRemove = oldKeys.filter((oldKey) => !newKeys.includes(oldKey));
    const keysToAdd = newKeys.filter((newKey) => !oldKeys.includes(newKey));
    const fieldsToAdd = keysToAdd.reduce((acc, key) => ({ ...acc, [key]: newFields[key] }), {});
    return [keysToRemove, fieldsToAdd];
  };
}

export default AddTruckStore;
