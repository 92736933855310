import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';

const ScrollToTop = observer(() => {
  const {
    pagesStore: { currentPage },
  } = useStores();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return null;
});

export default ScrollToTop;
