import styled from 'styled-components';

export const FilterContainer = styled.div`
  border-bottom: 2px solid #cccccc;
  padding: 18px 20px 26px 20px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #e8f4ff;
  }

  &:last-child {
    border-bottom: none;
  }

  .heading {
    margin: 0 0 8px 0;
  }

  .render-filter {
    line-height: 1.5;
  }

  .filters-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }
`;
