import axiosInstance from './axios';

async function deleteMultiAPI(data) {
  const config = {
    method: 'DELETE',
    url: `/api/v1/transports/multi`,
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default deleteMultiAPI;
