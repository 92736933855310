import { action, observable, runInAction } from 'mobx';
import postSimilarAdvertsAPI from 'services/PostSimilarAdvertsAPI';
import AsyncStateModel from 'models/AsyncStateModel';

interface IAdvertId {
  advertId: string;
}

class RelatedStore {
  @observable status = new AsyncStateModel();
  @observable relatedAdverts = [];
  @observable relatedAccounts = {};

  @action getRelatedTrucks = async ({ advertId }: IAdvertId) => {
    this.status.request();
    try {
      const res = await postSimilarAdvertsAPI({ advert_id: advertId });
      runInAction(() => {
        this.relatedAdverts = res.result.adverts;
        this.relatedAccounts = res.result.accounts;
      });
      this.status.success();
    } catch {
      runInAction(() => {
        this.status.failure();
      });
    }
  };
}

export default RelatedStore;
