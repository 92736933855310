import axiosInstance from './axios';

async function postAnAdAPI(newAd) {
  const config = {
    method: 'POST',
    url: '/api/v1/transports',
    data: newAd,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default postAnAdAPI;
