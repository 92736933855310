import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import NotificationComponent from 'ati-ui-react/widgets/Notification';
import { NotificationsStyles } from './NotificationStyles';

const Notification: FC = observer(() => {
  const {
    notificationStore: { notification, closeNotification },
  } = useStores();

  return (
    <NotificationsStyles>
      <NotificationComponent
        show={!!notification}
        closeNotification={closeNotification}
        delay={notification?.delay}
        message={notification?.text}
        type={notification?.type}
        className="global-notifications"
      />
    </NotificationsStyles>
  );
});

export default Notification;
