import React, { FC } from 'react';
import PageContainer from 'generic/PageContainer';
import Skeleton from 'ati-ui-react/components/Skeleton';
import { AfterPublicationPageSkeletonStyles } from './AfterPublicationPageSkeletonStyles';

const AfterPublicationPageSkeleton: FC = () => {
  return (
    <PageContainer page="afterPublication">
      <AfterPublicationPageSkeletonStyles>
        <Skeleton height={100} className="skeleton-row first" />
        <Skeleton height={140} className="skeleton-row second" />
        <Skeleton height={500} className="skeleton-row third" />
      </AfterPublicationPageSkeletonStyles>
    </PageContainer>
  );
};

export default AfterPublicationPageSkeleton;
