import { privatePersonID } from 'constants/ownershipsID';

const registrationData = {
  name: '',
  fio: '',
  mobile: '',
  ownership_id: privatePersonID, // Частное лицо по умолчанию
};

export default {
  step_two_data: {
    ...registrationData,
  },
};
