import { observable, action, runInAction } from 'mobx';

export interface IErrorsStore {
  errorText?: string;
  handleErrors: (text?: string) => void;
  clearErrors: () => void;
}

class ErrorsStore implements IErrorsStore {
  @observable errorText = '';

  handleErrors = (text?: string): void => {
    runInAction(() => {
      this.errorText = text || 'Перезагрузите страницу, что-то пошло не так.';
    });
  };

  @action clearErrors = () => {
    this.errorText = '';
  };
}

export default new ErrorsStore();
