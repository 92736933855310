import getSearchAdvertType from 'utils/getSearchAdvertType';

const searchFieldsSaleModel = (item) => {
  //  Типы  гео поиска
  const type = {
    country: 0,
    region: 1,
  };

  const isRent = window.location.pathname === '/trucks/rent';

  return {
    advert_type: getSearchAdvertType(),

    capacity: {
      from: Number(item.capacity?.from) || 0,
      to: Number(item.capacity?.to) || 0,
    },
    mileage: {
      from: Number(item.mileage?.from) || 0,
      to: Number(item.mileage?.to) || 0,
    },
    working_hours: {
      from: Number(item.working_hours?.from) || 0,
      to: Number(item.working_hours?.to) || 0,
    },
    year: {
      from: Number(item.year?.from) || 0,
      to: Number(item.year?.to) || 0,
    },
    price: {
      from: Number(item.price?.from) || 0,
      to: Number(item.price?.to) || 0,
    },

    // Добавляем поле period_type только на вкладке аренды
    ...(isRent && {
      period_type: item.price?.from || item.price?.to ? item?.period_type || 'day' : null,
    }),
    // Если выбран регион отправляет поля - country_id, region_id
    // Если выбрана страна отправляем ттолько - country_id
    city: {
      ...(item.city?.type !== type.country &&
        item.city?.type !== type.region && {
          id: item.city?.id || null,
        }),
      ...(item.city?.type !== type.country &&
        item.city?.type !== type.region && {
          name: item.city?.name || null,
        }),
      country_id: item.city?.country_id,
      region_id: item.city?.region_id,
    },
    ordering: item.ordering !== 'by_time_of_publication' ? item.ordering : null,
    advert_item_type: item.advert_item_type !== 'all_types_trucks' ? item.advert_item_type : null,
    limit: item.limit,
    offset: item.offset,

    is_available: item.is_available || null,
    only_new: item.only_new || null,
    with_vin: item.with_vin || null,
    with_lizing: item.with_lizing || null,

    with_driver: item.with_driver || null,

    brand: item.brand || null,
    model: item.model || null,
    proposal_type: item.proposal_type || null,
  };
};

export default searchFieldsSaleModel;
