import axiosInstance from './axios';

async function deleteFilterAPI(data) {
  const config = {
    method: 'DELETE',
    url: `/api/v1/filters`,
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default deleteFilterAPI;
