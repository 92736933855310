import React, { FC } from 'react';
import PageContainer from 'generic/PageContainer';
import Skeleton from 'ati-ui-react/components/Skeleton';
import { FormPageSkeletonStyles } from './FormPageSkeletonStyles';

const FormPageSkeleton: FC = () => {
  return (
    <PageContainer page="form">
      <FormPageSkeletonStyles>
        <div className="skeleton-wrapper">
          <Skeleton height={45} width="50%" className="skeleton-row first" />
          <Skeleton height={100} className="skeleton-row second" />
          <Skeleton height={200} className="skeleton-row third" />
        </div>
      </FormPageSkeletonStyles>
    </PageContainer>
  );
};

export default FormPageSkeleton;
