export const addMetric = (event) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({ event });
};

export const addMetricFormPublishBtn = (isSale, isEdit) => {
  if (isSale) {
    if (isEdit) {
      addMetric('tm-adv-sale-edit');
    } else {
      addMetric('tm-adv-sale-add');
    }
  } else if (isEdit) {
    addMetric('tm-adv-rent-edit');
  } else {
    addMetric('tm-adv-rent-add');
  }
};

export const addMetricSearchBtn = (isRent) => {
  if (isRent) {
    addMetric('tm-adv-rent-search');
  } else {
    addMetric('tm-adv-sale-search');
  }
};

export const addMetricSaveFilter = (filter) => {
  addMetric(`saved-search-save`);
  const advert_type = filter?.search_params?.advert_type;
  if (advert_type) {
    addMetric(`saved-search-save-${advert_type}`);
  }
};
