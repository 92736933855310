import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'hooks/useStores';
import HeaderSubmenu from 'generic/HeaderSubmenu';

import App from './components/App';
import Store from './stores';
import ErrorBoundary from './components/generic/ErrorBoundary';
import './index.css';

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider value={new Store()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
