import { IAnyObject } from 'interfaces/GenericInterfaces';

// Фото
const photoFields = {
  photos: [], // массив url'ов фото
  photo_url: '', // фото, которое надо установить основным
};

// Описание
const comment = {
  comment: '', //  Подробное текстовое описание
};

// Информация о ДТП
const accidentDescription = {
  accident_description: '', // Описание ДТП
};

// Адрес
const address = {
  address: '', // адрес
};

// Тип заявки
const proposalType = {
  proposal_type: 'purchase', // тип заявки
};

const additionalAdFields: IAnyObject = {
  sale: {
    ...photoFields,
    ...comment,
    ...accidentDescription,
    ...address,
    availability: 'in_stock',
  },
  rent: {
    ...photoFields,
    ...comment,
    ...address,
    period_type: 'hour',
    min_period: '',
    with_driver: false,
    busy_date_ranges: [],
  },
  purchase: {
    ...comment,
    ...proposalType,
  },
};

export default additionalAdFields;
