import axiosInstance from './axios';

async function deleteFavoritesMultiAPI(data) {
  const config = {
    method: 'DELETE',
    url: `/api/v1/favorites/multi`,
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default deleteFavoritesMultiAPI;
