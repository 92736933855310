import { observable, action, computed } from 'mobx';

class ProcessStateModel {
  constructor(initialState = 'default') {
    this.state = initialState;
  }

  @observable state;

  @action start() {
    this.state = 'started';
  }

  @action stop() {
    this.state = 'stopped';
  }

  @computed get isRun() {
    return this.state === 'started';
  }

  @computed get isDone() {
    return this.state === 'stopped';
  }
}

export default ProcessStateModel;
