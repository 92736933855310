type noWipeAwayQs = {
  ordering?: string;
};

const noWipeAwayQs = ({ ordering }: noWipeAwayQs): string => {
  if (ordering === 'by_time_of_publication') return '';

  const qs = [ordering && `?ordering=${ordering}`].filter(Boolean).join('');
  return qs;
};

export default noWipeAwayQs;
