import { configure } from 'mobx';

import { IAppStore } from 'interfaces/IAppStore';
import { IGeoSearchStore } from 'interfaces/IGeoSearchStore';
import { ITruckBrandsStore } from 'interfaces/ITrucksBrandsStore';
import { ITruckModelsStore } from 'interfaces/ITruckModelsStore';
import { ISearchModelsStore } from 'interfaces/ISearchModelsStore';
import { IDictionariesStore } from 'interfaces/IDictionnariesStore';
import { ITabsStore } from 'interfaces/ITabsStore';
import { IStatusesStore } from 'interfaces/IStatusesStore';
import { IPopupStore } from 'interfaces/IPopupStore';
import { IFindTrucksStore } from 'interfaces/IFindTrucksStore';
import { IStatisticsStore } from 'interfaces/IStatisticsStore';
import { IRegistrationStatusStore } from 'interfaces/IRegistrationStatusStore';
import { IPagesStore } from 'interfaces/IPagesStore';
import { INotificationStore } from 'interfaces/INotificationStore';
import { IModalStore } from 'interfaces/IModalStore';
import { IFavoritesStore } from 'interfaces/IFavoritesStore';
import { IDraftStore } from 'interfaces/IDraftStore';
import { IComplaintStore } from 'interfaces/IComplaintStore';
import { IChecksStore } from 'interfaces/IChecksStore';
import { ICabinetPageStore } from 'interfaces/ICabinetPageStore';
import { IAfterPublicationStore } from 'interfaces/IAfterPublicationStore';
import { IAdvertsStore } from 'interfaces/IAdvertsStore';
import { ILoginContactInfoStore } from 'interfaces/ILoginContactInfoStore';
import { ISaveFilterStore } from 'interfaces/ISaveFilterStore';
import { IHeaderSubmenuStore } from 'interfaces/IHeaderSubmenuStore';

import SaveFilterStore from 'stores/SaveFilterStore';
import AppStore from './AppStore';
import DictionariesStore from './DictionariesStore';
import AdvertsStore from './AdvertsStore';
import CabinetPageStore from './CabinetPageStore';
import GeoSearchStore from './GeoSearchStore';
import FindTruckStore from './FindTrucksStore';
import StatusesStore from './StatusesStore';
import PopupStore from './PopupStore';
import ChecksStore from './ChecksStore';
import AddTruckStore from './AddTruckStore';
import TruckBrandsStore from './TruckBrandsStore';
import TruckModelsStore from './TruckModelsStore';
import SearchModelsStore from './SearchModelsStore';
import PagesStore from './PagesStore';
import TabsStore from './TabsStore';
import ErrorsStore from './ErrorsStore';
import FormValidationStore from './FormValidationStore';
import RegistrationStatusStore from './RegistrationStatusStore';
import ComplaintStore from './ComplaintStore';
import DraftStore from './DraftStore';
import StatisticsStore from './StatisticsStore';
import FavoritesStore from './FavoritesStore';
import AfterPublicationStore from './AfterPublicationStore';
import ModalStore from './ModalStore';
import MobileTestStore from './MobileTestStore';
import ProfileStore from './ProfileStore';
import LoginContactInfoStore from './LoginContactInfoStore';
import NotificationStore from './NotificationStore';
import HeaderSubmenuStore from './HeaderSubmenuStore';
import RelatedStore from './RelatedStore';

configure({ enforceActions: 'observed', isolateGlobalState: true });

export interface IRootStore {
  appStore: IAppStore;
  addTruckStore: any;
  formValidationStore: any;
  dictionariesStore: IDictionariesStore;
  geoSearchStore: IGeoSearchStore;
  truckBrandsStore: ITruckBrandsStore;
  truckModelsStore: ITruckModelsStore;
  searchModelsStore: ISearchModelsStore;
  tabsStore: ITabsStore;
  statusesStore: IStatusesStore;
  popupStore: IPopupStore;
  findTrucksStore: IFindTrucksStore;
  statisticsStore: IStatisticsStore;
  registrationStatusStore: IRegistrationStatusStore;
  pagesStore: IPagesStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  favoritesStore: IFavoritesStore;
  draftStore: IDraftStore;
  complaintStore: IComplaintStore;
  checksStore: IChecksStore;
  cabinetPageStore: ICabinetPageStore;
  afterPublicationStore: IAfterPublicationStore;
  advertsStore: IAdvertsStore;
  loginContactInfoStore: ILoginContactInfoStore;
  saveFilterStore: ISaveFilterStore;
  profileStore: ProfileStore;
  headerSubmenuStore: HeaderSubmenuStore;
  relatedStore: RelatedStore;
}

class RootStore implements IRootStore {
  appStore: IAppStore;
  dictionariesStore: IDictionariesStore;
  advertsStore: AdvertsStore;
  cabinetPageStore: CabinetPageStore;
  geoSearchStore: GeoSearchStore;
  findTrucksStore: FindTruckStore;
  statusesStore: StatusesStore;
  popupStore: PopupStore;
  checksStore: ChecksStore;
  addTruckStore: AddTruckStore;
  truckBrandsStore: TruckBrandsStore;
  truckModelsStore: TruckModelsStore;
  searchModelsStore: SearchModelsStore;
  pagesStore: PagesStore;
  tabsStore: TabsStore;
  errorsStore: typeof ErrorsStore;
  formValidationStore: FormValidationStore;
  registrationStatusStore: RegistrationStatusStore;
  notificationStore: NotificationStore;
  complaintStore: ComplaintStore;
  draftStore: DraftStore;
  statisticsStore: StatisticsStore;
  favoritesStore: FavoritesStore;
  afterPublicationStore: AfterPublicationStore;
  modalStore: ModalStore;
  mobileTestStore: MobileTestStore;
  profileStore: ProfileStore;
  loginContactInfoStore: LoginContactInfoStore;
  saveFilterStore: SaveFilterStore;
  headerSubmenuStore: HeaderSubmenuStore;
  relatedStore: RelatedStore;

  constructor() {
    this.appStore = new AppStore();
    this.dictionariesStore = new DictionariesStore(this);
    this.advertsStore = new AdvertsStore(this);
    this.cabinetPageStore = new CabinetPageStore(this);
    this.geoSearchStore = new GeoSearchStore();
    this.findTrucksStore = new FindTruckStore(this);
    this.statusesStore = new StatusesStore();
    this.popupStore = new PopupStore(this);
    this.checksStore = new ChecksStore(this);
    this.addTruckStore = new AddTruckStore(this);
    this.truckBrandsStore = new TruckBrandsStore(this);
    this.truckModelsStore = new TruckModelsStore(this);
    this.searchModelsStore = new SearchModelsStore(this);
    this.pagesStore = new PagesStore(this);
    this.tabsStore = new TabsStore();
    this.errorsStore = ErrorsStore;
    this.formValidationStore = new FormValidationStore(this);
    this.registrationStatusStore = new RegistrationStatusStore(this);
    this.notificationStore = new NotificationStore();
    this.complaintStore = new ComplaintStore(this);
    this.draftStore = new DraftStore(this);
    this.statisticsStore = new StatisticsStore(this);
    this.favoritesStore = new FavoritesStore(this);
    this.afterPublicationStore = new AfterPublicationStore(this);
    this.modalStore = new ModalStore();
    this.mobileTestStore = new MobileTestStore();
    this.profileStore = new ProfileStore();
    this.loginContactInfoStore = new LoginContactInfoStore();
    this.saveFilterStore = new SaveFilterStore(this);
    this.headerSubmenuStore = new HeaderSubmenuStore();
    this.relatedStore = new RelatedStore();
    this.init();
  }

  init = async () => {
    await this.dictionariesStore.getDictionaries();
    this.appStore.checkIsMobileApp();
    this.popupStore.enableFastReg();
  };
}

export default RootStore;
