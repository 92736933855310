import axiosInstance from './axios';

async function searchModelsAPI(data: { prefix: string; brand_id?: string }) {
  const config = {
    method: 'POST',
    url: '/public/api/v1/autocomplete/models',
    data,
  };

  // @ts-ignore
  const res = await axiosInstance(config);
  return res.data;
}

export default searchModelsAPI;
