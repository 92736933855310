const getAdvertItemType = (item: string): string => {
  const advertItemType: { [key: string]: string } = {
    all_types_trucks: 'все типы транспорта',
    truck: 'грузовики',
    tractor: 'тягачи',
    trailer: 'прицепы',
    semitrailer: 'полуприцепы',
    road_train: 'автопоезда',
    bus: 'автобусы',
    truck_crane: 'автокраны',
    excavator: 'экскаваторы',
    dozer: 'бульдозеры',
    loader: 'погрузчики',
    other_special: 'спецтехника (другое)',
  };

  return advertItemType[item] ?? '';
};

export default getAdvertItemType;
