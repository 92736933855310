export default {
  '01': ['январь', 'января'],
  '02': ['февраль', 'февраля'],
  '03': ['март', 'марта'],
  '04': ['апрель', 'апреля'],
  '05': ['май', 'мая'],
  '06': ['июнь', 'июня'],
  '07': ['июль', 'июля'],
  '08': ['август', 'августа'],
  '09': ['сентябрь', 'сентября'],
  10: ['октябрь', 'октября'],
  11: ['ноябрь', 'ноября'],
  12: ['декабрь', 'декабря'],
};
