import React, { FC } from 'react';
import PageContainer from 'generic/PageContainer';
import Skeleton from 'ati-ui-react/components/Skeleton';
import { TruckCardPageSkeletonStyles } from './TruckCardPageSkeletonStyles';

const TruckCardPageSkeleton: FC = () => {
  return (
    <PageContainer page="advert">
      <TruckCardPageSkeletonStyles>
        <div className="skeleton-row first">
          <Skeleton height={45} className="skeleton-col first" />
          <Skeleton height={45} className="skeleton-col second" />
        </div>
        <div className="skeleton-row second">
          <Skeleton height={25} className="skeleton-col first" />
          <Skeleton height={25} className="skeleton-col second" />
        </div>
        <div className="skeleton-row third">
          <Skeleton height={450} className="skeleton-col first" />
          <Skeleton height={320} className="skeleton-col second" />
        </div>
        <div className="skeleton-row fourth">
          <Skeleton height={200} className="skeleton-col first" />
          <div className="skeleton-col second" />
        </div>
        <div className="skeleton-row fourth">
          <Skeleton height={300} className="skeleton-col first" />
          <div className="skeleton-col second" />
        </div>
        <div className="skeleton-row fifth">
          <Skeleton height={400} className="skeleton-col first" />
        </div>
      </TruckCardPageSkeletonStyles>
    </PageContainer>
  );
};

export default TruckCardPageSkeleton;
