import styled from 'styled-components';

export const TruckCardPageSkeletonStyles = styled.div`
  padding-top: 60px;

  .skeleton-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .skeleton-col {
    border-radius: 4px;
  }

  .skeleton-col.first {
    flex: 2;
  }

  .skeleton-col.second {
    flex: 1;
    margin-left: 32px;
  }

  .skeleton-row.third,
  .skeleton-row.fourth {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1024px) {
    .skeleton-col.second {
      display: none;
    }
  }
`;
