import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import toNumber from 'lodash/toNumber';
import isValidDateForForm from 'utils/isValidDateForForm';
import validateMobile from 'utils/validateMobile';
import decline from './decline';

/**
 * Функции фильтрации вводимых значений
 */
export const filteringFunctions = {
  // Только числа
  number: (value) => value.replace(/(^[0]+)|([^\d]*)/g, ''),
  // Дробные числа
  float: (value) =>
    value
      .replace(/,/g, '.')
      .replace(/^\.*([^.]*\.)(.*)$/, (match, p1, p2) => {
        return p1 + p2.replace(/\./g, '');
      })
      .replace(/(^[0]{2,})/g, '0')
      .replace(/(^\.)|([^\d.]*)/g, ''),
  // Русские и латинские буквы, цифры, пробелы и символы '/' и '-'
  text: (value) => value.replace(/[^\sA-Za-zА-Яа-я\d/.,?!#%&:"'()-]/g, ''),
  // Русские и латинские буквы, пробел и '-'
  fio: (value) => value.replace(/[^\sA-Za-zА-Яа-я-]/g, ''),
  // Русские и латинские буквы, цифры, пробелы
  gov_num: (value) => {
    const newValue = value.replace(/[^\s\d\-A-Za-zА-Яа-я]/g, '').replace(/\s{2,}/g, ' ');
    if (!newValue) return null;
    const trimmedValue = newValue.slice(0, 20);
    return trimmedValue;
  },
  // Только цифры и латинские буквы
  vin: (value) => value.replace(/[^A-Za-z0-9]/g, ''),
  // Массив фото не больше 10ти
  photos: (photos) => (photos.length > 10 ? photos.slice(0, 10) : photos),
  // Модель - режем количество символов
  model: (model) => (typeof model === 'string' ? model.slice(0, 130) : model),
};

/**
 * Функции валидации
 * required - проверяет что значение существует
 * minLength -  проверяет что значение не менее заданной длины
 * length -  проверяет что значение соответствует заданной длине
 * gov_num -  проверяет валидность гос номера для компонента carNumber
 */
export const validationFunctions = {
  required: (value, isTrue) => {
    if (!isTrue) return true;
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'string') {
      const normalValue = value.trim();
      return normalValue.length > 0;
    }
    if (isObject(value) && isEmpty(value)) return false;
    if (value === null || value === undefined) return false;
    // eslint-disable-next-line no-console
    console.log(value);
    throw new Error(`Ошибка валидации required. value = ${value}`);
  },
  minLength: (value, minLength) => {
    if (!value) return true;
    if (Array.isArray(value)) return value.length >= minLength;
    if (typeof value === 'string') {
      const normalValue = value.trim();
      return normalValue.length >= minLength;
    }
    throw new Error('Ошибка валидации minLength');
  },
  maxLength: (value, maxLength) => {
    if (!value) return true;
    if (Array.isArray(value)) return value.length <= maxLength;
    if (typeof value === 'string') {
      const normalValue = value.trim();
      return normalValue.length <= maxLength;
    }
    throw new Error('Ошибка валидации maxLength');
  },
  maxNum: (value, maxNum) => toNumber(value) <= maxNum,
  length: (value, length) => {
    if (!value) return true;
    if (Array.isArray(value)) return value.length === length;
    if (typeof value === 'string') {
      const normalValue = value.trim();
      return normalValue.length === length;
    }
    if (value === null || value === undefined) return false;
    throw new Error('Ошибка валидации length');
  },
  year: (year) => {
    if (!year && year !== 0) return true;
    return isValidDateForForm(year);
  },
  gov_num: (value, { isRequired, country }) => {
    if (!isRequired && !value) return true;
    if (!value) return false;
    if (country === 'ru') return value.replace(/[\s]/g, '').length >= 8;
    return value.replace(/[\s]/g, '').length >= 4;
  },
  advert_item_type: (type) => {
    if (!type || type === 'special_machinery') return false;
    return true;
  },
  mobile: (value, { countryID }) => {
    const { isValid } = validateMobile(value, countryID);
    return isValid;
  },
};

/**
 * Дефолтные ошибки валидации
 */
export const defaultErrorTexts = {
  required: () => 'Обязательное поле',
  minLength: (minLength) =>
    `Длина должна быть не менее ${minLength} ${decline(minLength, [
      'символа',
      'символов',
      'символов',
    ])}`,
  maxLength: (maxLength) =>
    `Длина должна быть не более ${maxLength} ${decline(maxLength, [
      'символа',
      'символов',
      'символов',
    ])}`,
  length: (length) =>
    `Длина должна быть ${length} ${decline(length, ['символ', 'символа', 'символов'])}`,
  maxNum: (maxNum) => `Максимальное значение ${maxNum}`,
  gov_num: () => 'Некорректно заполнен номер',
  advert_item_type: () => 'Обязательное поле',
  year: () => 'Введите год',
  mobile: () => 'Номер заполнен некорректно',
};
