import React, { useEffect } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';

const ScreenObserver = observer(() => {
  const {
    appStore: { updateWidthAndHeight },
  } = useStores();
  const [width, height] = useWindowSize();

  useEffect(() => {
    updateWidthAndHeight(width, height);
  }, [width, height]);

  return null;
});

export default ScreenObserver;
