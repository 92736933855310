import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import Input from 'ati-ui-react/components/InputField';
import isMobile from 'ati-utils/isMobile';
import Information from 'ati-ui-react/components/icons/Information';
import Tooltip from 'ati-ui-react/components/ReactTooltip';

import { EmailContainer } from './EmailStyles';

interface IEmail {
  id: string;
  val?: string;
}

const Email: FC<IEmail> = observer(({ id, val }) => {
  const {
    saveFilterStore: {
      isIncorrectEmail,
      getCustomEmail,
      customEmail,
      resetIncorrectEmail,
      statusSubscribeFilter,
      isChangedEmail,
      resetCustomEmail,
    },
  } = useStores();

  const [value, setValue] = useState(val);

  const currentEmail = customEmail.id === id;

  useEffect(() => {
    if (statusSubscribeFilter.isRequest) {
      resetIncorrectEmail(false);
    }
  }, [isIncorrectEmail]);

  useEffect(() => {
    return () => {
      resetIncorrectEmail(false);
      resetCustomEmail();
      isChangedEmail(false);
    };
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    getCustomEmail(e.target.id, e.target.value);
    resetIncorrectEmail(false);
    isChangedEmail(true);
  };

  return (
    <EmailContainer>
      <Input
        value={value}
        className="filter-email"
        placeholder="Введите email"
        wrapperClassName="wrapper-filter-email"
        onChange={onChange}
        onClick={(e: any) => e.stopPropagation()}
        id={id}
        errorDescr={currentEmail && isIncorrectEmail ? 'некорректный email' : ''}
      />

      <div data-tip={!isMobile} data-for="filter-email-tooltip" className="filter-email-info">
        <Information width={14} />
      </div>

      <Tooltip place="top" id="filter-email-tooltip" effect="solid">
        Без спама – отправляем уведомления раз в сутки.
      </Tooltip>
    </EmailContainer>
  );
});

export default Email;
