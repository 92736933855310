import { observable, action, computed, runInAction } from 'mobx';
import statisticsAPI from 'services/statisticsAPI';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import toNumber from 'lodash/toNumber';
import capitalize from 'lodash/capitalize';
import months from 'dictionaries/months';
import weeks from 'dictionaries/weeks';
import { IStatisticsStore } from 'interfaces/IStatisticsStore';
import { IRootStore } from 'stores/rootStore';

const weekDays = 7;
const oneWeek = 1;
const oneDay = 1;

class StatisticsStore implements IStatisticsStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable stateTodayViews = ''; // Состояние (для асинхронного запроса)
  @observable stateStatistics = ''; // Состояние (для асинхронного запроса)
  @observable todayViews = 0; // Прирост просмотров за сегодня
  @observable chartData = {}; // Данные для графика
  @observable offsetWeek = 0; // Сдвиг недели

  @computed get today() {
    const today = format(new Date(), 'yyyy-MM-dd');
    return today;
  }

  @computed get dateFrom() {
    const date = new Date();
    date.setDate(date.getDate() - (weekDays * (this.offsetWeek + oneWeek) - oneDay));
    return date;
  }

  @computed get dateTo() {
    const date = new Date();
    date.setDate(date.getDate() - weekDays * this.offsetWeek);
    return date;
  }

  @computed get periodString() {
    return `${format(this.dateFrom, 'dd.MM')}–${format(this.dateTo, 'dd.MM')}`;
  }

  @computed get isLoadingStatistics() {
    return this.stateStatistics === 'pending';
  }

  @action setOffsetWeek = (offset: number) => {
    this.offsetWeek = offset;
  };

  /**
   * Получение количества просмотров за сегодня
   */
  @action getTodayViews = async (id: any) => {
    runInAction(() => {
      this.stateTodayViews = 'pending';
    });
    try {
      const res = await statisticsAPI({
        advert_id: id,
      });
      runInAction(() => {
        this.stateTodayViews = 'done';
      });
      if (res?.ok) {
        runInAction(() => {
          this.todayViews = res.result[this.today];
        });
      }
    } catch {
      runInAction(() => {
        this.stateTodayViews = 'error';
      });
    }
  };

  getChartData = (stats: any) => {
    // TODO поправить
    // eslint-disable-next-line unicorn/no-reduce
    return Object.entries(stats).reduce(
      // @ts-ignore
      (acc, [date, views]) => {
        const dayStr = format(new Date(date), 'dd');
        // @ts-ignore
        const dateOfWeekStr = weeks[format(new Date(date), 'ee', { locale: ru })][1];
        // @ts-ignore
        const month = months[format(new Date(date), 'MM')][1];
        const year = format(new Date(date), 'yyyy');
        const dateStr = `${dayStr}, ${capitalize(dateOfWeekStr)}`;
        const fullDateStr = `${dayStr} ${month} ${year}`;
        const viewsNum = toNumber(views);
        const isEmptyStatistics = viewsNum ? { isEmptyStatistics: false } : {};
        return {
          labels: [...acc.labels, dateStr],
          values: [...acc.values, viewsNum],
          fullDates: {
            // @ts-ignore
            ...acc.fullDates,
            [dateStr]: fullDateStr,
          },
          isEmptyStatistics: acc.isEmptyStatistics,
          ...isEmptyStatistics,
        };
      },
      {
        labels: [],
        values: [],
        fullDadets: {},
        isEmptyStatistics: true,
      },
    );
  };

  /**
   * Получение статистики просмотров объявления
   */
  @action getStatistics = async (id: any, dateFrom = '', dateTo = '') => {
    runInAction(() => {
      this.stateStatistics = 'pending';
      this.chartData = { isEmptyStatistics: false };
    });
    const period =
      dateFrom || dateTo
        ? {
            date_from: dateFrom,
            date_to: dateTo,
          }
        : {};

    try {
      const res = await statisticsAPI({
        advert_id: id,
        ...period,
      });
      if (res?.ok) {
        runInAction(() => {
          this.chartData = this.getChartData(res.result);
        });
      }
      runInAction(() => {
        this.stateStatistics = 'done';
      });
    } catch {
      runInAction(() => {
        this.stateStatistics = 'error';
      });
    }
  };
}

export default StatisticsStore;
