import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Heading from 'ati-ui-react/components/Heading';
import { useStores } from 'hooks/useStores';
import updateUrlParam from 'utils/updateUrlParam';
import setRussianFilterName from 'utils/setRussianFilterName';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { addMetric } from 'utils/addMetrics';
import Email from '../Email';
import SubscribeLink from '../SubscribeLink';
import RemoveFilter from '../RemoveFilter';
import getAdvertItemType from '../../getAdvertItemType';

import { FilterContainer } from './FilterStyles';

interface IFilter {
  id: string;
  searchParams: any;
  queryString: string;
  brand?: string;
  model: string;
  email?: string;
  subscriptions: any;
  filterName: string;
  advertType: string;
  index: number;
  capacityFrom?: number;
  capacityTo?: number;
  mileageFrom?: number;
  mileageTo?: number;
  workingHoursFrom?: number;
  workingHoursTo?: number;
  yearFrom?: number;
  yearTo?: number;
  priceFrom?: number;
  priceTo?: number;
  periodType?: string;
  geoText?: string;
  advertItemType?: string;
  available?: string;
  new?: string;
  vin?: string;
  withLizing?: string;
  driver?: string;
  proposalType?: string;
}

const Filter: FC<IFilter> = observer((filter: IFilter) => {
  const {
    saveFilterStore: {
      customEmail,
      postRemoveFilters,
      postSubscribeFilter,
      getCustomEmail,
      isChanged,
      setFilterToForm,
      setVisiblePopover,
    },
    findTrucksStore,
  } = useStores();

  const history = useHistory();

  const isBrand = filter.brand && filter.brand;
  const isModel = filter.model && filter.model;
  const isCapacity = filter.capacityFrom && filter.capacityTo;
  const isMileage = filter.mileageFrom && filter.mileageTo;
  const isWorkingHours = filter.workingHoursFrom && filter.workingHoursTo;
  const isPrice = filter.priceFrom && filter.priceTo;
  const isYear = filter.yearFrom && filter.yearTo;
  const isRentPeriodType = filter.periodType && filter.periodType;

  const mapAdvertType: { [key: string]: string } = {
    sale: 'Продажа',
    rent: 'Аренда',
    purchase: 'Заявки',
  };

  const mapPeriodType: { [key: string]: string } = {
    hour: 'в час',
    day: 'в день',
    shift: 'за смену',
    contract: 'договорная',
  };

  const searchFormLocation: { [key: string]: string } = {
    sale: '/trucks',
    rent: '/trucks/rent',
  };

  const searchFormProposalsLocation: { [key: string]: string } = {
    purchase: '/trucks/proposals-purchase',
    rent: '/trucks/proposals-rent',
  };

  const filterFields = {
    capacity: { from: 0, to: 0 },
    mileage: { from: 0, to: 0 },
    working_hours: { from: 0, to: 0 },
    year: { from: 0, to: 0 },
    price: { from: 0, to: 0 },
    city: {
      id: '',
      name: '',
      country_id: 0,
      region_id: 0,
      type: 0,
    },
    is_available: false,
    only_new: false,
    with_vin: false,
    with_lizing: false,
    with_driver: false,
  };

  const brandAndModel =
    isBrand && isModel ? `${filter.brand} ${filter.model}` : filter.brand || filter.model;

  const capacity = isCapacity
    ? `от ${filter.capacityFrom} до ${filter.capacityTo} т.`
    : (filter.capacityFrom && `от ${filter.capacityFrom} т.`) ||
      (filter.capacityTo && `до ${filter.capacityTo} т.`);

  const mileage = isMileage
    ? `от ${filter.mileageFrom} до ${filter.mileageTo} км.`
    : (filter.mileageFrom && `от ${filter.mileageFrom} км.`) ||
      (filter.mileageTo && `до ${filter.mileageTo} км.`);

  const workingHours = isWorkingHours
    ? `от ${filter.workingHoursFrom} до ${filter.workingHoursTo} м.ч.`
    : (filter.workingHoursFrom && `от ${filter.workingHoursFrom} м.ч.`) ||
      (filter.workingHoursTo && `до ${filter.workingHoursTo} м.ч.`);

  const year = isYear
    ? `${filter.yearFrom} - ${filter.yearTo} гг.`
    : (filter.yearFrom && `от ${filter.yearFrom} г.`) ||
      (filter.yearTo && `до ${filter.yearTo} г.`);

  const price = isPrice
    ? `от ${filter.priceFrom} до ${filter.priceTo} руб.`
    : (filter.priceFrom && `от ${filter.priceFrom} руб.`) ||
      (filter.priceTo && `до ${filter.priceTo} руб.`);

  const periodType = isRentPeriodType && filter.periodType && mapPeriodType[filter.periodType];

  const geoText = filter.geoText && filter.geoText;

  const advertItemType = filter.advertItemType && getAdvertItemType(filter.advertItemType);

  const available = filter.available && 'только в наличии';

  const onlyNew = filter.new && 'только новые';

  const vin = filter.vin && 'указан vin';

  const withLizing = filter.withLizing && 'одобрен лизинг';

  const driver = filter.driver && 'с водителем';

  const renderFilter = compact([
    brandAndModel,
    capacity,
    mileage,
    workingHours,
    year,
    price,
    periodType,
    geoText,
    advertItemType,
    available,
    onlyNew,
    vin,
    withLizing,
    driver,
  ]).join(', ');

  const isEnglish = (str: string): boolean => {
    return /^[a-zA-Z]+$/.test(str);
  };

  const renderName = (name: string): string => {
    if (isEnglish(name)) {
      return setRussianFilterName(filter.filterName);
    }

    return filter.filterName;
  };

  const setTabActive = () => {
    if (filter?.proposalType) {
      history.push(searchFormProposalsLocation[filter.proposalType]);
    } else {
      history.push(searchFormLocation[filter.advertType]);
    }
  };

  const onHandleSetFilter = async () => {
    setTabActive();
    updateUrlParam(filter.queryString);
    setFilterToForm(
      {
        ...filterFields,
        ...filter.searchParams,
      },
      filter.id,
      filter.geoText,
    );
    setVisiblePopover(false);
    await findTrucksStore.getTrucksResults();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    addMetric('saved-search-use');
  };

  const onHandleSubscribe = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    getCustomEmail(filter.id, customEmail.email);
    postSubscribeFilter({
      search_params: filter.searchParams,
      filter_name: filter.filterName,
      query_string: filter.queryString,
      geo_text: filter.geoText,
      _id: filter.id,
      subscriptions: {
        period: 86400,
        email: customEmail.id === filter.id && isChanged ? customEmail.email : filter.email,
      },
    });
    addMetric('saved-search-subscribe');
  };

  const onHandleUnsubscribe = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    postSubscribeFilter({
      search_params: filter.searchParams,
      filter_name: filter.filterName,
      query_string: filter.queryString,
      geo_text: filter.geoText,
      _id: filter.id,
    });
    addMetric('saved-search-unsubscribe');
  };

  const onHandleRemove = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    postRemoveFilters({ filter_ids: [filter.id] });
    addMetric('saved-search-delete');
  };

  return (
    <FilterContainer onClick={onHandleSetFilter}>
      <Heading h5 className="heading">
        {mapAdvertType[filter.advertType]}: {renderName(filter.filterName)}
      </Heading>

      <span className="render-filter">{renderFilter}</span>

      <Email id={filter.id} val={filter.email} />
      <div className="filters-actions">
        <SubscribeLink
          subscription={isEmpty(filter.subscriptions)}
          id={filter.id}
          email={filter.email}
          onHandleSubscribe={onHandleSubscribe}
          onHandleUnsubscribe={onHandleUnsubscribe}
        />
        <RemoveFilter onHandleRemove={onHandleRemove} />
      </div>
    </FilterContainer>
  );
});

export default Filter;
