import axios from 'axios';

async function getRegistrationStatusAPI() {
  const config = {
    method: 'GET',
    url: '/api/v1/helpers/attrs',
  };

  const res = await axios(config);
  return res.data;
}

export default getRegistrationStatusAPI;
