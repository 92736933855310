import axiosInstance from './axios';

async function getTransportsCount() {
  const config = {
    method: 'GET',
    url: '/api/v1/transports/count',
  };

  const res = await axiosInstance(config);
  return res?.data;
}

export default getTransportsCount;
