import { IAnyObject } from 'interfaces/GenericInterfaces';

const allFields = {
  truck_crane: {
    capacity: '', // Грузоподъемность в тоннах
    max_jib_upper_height: '', // Высота подъёма
    min_jib_departure: '', // Мин.вылет стрелы
    max_jib_departure: '', // Макс.вылет стрелы
  },
  excavator: {
    excavator_type: '', // Тип экскаватора
    excavator_bucket_volume: '', // Объём ковша
    max_digging_height: '', // Высота копания
    max_digging_depth: '', // Глубина копания
    dumping_height: '', // Высота разгрузки
  },
  dozer: {
    traction_class: '', // Тяговый класс
    blade_width: '', // Ширина отвала
    tracks_width: '', // Ширина гусениц
    fully_weight: '', // Полная масса
    ground_pressure: '', // Давление на грунт
    max_cutting_depth: '', // Макс. глубина среза
    max_loosening_depth: '', // Макс. глубина рыхления
    max_blade_position_height: '', // Макс. высота подъема отвала
  },
  loader: {
    loader_type: '', // Тип погрузчика
    max_jib_upper_height: '', // Высота подъема
    speed_without_load: '', // Скорость без груза
    speed_with_load: '', // Скорость с грузом
    load_upping_speed: '', // Скорость подъема груза
  },
  other_special: {
    special_type: '', // Другой тип спецтехники
  },
};

const specificationsSpecialMachines: IAnyObject = {
  sale: {
    ...allFields,
  },
  rent: {
    ...allFields,
  },
  purchase: {
    truck_crane: {
      capacity: '', // Грузоподъемность в тоннах
    },
    excavator: {
      excavator_type: '', // Тип экскаватора
    },
    dozer: {},
    loader: {
      loader_type: '', // Тип погрузчика
    },
    other_special: {
      special_type: '', // Другой тип спецтехники
    },
  },
};

export default specificationsSpecialMachines;
