import React from 'react';
import * as Sentry from '@sentry/react';

import Heading from 'ati-ui-react/components/Heading';
import Button from 'ati-ui-react/components/Button';
import { AppBlock, ErrorContent, HeadingWrapper } from './ErrorBoundaryStyles';

const reloadPage = () => {
  window.location.reload();
};

const FallbackComponent = () => {
  return (
    <AppBlock>
      <ErrorContent>
        <HeadingWrapper>
          <Heading h3>Перезагрузите страницу, что-то пошло не так.</Heading>
        </HeadingWrapper>
        <Button onClick={reloadPage}>Перезагрузить</Button>
      </ErrorContent>
    </AppBlock>
  );
};

const ErrorBoundary = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={FallbackComponent}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
