import { observable, action, runInAction } from 'mobx';
import { IStatusesStore, IAccounts } from 'interfaces/IStatusesStore';
import fetchStatuses from 'services/status';

class StatusesStore implements IStatusesStore {
  @observable statuses = {};

  @action getStatuses = async (accounts: IAccounts) => {
    // TODO исправить
    // eslint-disable-next-line no-useless-catch
    try {
      const userIds: string[] = [];
      Object.keys(accounts).forEach((atiId) => {
        accounts[atiId].contacts.forEach((contact) => {
          const userId = `${atiId}.${contact.id}`;
          userIds.push(userId);
        });
      });

      if (userIds.length === 0) {
        return;
      }

      const data = await fetchStatuses(userIds);
      runInAction(() => {
        this.statuses = {
          ...this.statuses,
          ...data,
        };
      });
    } catch (error) {
      throw error;
    }
  };
}

export default StatusesStore;
