import { observable, computed, action, runInAction } from 'mobx';
import getRegistrationStatusAPI from 'services/getRegistrationStatusAPI';
import { IRegistrationStatusStore } from 'interfaces/IRegistrationStatusStore';
import { IRootStore } from 'stores/rootStore';

class RegistrationStatusStore implements IRegistrationStatusStore {
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  @observable status = 'pending';
  @observable isOnlyFastRegistration = false;

  @computed get registrationStatusLoaded() {
    return this.status === 'done';
  }

  @action resetIsOnlyFastRegistration = () => {
    this.isOnlyFastRegistration = false;
  };

  @action getRegistrationStatus = () => {
    runInAction(() => {
      this.status = 'rinning';
    });
    // eslint-disable-next-line promise/catch-or-return
    getRegistrationStatusAPI()
      // eslint-disable-next-line promise/always-return
      .then((res) => {
        runInAction(() => {
          this.isOnlyFastRegistration = res.result.reg_in_process;
        });
      })
      .catch(() => {
        runInAction(() => {
          // @ts-ignore
          this.isOnlyFastRegistration = this.rootStore.profileStore.isFastReg;
        });
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        runInAction(() => {
          this.status = 'done';
        });
      });
  };
}

export default RegistrationStatusStore;
