import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemTruckModel from './items/ItemTruckModel';
import ItemTrailerModel from './items/ItemTrailerModel';

class RoadTrainTruckFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется с составными типами ТС
    this.advert_items = observable([
      {
        type: 'truck',
        transport: toJS(new ItemTruckModel(options)),
      },
      {
        type: 'trailer',
        transport: toJS(new ItemTrailerModel(options)),
      },
    ]);
  }

  @observable advert_item_type = 'road_train'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default RoadTrainTruckFormModel;
