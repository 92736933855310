import axiosInstance from './axios';

async function virtualAccountDepositAPI(data) {
  const config = {
    method: 'POST',
    url: '/webapi/billing/virtual-account-deposit',
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default virtualAccountDepositAPI;
