import styled from 'styled-components';

export const NewsBlockContainer = styled.div`
  width: 300px;
  height: 400px;
`;

export const NewsIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;
