import styled from 'styled-components';

export const FavoriteLinkContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  .star {
    display: flex;
    margin-right: 6px;

    svg path {
      fill: #478cc8;
    }
  }

  .favorite-link {
    color: #478cc8;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .favorite-link {
      color: #478cc8;
      text-decoration: underline;
    }

    .star svg path {
      fill: #3a7bbf;
    }
  }

  @media (max-width: 1023px) {
    width: 28px;
    height: 28px;
    border: 1px solid #478cc8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .text {
      display: none;
    }

    .star {
      position: inherit;
      margin: 0;

      svg {
        width: 100%;
      }

      svg path {
        fill: #478cc8;
      }
    }

    &:hover .favorite-link {
      text-decoration: none;
    }
  }
`;
