import { observable, toJS } from 'mobx';
import AdvertFormModel from './AdvertFormModel';
import ItemTrailerModel from './items/ItemTrailerModel';

class TrailerFormModel extends AdvertFormModel {
  constructor(options) {
    super(options);
    // Вложенная модель ТС. Используется вместе с простыми типами ТС
    this.advert_item = observable(toJS(new ItemTrailerModel(options)));
  }

  @observable advert_item_type = 'trailer'; // Тип продаваемого транспорта,значение из словаря supported_types
}

export default TrailerFormModel;
