import axiosInstance from './axios';

async function promotionAPI(data) {
  const config = {
    method: 'POST',
    url: '/api/v1/billing/transports/promotion',
    data,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default promotionAPI;
