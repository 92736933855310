import { observable, extendObservable } from 'mobx';
import additionalAdFields from './additionalAdFields';
import registrationData from './registrationData';

class AdvertFormModel {
  constructor(options) {
    const advertType = options?.advertType;
    const isOnlyFastRegistration = options?.isOnlyFastRegistration;

    // Установка типа объявления (продажа/аренда)
    if (advertType) {
      this.advert_type = options.advertType;
      // Установка дополнительных полей для текущего типа
      extendObservable(this, additionalAdFields[advertType]);
    }

    // Проверка пользователя на не полную регистрацию
    if (isOnlyFastRegistration) {
      // Добаление полей для второго шага регистрации
      extendObservable(this, registrationData);
    }
  }

  // Где находится
  @observable city_verbose = ''; // Город
  @observable country_verbose = ''; // Страна
  @observable city_ati_id = ''; //  ID города из базы ATI
  @observable country_ati_id = ''; //  ID страны из базы ATI
  @observable region_ati_id = ''; // ID региона из базы ATI
  // Стоимость и контакты
  @observable price = ''; // Цена
  @observable currency = 'rub'; // Валюта, string, Default:rub
  @observable contacts = []; //  contact id владельца
  // Служебные данные
  @observable status = 'active'; // Значение из словаря advert_statuses, Default:active
  @observable advert_type = 'sale'; // Значение из словаря advert_types
  @observable advert_item_type = ''; // Тип продаваемого транспорта,значение из словаря supported_types
  @observable owner_acc = ''; // firm_id владельца
  @observable owner_contact = ''; //  contact id владельца
  // Платные услуги
  @observable advert_promotion = ''; // Наименование платного пакета
}

export default AdvertFormModel;
