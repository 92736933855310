import { observable, action } from 'mobx';
import { ITabsStore } from 'interfaces/ITabsStore';

class TabsStore implements ITabsStore {
  @observable isShowedAppendComponent = true;
  @observable currentTab = '';

  @action setShowedAppendComponent = (status: boolean): void => {
    this.isShowedAppendComponent = status;
  };

  @action setCurrentTab = (tab: string) => {
    this.currentTab = tab;
  };
}

export default TabsStore;
