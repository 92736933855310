import axiosInstance from './axios';

async function getLastPayment(id) {
  const config = {
    method: 'GET',
    url: `/api/v1/billing/last/${id}`,
  };

  const res = await axiosInstance(config);
  return res.data;
}

export default getLastPayment;
