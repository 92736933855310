// TODO удалять поломанные ключи

import updateUrlParam from 'utils/updateUrlParam';
import getSearchAdvertType from 'utils/getSearchAdvertType';

const queryStringFormModel = (item) => {
  //  Типы  гео поиска
  const type = {
    country: 0,
    region: 1,
  };

  const validOrdering = [
    'cheaper',
    'more_expensive',
    'new_first',
    'first_old',
    'mileage',
    'working_hours',
    'by_name',
  ];

  const validAdvertItemType = [
    'truck',
    'tractor',
    'trailer',
    'semitrailer',
    'road_train',
    'bus',
    'truck_crane',
    'excavator',
    'dozer',
    'loader',
    'other_special',
  ];

  const getGeoUrlParams = item.geo?.split('_');
  const [id, name, countryId, regionId, typeId] = getGeoUrlParams || [];

  const params = new URLSearchParams(window.location.search);
  const page = params.get('page');
  const setQSPage = page > 0 ? (page - 1) * 10 : null;

  // Валидация QS паггинации.
  if (Number(page) === 0) {
    params.delete('page');
    updateUrlParam(params);
  }

  return {
    advert_type: getSearchAdvertType(),
    capacity: {
      from: Number(item.capacity_from?.slice(0, 3)) || null,
      to: Number(item.capacity_to?.slice(0, 3)) || null,
    },
    mileage: {
      from: Number(item.mileage_from?.slice(0, 7)) || null,
      to: Number(item.mileage_to?.slice(0, 7)) || null,
    },
    working_hours: {
      from: Number(item.working_hours_from?.slice(0, 6)) || null,
      to: Number(item.working_hours_to?.slice(0, 6)) || null,
    },
    year: {
      from: Number(item.year_from?.slice(0, 4)) || null,
      to: Number(item.year_to?.slice(0, 4)) || null,
    },
    price: {
      from: Number(item.price_from?.slice(0, 9)) || null,
      to: Number(item.price_to?.slice(0, 9)) || null,
    },
    period_type: item.price_from || item.price_to ? item.period_type : 'day',
    city: {
      ...(Number(typeId) !== type.country &&
        Number(typeId) !== type.region && {
          // eslint-disable-next-line no-restricted-globals
          id: isNaN(id) ? null : id,
        }),
      ...(Number(typeId) !== type.country &&
        Number(typeId) !== type.region && {
          name,
        }),
      country_id: Number(countryId) || null,
      region_id: Number(regionId) || null,
    },
    ordering: validOrdering.includes(item.ordering) ? item.ordering : null,
    advert_item_type: validAdvertItemType.includes(item.advert_item_type)
      ? item.advert_item_type
      : null,
    limit: item.limit,
    offset: item.offset || Number(setQSPage) || null,

    is_available: (item.is_available === 'true' && !!item.is_available) || false,
    only_new: (item.only_new === 'true' && !!item.only_new) || false,
    with_vin: (item.with_vin === 'true' && !!item.with_vin) || false,
    with_driver: (item.with_driver === 'true' && !!item.with_driver) || false,
    with_lizing: (item.with_lizing === 'true' && !!item.with_lizing) || false,

    brand: item.brand || null,
    model: item.model || null,
  };
};

export default queryStringFormModel;
